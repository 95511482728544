import { Flex, Image, Text } from '@chakra-ui/react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import aizubg from 'src/assets/login/home_aizu.jpg'
import cdbg from 'src/assets/login/home_cd.jpg'
import gestorbg from 'src/assets/login/home_gestor.jpg'
import uniquebg from 'src/assets/login/home_unique.jpg'

interface Prod {
  bg: string
  label: string
  icon: string
}

export const Slider = () => {
  const [prod, setProd] = useState<Prod[]>([
    {
      bg: gestorbg,
      label: `Otimize sua empresa e tenha todo o processo  de vendas ajustado em um só sistema.`,
      icon: '<Gest01 />',
    },
    {
      bg: uniquebg,
      label: `Um sistema feito exclusivamente para atender 
    o trabalho do contador com simplicidade e agilidade.`,
      icon: '<Uni01 />',
    },
    {
      bg: aizubg,
      label: `Solução financeira descomplicada para  
    otimizar os pagamentos da sua empresa.`,
      icon: '<Aizu01 />',
    },
    {
      bg: cdbg,
      label: `A Londrisoft é revendedora de certificados digitais! Essa solução ágil é imprescindível para a emissão de notas fiscais.`,
      icon: '<Cert01 />',
    },
  ])
  const [index, setIndex] = useState(0)

  const getSettingImageLogin = async () => {
    await axios
      .get(
        'https://imagens-design.londrisoftservices.com.br/workspace/login/config-login/config-login.json',
      )
      .then((response) => setProd(response.data))
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (index === prod.length - 1) setIndex(() => 0)
      if (index !== prod.length - 1) setIndex(() => index + 1)
    }, 5000)

    return () => clearTimeout(timer)
  }, [index])

  useEffect(() => {
    getSettingImageLogin()
  }, [])

  return (
    <>
      <Flex
        display={{ base: 'none', lg: 'flex' }}
        transition={'background .3s linear'}
        backgroundImage={prod[index].bg}
        backgroundPosition={'center'}
        backgroundRepeat={'no-repeat'}
        backgroundSize={'cover'}
        width={'100%'}
        position={'relative'}
      >
        <Flex
          position={'absolute'}
          // height={'150px'}
          top={'50%'}
          left={'50%'}
          transform={'translateX(-50%)'}
          alignItems={'center'}
          justifyContent={'space-between'}
          flexDirection={'column'}
        >
          <Image src={prod[index].icon} alt={prod[index].label} />

          <Text
            minWidth={'500px'}
            color={'high.pure'}
            mt={'40px'}
            fontSize={'16px'}
            fontWeight={'300'}
            textAlign={'center'}
          >
            {prod[index].label}
          </Text>
        </Flex>
      </Flex>
    </>
  )
}
