import { useCallback, useEffect, useState } from 'react'
import { useSession } from 'src/contexts/sessions'
import { apiInstance } from 'src/services/api'

export interface User {
  userId: number
  name: string
  email: string
  status: boolean
  perfilId: number
  perfilWs: number
  perfilAplicativos: string
  aplicativos: string
}

export function useUsers() {
  const { session } = useSession()
  const [data, setData] = useState<User[]>([])
  const [isFetching, setIsFetching] = useState(true)

  const getUsers = useCallback(async () => {
    //TODO: remover do alpha quando for para produção
    const { data } = await apiInstance(8006).get(
      `/UserGestor/UsersByCompany/${session?.company?.document}`,
      {
        headers: {
          authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
          companyId: session?.company?.id.toString(),
          cnpjcpf: session?.company?.document,
        },
      },
    )

    if (session.perfilWs !== 0) {
      const my = data.filter((user: any) => user.userId === session.id)

      setData(my)
      setIsFetching(false)
      return
    }

    setData(data.reverse())
    setIsFetching(false)
  }, [
    session?.company?.document,
    session?.company?.id,
    session.id,
    session.perfilWs,
  ])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  return { users: data, isFetching }
}
