import {
  Box,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { User } from 'src/screens/Users/hooks'
import {
  GestorProfiles,
  Profile,
  UniqueProfiles,
  WorkspaceProfiles,
} from '../../../../../constants/profiles'
import { useSession } from '../../../../../contexts'
import { apiInstance, getUserByUserId } from '../../../../../services/api'
import { DisableUserButton } from './components/DisableUserButton'

export interface UsuariosAtivosTableProps {
  app: { appTag: string; appName: string }
  users: User[]
  reloadMainPage: () => void
}

interface getProfileUserPropsReturn {
  profileItem: Profile[]
  valueSelected: string
  name: string
}

export const UsuariosAtivosTable = ({
  app,
  users,
  reloadMainPage,
}: UsuariosAtivosTableProps) => {
  const toast = useToast()
  const { session } = useSession()

  const [appTag, setAppTag] = useState<string>('')

  const appsWithPermissions = [
    'SIMPLAZ',
    'GE_L4',
    'GE_L3',
    'GE_L2',
    'GE_L1',
    'GE_N4',
    'GE_N3',
    'GE_N2',
    'GE_N1',
    'UN_L1',
    'UN_L2',
    'UN_L3',
    'UN_N1',
    'UN_N2',
    'UN_N3',
    'SP_P2',
    'SP_P3',
    'SP_P4',
  ]

  const normalizeAppName = () => {
    if (
      app.appTag.toUpperCase().includes('SIMPLAZ') ||
      app.appTag.toUpperCase().includes('SP_')
    ) {
      setAppTag('SIMPLAZ')
    }

    if (app.appTag.toUpperCase().includes('GE_')) {
      setAppTag('GESTOR')
    }

    if (app.appTag.toUpperCase().includes('UN_')) {
      setAppTag('UNIQUE')
    }
  }

  const containProfileApps = (profileApps: string): boolean => {
    if (profileApps === '') return false
    if (profileApps === undefined) return false
    if (profileApps === null) return false
    if (typeof profileApps !== 'string') return false
    if (profileApps.length < 8) return false

    return true
  }

  const getProfileUser = (profileApps: string): getProfileUserPropsReturn => {
    // pegar o perfil do usuário para o app
    if (containProfileApps(profileApps)) {
      const appsJson = JSON.parse(profileApps)

      // se o app for o simplaz, pegar o perfil do workspace
      if (
        appsJson.some((app: any) => app.Aplicativo === appTag) &&
        appTag === 'SIMPLAZ'
      ) {
        const simplazProfile = appsJson.find(
          (app: any) => app.Aplicativo === 'SIMPLAZ',
        ).Perfil

        const nameProfile = WorkspaceProfiles.find(
          (app) => app.id === simplazProfile,
        ) as Profile
        return {
          profileItem: WorkspaceProfiles,
          valueSelected: nameProfile.id,
          name: nameProfile.name.replace('Usuário', 'Operacional'),
        }
      }

      // se o app for o gestor, pegar o perfil do gestor
      if (
        appsJson.some((app: any) => app.Aplicativo === appTag) &&
        appTag === 'GESTOR'
      ) {
        const gestorProfile = appsJson.find(
          (app: any) => app.Aplicativo === 'GESTOR',
        ).Perfil

        const nameProfile = GestorProfiles.find(
          (app) => app.id === gestorProfile,
        ) as Profile
        return {
          profileItem: GestorProfiles,
          valueSelected: nameProfile.id,
          name: nameProfile.name,
        }
      }

      // se o app for o unique, pegar o perfil do unique
      if (
        appsJson.some((app: any) => app.Aplicativo === appTag) &&
        appTag === 'UNIQUE'
      ) {
        const uniqueProfile = appsJson.find(
          (app: any) => app.Aplicativo === 'UNIQUE',
        ).Perfil

        const nameProfile = UniqueProfiles.find(
          (app) => app.id === uniqueProfile,
        ) as Profile
        return {
          profileItem: UniqueProfiles,
          valueSelected: nameProfile.id,
          name: nameProfile.name,
        }
      }
    } else {
      if (appTag === 'SIMPLAZ') {
        return {
          profileItem: WorkspaceProfiles,
          valueSelected: '999',
          name: 'Selecionar',
        }
      }
      if (appTag === 'UNIQUE') {
        return {
          profileItem: UniqueProfiles,
          valueSelected: '999',
          name: 'Selecionar',
        }
      }
      if (appTag === 'GESTOR') {
        return {
          profileItem: GestorProfiles,
          valueSelected: '999',
          name: 'Selecionar',
        }
      }
    }

    return {
      profileItem: WorkspaceProfiles,
      valueSelected: '999',
      name: 'Selecionar',
    }
  }

  const handleProfileApp = (user: User, profileId: string) => {
    if (
      user.perfilAplicativos !== '' &&
      user.perfilAplicativos.length > 8 &&
      user.perfilAplicativos !== undefined
    ) {
      const profileApp = JSON.parse(user.perfilAplicativos)

      if (profileApp.some((app: any) => app.Aplicativo === appTag)) {
        const profileAppFilter = profileApp.filter((app: any) => {
          return app.Aplicativo !== appTag
        })

        profileAppFilter.push({
          Aplicativo: appTag,
          Perfil: profileId,
        })

        const newProfileApp =
          profileAppFilter.length === 0
            ? [
                {
                  Aplicativo: appTag,
                  Perfil: profileId,
                },
              ]
            : profileAppFilter

        return newProfileApp
      }
    } else {
      const newProfileApp = [
        {
          Aplicativo: appTag,
          Perfil: profileId,
        },
      ]

      return newProfileApp
    }
  }

  const handleChangeProfileUser = async (user: User, profileId: string) => {
    try {
      const { password } = await getUserByUserId(user.userId)

      const changedUser = {
        ...user,
        perfilAplicativos: JSON.stringify(handleProfileApp(user, profileId)),
        companyCNPJCPF: session.company.document,
        password: password,
      }

      await apiInstance(8006).put('UserGestor', changedUser, {
        headers: {
          authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
          companyId: session.company.id.toString(),
          cnpjcpf: session.company.document,
        },
      })

      toast({
        title: 'Alteração realizada',
        status: 'success',
        duration: 3500,
        position: 'bottom-right',
        isClosable: true,
      })

      reloadMainPage()
    } catch (e) {
      toast({
        title: 'Algo deu errado',
        status: 'error',
        duration: 3500,
        position: 'bottom-right',
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    normalizeAppName()
  }, [app])

  return (
    <Box height="100%">
      <Text
        fontSize={'16px'}
        fontWeight={600}
        color={'blue'}
      >{` Usuários ativos (${users.length})`}</Text>
      <Box overflowY={'auto'} height={'calc(100vh - 500px)'}>
        <TableContainer>
          <Table variant="simple" size={'xs'}>
            <Thead>
              <Tr>
                <Th>
                  <Text fontSize={'14px'} color={'#BABABA'}>
                    Nome
                  </Text>
                </Th>
                <Th>
                  <Text fontSize={'14px'} color={'#BABABA'}>
                    E-mail
                  </Text>
                </Th>
                <Th>
                  <Text fontSize={'14px'} color={'#BABABA'}>
                    Perfil
                  </Text>
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => {
                return (
                  <Tr key={user.userId}>
                    <Td>
                      <Text fontSize={'14px'} color={'#606060'}>
                        {user.name}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize={'14px'} color={'#606060'}>
                        {user.email}
                      </Text>
                    </Td>
                    <Td>
                      {appsWithPermissions.includes(
                        app.appTag.toUpperCase(),
                      ) ? (
                        <Select
                          onChange={(e) =>
                            handleChangeProfileUser(user, e.target.value)
                          }
                          value={
                            getProfileUser(user.perfilAplicativos).valueSelected
                          }
                          fontFamily="Sora"
                          fontSize="14px"
                          color="#606060"
                          border="none"
                        >
                          <option key="999" value="999">
                            Selecione
                          </option>
                          {getProfileUser(
                            user.perfilAplicativos,
                          )?.profileItem.map((item) => (
                            <option key={item.id} value={item.id}>
                              Perfil{' '}
                              {item.name.replace('Usuário', 'Operacional')}
                            </option>
                          ))}
                        </Select>
                      ) : (
                        '-'
                      )}
                    </Td>
                    <Td isNumeric>
                      {
                        <DisableUserButton
                          appTag={app.appTag}
                          user={user}
                          reloadMainPage={reloadMainPage}
                        />
                      }
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
