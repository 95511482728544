import { Button, Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { statusNotes } from '../..'
import { handleSaveOrderConfiguration } from '../../utils/saveOrderAndImportToGestor'

interface ButtonFooterTableProps {
  lineSelectedToImported: statusNotes
  setSelectedLineToImport: (value: statusNotes) => void
  newTable: any[]
  session: any
  openModal: () => void
  onOpen: () => void
}
export const ButtonFooterTable = ({
  lineSelectedToImported,
  setSelectedLineToImport,
  newTable,
  session,
  openModal,
  onOpen,
}: ButtonFooterTableProps) => {
  const navigate = useNavigate()

  const goHome = () => {
    navigate('/importa-facil')
  }

  return (
    <Flex mt={{ xl: '19px', '2xl': '49px' }}>
      <Button
        w="132px"
        h={'52px'}
        bg="transparent"
        _hover={{ bg: '#fff' }}
        border="1px solid #1155BB"
        color="#1155BB"
        borderRadius={'4px'}
        fontSize="16px"
        fontWeight={'700'}
        onClick={goHome}
      >
        Retornar
      </Button>

      <Button
        w="267px"
        h={'52px'}
        bg="#1155BB"
        _hover={{ bg: '#006AFF' }}
        color="#fff"
        borderRadius={'4px'}
        fontSize="16px"
        fontWeight={'700'}
        disabled={lineSelectedToImported.lineSelectedToImport.length === 0}
        ml="20px"
        onClick={() => {
          onOpen()
          openModal()
          handleSaveOrderConfiguration(
            lineSelectedToImported.lineSelectedToImport,
            newTable,
            session.company.document
          )
          setSelectedLineToImport({
            ...lineSelectedToImported,
            lineSelectedToImport: [],
          })
        }}
      >
        Finalizar importação
      </Button>
    </Flex>
  )
}
