import { postGravarLogRequest } from '@/services/interfaces/postGravarLogRequest'
import { Flex } from '@chakra-ui/react'
import { useCallback, useEffect } from 'react'
import { useSession } from 'src/contexts'
import { postGravarLog } from 'src/services/api'

export const Quod = () => {
  const { session } = useSession()

  const sendLog = useCallback(() => {
    const log: postGravarLogRequest = {
      EmpresaCnpj: session.company.document,
      IdUsuario: session.id,
      NomeUsuario: session.name,
      Aplicativo: 'Quod',
      Acao: 'Acesso ao Quod',
      TipoAcao: 'Login',
      EndPoint: '',
    }

    postGravarLog(log)
  }, [])

  useEffect(() => {
    sendLog()
  }, [sendLog])
  return (
    <Flex w="100%">
      <object
        width={'100%'}
        type="text/html"
        data="https://consultasoluti.com.br/loja/?p=i559235-londrisoft-"
      >
        <p>Your browser does not support iframes.</p>
      </object>
    </Flex>
  )
}
