import { TReturnTable } from '../correlationPage/types/types'

export const returnTablePlanilhaPadrao = (): TReturnTable[] => {
  const tablePlanilhaPadrao: TReturnTable[] = []

  for (let i = 0; i <= 21; i++) {
    const json = {
      key_table: i,
      table_index: i,
      status: true,
    }
    tablePlanilhaPadrao.push(json)
  }

  return tablePlanilhaPadrao
}
