import { useToast } from '@chakra-ui/react'
import { getDataGestor } from 'src/services/reduncandeAPI'
import { useSession } from '../contexts/sessions'
import { apiInstance } from '../services/api'
import { setCookieToken } from './createCookie'
import { encrypted } from './hashing'
import { EncryptCrypto } from './setHashingCrypto'

export const useSystems = () => {
  const toast = useToast()
  let { session } = useSession()

  const dataSimplaz = JSON.stringify({
    id: session.id,
    name: session.name,
    email: session.email,
    origin: session.origin,
    perfilId: session.perfilWs,
    plan: { aplicativos: session.plan.aplicativos },
    company: {
      id: session.company.id,
      name: session.company.name,
      document: session.company.document,
      local: session.type === 0 ? true : false,
    },
  })
  const simplaz = encrypted(dataSimplaz)
  const hashUserGerenciadorVendas = EncryptCrypto(
    session.company.document,
    'GESTOR',
    session.email,
  )
  const environmentIsDevelopment =
    window.location.origin.includes('localhost') ||
    window.location.origin.includes('alpha') ||
    window.location.origin.includes('192.168.0.221')
      ? true
      : false

  const openGestor = async () => {
    try {
      const { data: company } = await apiInstance(8006).get(
        `Company/${session.company.id}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
            companyId: session.company.id.toString(),
          },
        },
      )

      session = {
        ...session,
        company: {
          ...session.company,
          server: company.Nserver,
        },
      }

      const doc =
        session.company.document.length !== 14
          ? session.company.document.substring(4, 12)
          : session.company.document.substring(6, 14)

      let pass =
        (
          session.email.substring(0, 1).toUpperCase().replace(/[0-9]/g, '') +
          session.email.substring(1, 2).toLowerCase().replace(/[0-9]/g, '')
        ).length !== 2
          ? `Lo${doc}`
          : session.email.substring(0, 1).toUpperCase() +
            session.email.substring(1, 2).toLowerCase().replace(/[0-9]/g, '') +
            doc

      const { data } = await apiInstance(8012).get<any>(
        `/user/checkActivityDirectory/${session.email}`,
        {
          headers: {
            Authorization: session.token,
          },
        },
      )

      if (!data.status) {
        toast({
          title: 'Estamos revisando seu cadastro',
          status: 'warning',
          position: 'top',
          isClosable: true,
        })
        return
      }

      await apiInstance(9000).post(
        '/logsApi/Logs/GravarLogs',
        {
          EmpresaCnpj: session.company.document,
          IdUsuario: session.id,
          NomeUsuario: session.name,
          Aplicativo: 'Gestor',
          Acao: 'Acesso ao Gestor',
          TipoAcao: 'Login',
          EndPoint: 'servidor: ' + session.company.server.toString(),
        },
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        },
      )

      const userAd = data.userNameAD
      const params = data.param

      let baseUrl = process.env.REACT_APP_GESTOR_1

      if (session.company.server === 0) {
        baseUrl = getDataGestor()[0]
      } else if (session.company.server === 1) {
        baseUrl = getDataGestor()[1]
      } else {
        baseUrl = getDataGestor()[2]
      }

      const url = `${baseUrl}?user=londrisoft\\\\${userAd}&pwd=${pass}&program=C:\\londrisoft\\Gestor_Prime\\appgestor.exe&startupdir=C:\\londrisoft\\Gestor_Prime\\&params='${params}'`

      // window.location.href = url

      // console.log(data)
      window.open(url)
    } catch (error: any) {
      toast({
        title: 'Falha ao abrir o sistema',
        description: `${error.message}`,
        status: 'warning',
        position: 'top',
        isClosable: true,
      })
    }
  }

  const openUnique = async () => {
    try {
      const { data: company } = await apiInstance(8006).get(
        `Company/${session.company.id}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
            companyId: session.company.id.toString(),
          },
        },
      )

      session = {
        ...session,
        company: {
          ...session.company,
          server: company.Nserver,
        },
      }

      const doc =
        session.company.document.length !== 14
          ? session.company.document.substring(4, 12)
          : session.company.document.substring(6, 14)

      let pass =
        (
          session.email.substring(0, 1).toUpperCase().replace(/[0-9]/g, '') +
          session.email.substring(1, 2).toLowerCase().replace(/[0-9]/g, '')
        ).length !== 2
          ? `Lo${doc}`
          : session.email.substring(0, 1).toUpperCase() +
            session.email.substring(1, 2).toLowerCase().replace(/[0-9]/g, '') +
            doc

      const { data } = await apiInstance(8012).get<any>(
        `/user/checkActivityDirectory/${session.email}`,
        {
          headers: {
            Authorization: session.token,
          },
        },
      )

      if (!data.status) {
        toast({
          title: 'Estamos revisando seu cadastro',
          status: 'warning',
          position: 'top',
          isClosable: true,
        })
        return
      }

      await apiInstance(9000).post(
        '/logsApi/Logs/GravarLogs',
        {
          EmpresaCnpj: session.company.document,
          IdUsuario: session.id,
          NomeUsuario: session.name,
          Aplicativo: 'Unique',
          Acao: 'Acesso ao Unique',
          TipoAcao: 'Login',
          EndPoint: 'servidor: ' + session.company.server.toString(),
        },
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        },
      )
      const userAd = data.userNameAD
      const params = data.param

      let baseUrl = process.env.REACT_APP_GESTOR_3

      const url = `${baseUrl}?user=londrisoft\\\\${userAd}&pwd=${pass}&program=C:\\londrisoft\\Gestor_Prime\\appgestor.exe&startupdir=C:\\londrisoft\\Gestor_Prime\\&params='${params}'`

      // window.location.href = url

      // console.log(data)
      window.open(url)
    } catch (error: any) {
      toast({
        title: 'Falha ao abrir o sistema',
        description: `${error.message}`,
        status: 'warning',
        position: 'top',
        isClosable: true,
      })
    }
  }

  const openSimplaz = async () => {
    await apiInstance(9000).post(
      '/logsApi/Logs/GravarLogs',
      {
        EmpresaCnpj: session.company.document,
        IdUsuario: session.id,
        NomeUsuario: session.name,
        Aplicativo: 'Simplaz',
        Acao: 'Acesso ao Simplaz',
        TipoAcao: 'Login',
        EndPoint: 'Simplaz/Login',
      },
      {
        headers: {
          GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
        },
      },
    )

    window.open(
      environmentIsDevelopment
        ? `https://alpha-simplaz.londrisoftservices.com.br/#/login/?dados=${JSON.stringify(
            [...simplaz],
          )}`
        : `https://simplaz.londrisoft.com.br/#/login/?dados=${JSON.stringify([
            ...simplaz,
          ])}`,
    )
  }

  const openGerenciadorVendas = async () => {
    await apiInstance(9000).post(
      '/logsApi/Logs/GravarLogs',
      {
        EmpresaCnpj: session.company.document,
        IdUsuario: session.id,
        NomeUsuario: session.name,
        Aplicativo: 'Gerenciador',
        Acao: 'Acesso ao Gerenciador de vendas pelo workspace',
        TipoAcao: 'Login',
        EndPoint: 'gerenciador/Login',
      },
      {
        headers: {
          GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
        },
      },
    )
    setCookieToken(hashUserGerenciadorVendas)

    window.open('https://gerenciamento-vendas.vercel.app/')
  }

  return { openGestor, openUnique, openSimplaz, openGerenciadorVendas }
}
