import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { useSession } from '../../../../../contexts/sessions'

import { ProfileTag } from '../../../../ProfileTag'

import { ReactComponent as LogoutIcon } from 'src/assets/icons/logout_icon.svg'
import { ReactComponent as QuestionIcon } from 'src/assets/icons/question.svg'
import { setupProfile } from 'src/constants/setupProfile'

export const HeaderMenu = () => {
  const { openLogoutModal, session } = useSession()

  const { profile } = setupProfile({
    profileId: session.perfilWs,
    origin: session.origin,
  })

  return (
    <Menu isLazy flip offset={[0, 5]} placement="bottom-end">
      <MenuButton
        as={IconButton}
        aria-label="Menu Options"
        icon={
          <Avatar
            name={session.name}
            size={'sm'}
            bg={profile.bgColor}
            color={profile.textColor}
          />
        }
        variant="link"
      />
      <MenuList
        fontSize={'14px'}
        color={'low.medium'}
        width={'300px'}
        zIndex={4}
      >
        <Box flexDirection={'column'} padding={'6.4px 12.8px'}>
          <Text fontSize={'13px'}>{session.name.toUpperCase()}</Text>
          <Text fontSize={'13px'} mt="2px" color="low.light">
            {session.email}
          </Text>
          <ProfileTag profileId={session.perfilWs} mt="5px" />
        </Box>

        <MenuDivider />

        <MenuItem
          fontSize="13px"
          icon={<QuestionIcon />}
          height={'40px'}
          onClick={() => window.open(profile.link)}
          _hover={{ backgroundColor: '#1155bb17' }}
        >
          Central de ajuda{' '}
        </MenuItem>
        <MenuItem
          fontSize="13px"
          icon={<LogoutIcon />}
          height={'40px'}
          onClick={() => openLogoutModal()}
          _hover={{ backgroundColor: '#1155bb17' }}
        >
          Desconectar
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
