import { ElementType } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSession } from 'src/contexts/sessions'
import { ReactComponent as ClubeContadorIcon } from '../../assets/icons/clube-contador-icon-sidebar.svg'
import { ReactComponent as DashboardBIIcon } from '../../assets/icons/dashboard-bi.svg'
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg'
import { ReactComponent as ClientIcon } from '../../assets/icons/every-user.svg'
import { ReactComponent as FolderIcon } from '../../assets/icons/folder.svg'
import { ReactComponent as GestorIcon } from '../../assets/icons/gestor.svg'
import { ReactComponent as ImportadorIcon } from '../../assets/icons/importador-icon-sidemenu.svg'
import { ReactComponent as QuodIcon } from '../../assets/icons/quod-icon-sidebar.svg'
import { ReactComponent as GearIcon } from '../../assets/icons/setting-two.svg'
import { ReactComponent as SimplazIcon } from '../../assets/icons/simplaz_icon.svg'
import { ReactComponent as StoreIcon } from '../../assets/icons/store.svg'
import { ReactComponent as UsersIcon } from '../../assets/icons/user-search.svg'
import { ReactComponent as UniqueIcon } from '../../assets/unique.svg'
import { useSystems } from '../../utilities/useSystems'

type Sections = 'Produtos' | 'Gerência' | 'Outros'

interface Item {
  label: string
  icon: ElementType
  section: Sections
  action: () => void
  beta?: boolean
  permissions?: string[]
}

interface SideMenuSectionItems {
  name: Sections
  items: Item[]
}

export const useSideMenuSection = () => {
  const navigate = useNavigate()
  const { openGestor, openUnique, openSimplaz, openGerenciadorVendas } =
    useSystems()
  let { session } = useSession()

  const SideMenuSections: SideMenuSectionItems[] = [
    {
      name: 'Produtos',
      items: [
        {
          label: 'Gestor',
          icon: GestorIcon,
          section: 'Produtos',
          action: () => openGestor(),
          permissions: ['GE_N1', 'CLOUD'],
        },
        {
          label: 'Gestor',
          icon: GestorIcon,
          section: 'Produtos',
          action: () => openGestor(),
          permissions: ['GE_N2', 'CLOUD'],
        },
        {
          label: 'Gestor',
          icon: GestorIcon,
          section: 'Produtos',
          action: () => openGestor(),
          permissions: ['GE_N3', 'CLOUD'],
        },
        {
          label: 'Gestor',
          icon: GestorIcon,
          section: 'Produtos',
          action: () => openGestor(),
          permissions: ['GE_N4', 'CLOUD'],
        },
        {
          label: 'Unique',
          icon: UniqueIcon,
          section: 'Produtos',
          action: () => openUnique(),
          permissions: ['UN_N1', 'CLOUD'],
        },
        {
          label: 'Unique',
          icon: UniqueIcon,
          section: 'Produtos',
          action: () => openUnique(),
          permissions: ['UN_N2', 'CLOUD'],
        },
        {
          label: 'Unique',
          icon: UniqueIcon,
          section: 'Produtos',
          action: () => openUnique(),
          permissions: ['UN_N3', 'CLOUD'],
        },
        {
          label: 'Unique',
          icon: UniqueIcon,
          section: 'Produtos',
          action: () => openUnique(),
          permissions: ['UN_N4', 'CLOUD'],
        },
        {
          label: 'Simplaz',
          icon: SimplazIcon,
          section: 'Produtos',
          action: () => openSimplaz(),
          beta: false,
          permissions: ['SIMPLAZ'],
        },
        {
          label: 'Simplaz',
          icon: SimplazIcon,
          section: 'Produtos',
          action: () => openSimplaz(),
          beta: false,
          permissions: ['SP_P2'],
        },
        {
          label: 'Simplaz',
          icon: SimplazIcon,
          section: 'Produtos',
          action: () => openSimplaz(),
          beta: false,
          permissions: ['SP_P3'],
        },
        {
          label: 'Simplaz',
          icon: SimplazIcon,
          section: 'Produtos',
          action: () => openSimplaz(),
          beta: false,
          permissions: ['SP_P4'],
        },
        {
          label: 'Simplaz',
          icon: SimplazIcon,
          section: 'Produtos',
          action: () => openSimplaz(),
          beta: false,
          permissions: ['SP_CTB_P5'],
        },
        {
          label: 'Simplaz',
          icon: SimplazIcon,
          section: 'Produtos',
          action: () => openSimplaz(),
          beta: false,
          permissions: ['SP_CTB_P6'],
        },
        {
          label: 'Simplaz',
          icon: SimplazIcon,
          section: 'Produtos',
          action: () => openSimplaz(),
          beta: false,
          permissions: ['SP_CTB_P7'],
        },
        {
          label: 'Importa Fácil',
          icon: ImportadorIcon,
          section: 'Produtos',
          action: () => navigate('importa-facil'),
          beta: true,
          permissions: ['IMPXLS', 'GE_N1', 'CLOUD'],
        },
        {
          label: 'Importa Fácil',
          icon: ImportadorIcon,
          section: 'Produtos',
          action: () => navigate('importa-facil'),
          beta: true,
          permissions: ['IMPXLS', 'GE_N2', 'CLOUD'],
        },
        {
          label: 'Importa Fácil',
          icon: ImportadorIcon,
          section: 'Produtos',
          action: () => navigate('importa-facil'),
          beta: true,
          permissions: ['IMPXLS', 'GE_N3', 'CLOUD'],
        },
        {
          label: 'Importa Fácil',
          icon: ImportadorIcon,
          section: 'Produtos',
          action: () => navigate('importa-facil'),
          beta: true,
          permissions: ['IMPXLS', 'GE_N4', 'CLOUD'],
        },
        {
          label: 'Analytics',
          icon: DashboardIcon,
          section: 'Produtos',
          action: () => navigate('analytics'),
          beta: false,
          permissions: ['ANALYTICSBI', 'ADMIN'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: false,
          permissions: ['INTELVENDAS', 'GE_N1'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: false,
          permissions: ['INTELVENDAS', 'GE_N2'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: false,
          permissions: ['INTELVENDAS', 'GE_N3'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: false,
          permissions: ['INTELVENDAS', 'GE_N4'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: false,
          permissions: ['INTELADM', 'UN_N1'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: false,
          permissions: ['INTELADM', 'UN_N2'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: false,
          permissions: ['INTELADM', 'UN_N3'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: false,
          permissions: ['INTELADM', 'UN_N4'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: false,
          permissions: ['INTELADM', 'UN_L1'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: false,
          permissions: ['INTELADM', 'UN_L2'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: false,
          permissions: ['INTELADM', 'UN_L3'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: false,
          permissions: ['INTELADM', 'UN_L4'],
        },
        {
          label: 'Quod',
          icon: QuodIcon,
          section: 'Produtos',
          action: () => navigate('quod'),
          beta: false,
          permissions: ['QUOD'],
        },
        {
          label: 'Clube contador',
          icon: ClubeContadorIcon,
          section: 'Produtos',
          action: () => navigate('clube-contador'),
          beta: false,
          permissions: ['CL_CTD_P1', 'UN_N1'],
        },
        {
          label: 'Clube contador',
          icon: ClubeContadorIcon,
          section: 'Produtos',
          action: () => navigate('clube-contador'),
          beta: false,
          permissions: ['CL_CTD_P2', 'UN_N2'],
        },
        {
          label: 'Clube contador',
          icon: ClubeContadorIcon,
          section: 'Produtos',
          action: () => navigate('clube-contador'),
          beta: false,
          permissions: ['CL_CTD_P3', 'UN_N3'],
        },
        {
          label: 'Clube contador',
          icon: ClubeContadorIcon,
          section: 'Produtos',
          action: () => navigate('clube-contador'),
          beta: false,
          permissions: ['CL_CTD_P4', 'UN_N4'],
        },
        {
          label: 'Clube contador',
          icon: ClubeContadorIcon,
          section: 'Produtos',
          action: () => navigate('clube-contador'),
          beta: false,
          permissions: ['CL_CTD_P1', 'UN_N1'],
        },
        {
          label: 'Clube contador',
          icon: ClubeContadorIcon,
          section: 'Produtos',
          action: () => navigate('clube-contador'),
          beta: false,
          permissions: ['CL_CTD_P2', 'UN_L2'],
        },
        {
          label: 'Clube contador',
          icon: ClubeContadorIcon,
          section: 'Produtos',
          action: () => navigate('clube-contador'),
          beta: false,
          permissions: ['CL_CTD_P3', 'UN_L3'],
        },
        {
          label: 'Clube contador',
          icon: ClubeContadorIcon,
          section: 'Produtos',
          action: () => navigate('clube-contador'),
          beta: false,
          permissions: ['CL_CTD_P4', 'UN_L4'],
        },
        {
          label: 'Clube contador',
          icon: ClubeContadorIcon,
          section: 'Produtos',
          action: () => navigate('clube-contador'),
          beta: false,
          permissions: ['CL_CTD_P5', 'UN_L1'],
        },
        // {
        //   label: 'Bime',
        //   icon: ClubeContadorIcon,
        //   section: 'Produtos',
        //   action: () => openGerenciadorVendas(),
        //   beta: false,
        //   permissions: ['GE_N1'],
        // },
        // {
        //   label: 'Bime',
        //   icon: ClubeContadorIcon,
        //   section: 'Produtos',
        //   action: () => openGerenciadorVendas(),
        //   beta: false,
        //   permissions: ['GE_N2'],
        // },
        // {
        //   label: 'Bime',
        //   icon: ClubeContadorIcon,
        //   section: 'Produtos',
        //   action: () => openGerenciadorVendas(),
        //   beta: false,
        //   permissions: ['GE_N3'],
        // },
        // {
        //   label: 'Bime',
        //   icon: ClubeContadorIcon,
        //   section: 'Produtos',
        //   action: () => openGerenciadorVendas(),
        //   beta: false,
        //   permissions: ['GE_N4'],
        // },
      ],
    },
    {
      name: 'Gerência',
      items: [
        {
          label: 'Arquivos',
          icon: FolderIcon,
          section: 'Gerência',
          action: () => navigate('files/relatorios'),
          permissions: ['GE_N1'],
        },
        {
          label: 'Arquivos',
          icon: FolderIcon,
          section: 'Gerência',
          action: () => navigate('files/relatorios'),
          permissions: ['GE_N2'],
        },
        {
          label: 'Arquivos',
          icon: FolderIcon,
          section: 'Gerência',
          action: () => navigate('files/relatorios'),
          permissions: ['GE_N3'],
        },
        {
          label: 'Arquivos',
          icon: FolderIcon,
          section: 'Gerência',
          action: () => navigate('files/relatorios'),
          permissions: ['GE_N4'],
        },
        {
          label: 'Arquivos',
          icon: FolderIcon,
          section: 'Gerência',
          action: () => navigate('files/relatorios'),
          permissions: ['UN_N1'],
        },
        {
          label: 'Arquivos',
          icon: FolderIcon,
          section: 'Gerência',
          action: () => navigate('files/relatorios'),
          permissions: ['UN_N2'],
        },
        {
          label: 'Arquivos',
          icon: FolderIcon,
          section: 'Gerência',
          action: () => navigate('files/relatorios'),
          permissions: ['UN_N3'],
        },
        {
          label: 'Arquivos',
          icon: FolderIcon,
          section: 'Gerência',
          action: () => navigate('files/relatorios'),
          permissions: ['UN_N4'],
        },
        // {
        //   label: 'Arquivos',
        //   icon: FolderIcon,
        //   section: 'Gerência',
        //   action: () => navigate('files'),
        //   permissions: ['UN_L1'],
        // },
        // {
        //   label: 'Arquivos',
        //   icon: FolderIcon,
        //   section: 'Gerência',
        //   action: () => navigate('files'),
        //   permissions: ['UN_L2'],
        // },
        // {
        //   label: 'Arquivos',
        //   icon: FolderIcon,
        //   section: 'Gerência',
        //   action: () => navigate('files'),
        //   permissions: ['UN_L3'],
        // },
        // {
        //   label: 'Arquivos',
        //   icon: FolderIcon,
        //   section: 'Gerência',
        //   action: () => navigate('files'),
        //   permissions: ['UN_L4'],
        // },
        {
          label: 'Usuários',
          icon: UsersIcon,
          section: 'Gerência',
          action: () => navigate('users'),
        },
        {
          label: 'Participantes',
          icon: ClientIcon,
          section: 'Gerência',
          action: () => navigate('participants'),
          permissions: ['UN_N1'],
          beta: true,
        },
        {
          label: 'Participantes',
          icon: ClientIcon,
          section: 'Gerência',
          action: () => navigate('participants'),
          permissions: ['UN_N2'],
          beta: true,
        },
        {
          label: 'Participantes',
          icon: ClientIcon,
          section: 'Gerência',
          action: () => navigate('participants'),
          permissions: ['UN_N3'],
          beta: true,
        },
        {
          label: 'Participantes',
          icon: ClientIcon,
          section: 'Gerência',
          action: () => navigate('participants'),
          permissions: ['UN_N4'],
          beta: true,
        },
        {
          label: 'Participantes',
          icon: ClientIcon,
          section: 'Gerência',
          action: () => navigate('participants'),
          permissions: ['UN_L1'],
          beta: true,
        },
        {
          label: 'Participantes',
          icon: ClientIcon,
          section: 'Gerência',
          action: () => navigate('participants'),
          permissions: ['UN_L2'],
          beta: true,
        },
        {
          label: 'Participantes',
          icon: ClientIcon,
          section: 'Gerência',
          action: () => navigate('participants'),
          permissions: ['UN_L3'],
          beta: true,
        },
        {
          label: 'Participantes',
          icon: ClientIcon,
          section: 'Gerência',
          action: () => navigate('participants'),
          permissions: ['UN_L4'],
          beta: true,
        },
        {
          label: 'Configurações',
          icon: GearIcon,
          section: 'Gerência',
          action: () => navigate('company-settings/geral'),
        },
      ],
    },
    {
      name: 'Outros',
      items: [
        {
          label: 'Apps',
          icon: StoreIcon,
          section: 'Outros',
          action: () => navigate('/store/integrations'),
          // permissions: ['ADMIN'],
          beta: false,
        },
      ],
    },
  ]

  return { SideMenuSections }
}
