import {
  Button,
  Center,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useNavigate } from 'react-router-dom'
import { encryptedSimple } from 'src/utilities/hashing'
import * as XLSX from 'xlsx'
import SaveFileSVG from '../../../../assets/icons/savefilesucess.svg'
import Step1SVG from '../../../../assets/icons/step1.svg'
import UploadSVG from '../../../../assets/icons/upload.svg'
import UploadFileSVG from '../../../../assets/icons/uploadfile.svg'

let dataFile: any

interface IDropzoneModal {
  openModal: boolean
  render: number
}

export const DropzoneModal = ({ openModal, render }: IDropzoneModal) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()

  useEffect(() => {
    if (render > 2) {
      onOpen()
    }
  }, [openModal])

  const toast = useToast()

  const dropzoneModal = (
    <ModalContent bg="#F7F8FA" fontFamily={'Inter'}>
      <ModalHeader display="flex" mt={'82px'}>
        <Flex ml={'8%'}>
          <Image src={UploadFileSVG} alt="uploadfile" />
          <Text mt="1%" ml="17px" fontFamily={'Inter'}>
            Carregue aqui seu arquivo
          </Text>
          <Flex>
            <Image src={Step1SVG} alt="step1" ml="60%" />
          </Flex>
        </Flex>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody alignSelf="center">
        <Dropzone onDrop={(acceptedFiles) => handleFiles(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <Flex
              cursor="pointer"
              border="1px solid #FFFFFF"
              bg="#FFFFFF"
              borderRadius="11px"
              alignItems={'center'}
              justifyContent="center"
              {...getRootProps()}
            >
              <Flex display="grid" gap="20px" w={'918px'} h="374px">
                <Center flexDir="column">
                  <Image src={UploadSVG} alt="upload" />
                  <Text
                    fontWeight="700"
                    fontSize="22px"
                    color="#333333"
                    textAlign={'center'}
                    mt={'2%'}
                    fontFamily="Inter"
                  >
                    Arraste e solte o arquivo ou <br />
                    selecione em seu computador
                  </Text>
                  <Flex mt={'2%'}>
                    <Text fontWeight={'700'} fontSize="14px">
                      Suporta arquivos em formatos:{' '}
                    </Text>
                    <Text fontWeight={'400'} fontSize="14px">
                      XLS, XLSX ou CSV
                    </Text>
                  </Flex>
                  <input {...getInputProps()} />

                  <Button
                    color="#FFFFFF"
                    bg="#1155BB"
                    borderRadius="6px"
                    w={'212px'}
                    h={'44px'}
                    mt={'4%'}
                    _hover={{ bg: '#006AFF' }}
                  >
                    Selecionar arquivo
                  </Button>
                </Center>
              </Flex>
            </Flex>
          )}
        </Dropzone>
      </ModalBody>

      <ModalFooter alignSelf="flex-start" mb={'10%'} ml="8%">
        <Text>O tamanho do arquivo não deve ultrapassar 2MB.</Text>
      </ModalFooter>
    </ModalContent>
  )

  const loadingModal = (
    <ModalContent bg="#F7F8FA" h="488px">
      <ModalBody alignSelf="center" mt="18%">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </ModalBody>
    </ModalContent>
  )

  const successModal = (
    <ModalContent bg="#F7F8FA" h="372px" fontFamily={'Inter'}>
      <ModalBody alignSelf="center">
        <Flex display="grid " justifyItems="center" mt="18%">
          <Image src={SaveFileSVG} alt="savefile" />
          <Text
            fontWeight="600"
            fontSize="34px"
            color="#333333"
            fontFamily={'Inter'}
          >
            Arquivo enviado com sucesso!
          </Text>
        </Flex>
      </ModalBody>
    </ModalContent>
  )

  const [steps, setSteps] = useState(dropzoneModal)

  const transitionModalSteps = () => {
    setSteps(loadingModal)
    setTimeout(() => {
      setSteps(successModal)
      setTimeout(() => {
        navigate(
          `/importa-facil/selectHeaderTable/${encryptedSimple(
            dataFile
          ).replaceAll('/', '98fds49f8d5ds98f5ds98f54ds984d9841fvcx9581ff1')}`
        )
      }, 1000)
    }, 1500)
  }

  const handleFiles = async (file: any) => {
    const fileName = file[0].name
    const extensionValid = ['xlsx', 'xls', 'csv']
    const extensionFile = fileName.split('.').pop()

    extensionValid.some((el) => el === extensionFile)
      ? transitionModalSteps()
      : toast({
          title: 'Arquivo inválido',
          duration: 2000,
          isClosable: true,
          status: 'error',
        })

    const reader = new FileReader()
    reader.onload = (ev: any) => {
      const fileData = ev.target.result
      const workbook = XLSX.read(fileData, { type: 'binary' })
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      dataFile = data
    }

    reader.readAsBinaryString(file[0])
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        {steps}
      </Modal>
    </>
  )
}
