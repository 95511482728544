import { Flex, useDisclosure } from '@chakra-ui/react'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSession } from 'src/contexts'
import { replaceTextDescrypt } from 'src/screens/Importador/utils/replaceTextIcorrects'
import { decryptedSimple } from 'src/utilities/hashing'
import { ButtonFooterTable } from './components/buttonsFooterTable'
import { Header } from './components/header'
import { LoadingModal } from './components/modals/loadingModal'
import { ModalBodyDefault } from './components/modals/modalBody'
import { SuccessModal } from './components/modals/successModal'
import { ReleasesBottom } from './components/releasesBottom'
import { TableIndex } from './components/table'
import { createNewTable } from './utils/createNewTable'
import { organizeTableIsNotNull } from './utils/organizeTableIsNotNull'
import { recriatedTable } from './utils/reecritatedTable'

// import { AlertDuplicateOrder } from './components/modals/alertDuplicateOrder'

type TReturnTable = {
  key_table: number
  table_index: number
}
interface Data {
  table: string[]
  returnTable: TReturnTable[]
}

export interface statusNotes {
  lineSelectedToImport: string[]
  entrySuccess: number
  entryError: number
}

const SelectCorrelationPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [bodyModal, setBodyModal] = useState<ReactJSXElement | undefined>()
  const [sizeModal, setSizeModal] = useState<
    'sm' | 'md' | 'lg' | 'xl' | 'full'
  >('full')

  const [lineSelectedToImported, setLineSelectedToImported] =
    useState<statusNotes>({
      lineSelectedToImport: [],
      entrySuccess: 0,
      entryError: 0,
    })

  const { session } = useSession()
  const { params } = useParams()

  const newParams = replaceTextDescrypt(params!)

  const decryptData: Data = decryptedSimple(newParams)

  let { table, returnTable } = decryptData

  const loadingModal = <LoadingModal />

  const successModal = (
    <SuccessModal lineSelectedToImported={lineSelectedToImported} />
  )

  // const alertDuplicatedOrder = <AlertDuplicateOrder />

  const transitionModal = () => {
    setSizeModal('xl')
    setBodyModal(loadingModal)

    setTimeout(() => {
      setSizeModal('full')
      setBodyModal(successModal)
    }, 2500)
  }

  const key_lines_client = returnTable.map((item) => {
    return item.key_table
  })

  table = organizeTableIsNotNull(table)

  const newTable_old = createNewTable(key_lines_client, table, returnTable)

  const newTable = recriatedTable(
    newTable_old,
    session.company.id,
    session.company.complemento?.hashEmpresa,
    session.company.name,
    session.company.phone,
    session.company.document
  )

  return (
    <>
      <Flex w="100%" h="100%" flexDir="column" fontFamily={'Inter'}>
        <Header />

        <TableIndex
          lineSelectedToImported={lineSelectedToImported}
          table={newTable}
          setLineSelectedToImported={setLineSelectedToImported}
        />

        <Flex w={'100%'} justifyContent="space-between" mt="34px">
          <ReleasesBottom lineSelectedToImported={lineSelectedToImported} />
          <ButtonFooterTable
            lineSelectedToImported={lineSelectedToImported}
            setSelectedLineToImport={setLineSelectedToImported}
            newTable={newTable}
            session={session}
            onOpen={onOpen}
            openModal={transitionModal}
          />
        </Flex>
        <ModalBodyDefault
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          body={bodyModal}
          size={sizeModal}
        />
      </Flex>
    </>
  )
}

export default SelectCorrelationPage
