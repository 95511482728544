import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { ArrowSquareOut } from 'phosphor-react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSession } from 'src/contexts'
import { apiInstance, getAplicativosByCompanyDocument } from 'src/services/api'
import { GetAppIconByTag } from 'src/utilities/GetTagApp'

export interface App {
  aplicativoId: number
  codigo: number
  descricao: string
  indAtivar: string
  indStatus: string
  nome: string
  tag: string
  valor: number
}

export const Integrations = () => {
  const { session, attPermissions } = useSession()

  const toast = useToast()

  const [apps, setApps] = useState<App[]>()

  const appsSimplaz = [
    'SIMPLAZ',
    'SP_P2',
    'SP_P3',
    'SP_P4',
    'SP_CTB_P5',
    'SP_CTB_P6',
    'SP_CTB_P7',
  ]

  const tagsUnique = [
    'UN_L1',
    'UN_L2',
    'UN_L3',
    'UN_L4',
    'UN_N1',
    'UN_N2',
    'UN_N3',
  ]
  const tagsGestor = [
    'GE_L1',
    'GE_L2',
    'GE_L3',
    'GE_L4',
    'GE_N1',
    'GE_N2',
    'GE_N3',
    'GE_N4',
  ]

  const appsClubeContador = [
    'CL_CTD_P1',
    'CL_CTD_P2',
    'CL_CTD_P3',
    'CL_CTD_P4',
    'CL_CTD_P5',
  ]

  const getAppsActives = useCallback((apps) => {
    return apps.filter((app: any) => app.indAtivar === 'S')
  }, [])

  const getAppsDenyUser = useCallback((apps, appsActives) => {
    const appsPush: any[] = []

    for (let app of apps) {
      if (
        !appsActives.includes(app.tag) &&
        app.indAtivar === 'N' &&
        !tagsUnique.includes(app.tag)
      ) {
        if (!appsClubeContador.includes(app.tag)) {
          appsPush.push(app)
        }
      }
    }
    return appsPush.filter((app: any) => {
      return !appsSimplaz.includes(app.tag) && !tagsGestor.includes(app.tag)
    })
  }, [])

  const containsSimplaz = useCallback((apps: any) => {
    return apps.filter((app: any) => {
      if (appsSimplaz.includes(app.tag)) {
        return app
      }
    })
  }, [])

  const containsClubeContador = useCallback((apps: any) => {
    return apps.filter((app: any) => {
      if (appsClubeContador.includes(app.tag)) {
        return app
      }
    })
  }, [])

  const verifyContainsAppUnique = (apps: any) => {
    return apps.filter((app: any) => {
      if (appsClubeContador.includes(app.tag)) {
        return app
      }
    })
  }

  const joinApps = useCallback((appsActives, appsDenyUser) => {
    return [...appsActives, ...appsDenyUser]
  }, [])

  const getApps = useCallback(async () => {
    // const headers = {
    //   GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
    //   Sistema: `${session.origin}`,
    // }
    try {
      const { data } = await getAplicativosByCompanyDocument(
        session.company.document,
        session.origin,
      )

      // 0 -> todos
      // 1 -> gestor
      // 2 -> unique
      const appsActives = getAppsActives(data)

      const isSimplaz = containsSimplaz(appsActives).length > 0 ? true : false
      const isClubeContador =
        containsClubeContador(appsActives).length > 0 ? true : false

      const appsDenyUser = getAppsDenyUser(data, appsActives)
      if (!isSimplaz) {
        appsActives.push({
          aplicativoId: 2,
          codigo: 1,
          nome: 'Simplaz',
          descricao: 'Simplaz',
          indStatus: '1',
          valor: 0.0,
          tag: 'SIMPLAZ',
          indAtivar: 'N',
          indLista: '0',
        })
      }

      if (!isClubeContador && session.origin === 'Unique') {
        appsActives.push({
          aplicativoId: 26,
          codigo: 4100,
          nome: 'Clube do Contador - Iniciante',
          descricao: 'Clube do Contador - Iniciante',
          indStatus: '1',
          valor: 0.0,
          tag: 'CL_CTD_P1',
          indAtivar: 'N',
          indLista: '2',
        })
      }

      const appsExcludesByTag = ['CERTD', 'MOBV_']

      const joinAppsAll = joinApps(appsActives, appsDenyUser).filter((app) => {
        return !appsExcludesByTag.includes(app.tag)
      })

      if (session.origin === 'Unique') {
        verifyContainsAppUnique(joinAppsAll)
      }

      setApps(joinAppsAll)
    } catch (error) {
      console.log(error)
    }
  }, [session.company.document])

  const changeApp = async (app: App) => {
    if (app.codigo === 2) {
      window.open(
        'https://docs.google.com/forms/d/1xDnM107RQfOR5fZSAsjhLTT6tLKrRuWL5oxUmvzEM2k/edit',
      )
      return
    }

    const payload: App = {
      ...app,
      indAtivar: app.indAtivar === 'S' ? 'N' : 'S',
    }

    const headers = {
      GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
      Sistema: `${session.origin}`,
    }

    try {
      const { data } = await apiInstance(9000).post(
        `/workspace/Aplicativo/Empresa/${session.company.document}`,
        [payload],
        {
          headers: headers,
        },
      )

      await attPermissions()

      toast({
        title: (
          <Text fontSize="sm">
            {payload.indAtivar === 'S'
              ? 'Aplicativo ativado com sucesso!'
              : 'Aplicativo desativado'}
          </Text>
        ),
        description: <Text fontSize="sm">{data.message}</Text>,
        position: 'top',
        status: payload.indAtivar === 'S' ? 'success' : 'warning',
        isClosable: true,
      })
      await getApps()
    } catch (error) {
      toast({
        title: <Text fontSize="sm">Falha ao ativar Aplicativo</Text>,
        description: (
          <Text fontSize="sm">
            Entre em contato com o departamento de Atendimento
          </Text>
        ),
        position: 'top',
        status: 'error',
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    getApps()
  }, [getApps])

  const navigate = useNavigate()

  return (
    <Flex overflow={'auto'}>
      <Table>
        <Thead>
          <Tr></Tr>
        </Thead>
        <Tbody>
          {apps?.map((app, index) => (
            <Tr key={index}>
              <Td w="100px">{GetAppIconByTag({ appTag: app.tag })}</Td>
              <Td>
                <Text fontSize="sm" fontWeight="bold">
                  {app.nome}
                </Text>
                <Text fontSize="13px" color="low.medium">
                  {app.descricao}
                </Text>
              </Td>

              <Td textAlign="end">
                <Button
                  as="a"
                  variant="outline"
                  cursor="pointer"
                  size="sm"
                  borderRadius="4px"
                  w="160px"
                  leftIcon={<ArrowSquareOut size={20} />}
                  onClick={() => navigate(`${app.tag.toLocaleLowerCase()}`)}
                >
                  Ver detalhes
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Flex>
  )
}

export const ProDetails = () => {
  return (
    <Flex>
      <P />
    </Flex>
  )
}

const P = () => {
  return (
    <svg
      width="226"
      height="33"
      viewBox="0 0 226 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M221.794 15.7482C221.389 15.7482 221.039 16.0136 220.941 16.4049C220.858 16.7263 220.76 17.0477 220.648 17.3691C220.243 18.4869 219.656 19.493 218.887 20.3734C218.119 21.2537 217.183 21.9523 216.093 22.4694C214.989 22.9864 213.759 23.2379 212.404 23.2379C211.034 23.2379 209.805 22.9864 208.715 22.4694C207.611 21.9523 206.689 21.2537 205.92 20.3734C205.152 19.493 204.565 18.4869 204.159 17.3691C203.754 16.2512 203.559 15.0774 203.559 13.8338C203.559 12.5343 203.754 11.3186 204.159 10.1867C204.565 9.05488 205.152 8.06277 205.92 7.19642C206.689 6.33006 207.625 5.65934 208.715 5.17027C209.106 4.98862 209.525 4.84888 209.958 4.7371C210.35 4.63928 210.615 4.28995 210.615 3.88472V2.683C210.615 2.13804 210.112 1.70487 209.581 1.80268C208.687 1.98433 207.848 2.23585 207.094 2.59916C205.585 3.29783 204.341 4.23405 203.335 5.39385C202.329 6.55364 201.588 7.8392 201.099 9.25051C200.61 10.6618 200.359 12.0592 200.359 13.4425V14.1412C200.359 15.4268 200.596 16.7542 201.085 18.1376C201.56 19.521 202.301 20.8205 203.279 22.0362C204.257 23.2519 205.501 24.23 207.024 24.9846C208.547 25.7391 210.336 26.1025 212.432 26.1025C214.528 26.1025 216.33 25.7252 217.839 24.9846C219.349 24.244 220.606 23.2519 221.584 22.0362C222.562 20.8205 223.289 19.521 223.778 18.1376C223.932 17.6905 224.058 17.2573 224.155 16.8241C224.281 16.2652 223.862 15.7342 223.289 15.7342H221.794V15.7482Z"
        fill="#58595B"
      />
      <path
        d="M222.772 4.23395C221.668 2.96237 220.285 1.91436 218.636 1.1598C217.043 0.419205 215.171 0.0279468 213.047 0H212.823C212.32 0 211.915 0.391259 211.915 0.894302V4.206C211.915 4.69507 212.306 5.08632 212.795 5.1003H213.005C214.151 5.11427 215.185 5.33785 216.065 5.7291C217.015 6.1483 217.826 6.74916 218.482 7.48975C219.153 8.24432 219.67 9.12465 220.033 10.1168C220.383 11.0949 220.564 12.1708 220.578 13.3027V13.5123C220.578 14.0014 220.984 14.3926 221.473 14.3926H225.106C225.595 14.3926 226 13.9874 226 13.4983V12.9813C226 11.4722 225.735 9.94908 225.204 8.42597C224.686 6.91685 223.862 5.50553 222.772 4.23395Z"
        fill="url(#paint0_linear_728_1927)"
      />
      <path
        d="M7.86097 2.73881L0.0498252 24.4256C-0.145803 24.9705 0.259427 25.5295 0.832337 25.5295H2.20173C2.55107 25.5295 2.85848 25.3059 2.98424 24.9845L5.08026 19.1157C5.19204 18.7803 5.51343 18.5707 5.86277 18.5707H15.6721C16.0214 18.5707 16.3289 18.7943 16.4546 19.1157L18.6205 24.9985C18.7463 25.3199 19.0537 25.5434 19.403 25.5434H20.8702C21.4431 25.5434 21.8484 24.9705 21.6527 24.4256L13.604 2.73881C13.4783 2.41742 13.1709 2.19385 12.8215 2.19385H8.67143C8.29414 2.19385 7.97276 2.41742 7.86097 2.73881ZM6.65925 14.6442L9.88711 5.54747C9.9989 5.21211 10.3203 5.00251 10.6696 5.00251C11.019 5.00251 11.3264 5.22608 11.4521 5.54747L14.7918 14.6442C14.9874 15.1891 14.5962 15.762 14.0093 15.762H7.42779C6.86885 15.7481 6.46362 15.1891 6.65925 14.6442Z"
        fill="#58595B"
      />
      <path
        d="M34.3826 7.95093H34.2568C32.8315 7.95093 31.6438 8.25834 30.7075 8.8592C29.7713 9.47403 29.0727 10.3544 28.6115 11.5141C28.6115 11.5141 28.6115 11.5141 28.6115 11.5281C28.5417 11.6818 28.3181 11.6399 28.3181 11.4722V8.62165C28.3181 8.53781 28.2482 8.46794 28.1644 8.46794H26.0404C25.9566 8.46794 25.8867 8.53781 25.8867 8.62165V25.3898C25.8867 25.4736 25.9566 25.5435 26.0404 25.5435H28.8072C28.891 25.5435 28.9609 25.4736 28.9609 25.3898V15.3429C28.9609 13.9595 29.3801 12.8416 30.2045 12.0172C31.0429 11.1788 32.1189 10.7736 33.4743 10.7736C34.7738 10.7736 35.7939 11.1788 36.5484 11.9753C37.289 12.7718 37.6663 13.8337 37.6663 15.1612V25.4037C37.6663 25.4876 37.7362 25.5574 37.82 25.5574H40.5868C40.6706 25.5574 40.7405 25.4876 40.7405 25.4037V15.804C40.7405 13.2608 40.2235 11.3325 39.1894 9.97707C38.1274 8.62165 36.5345 7.95093 34.3826 7.95093Z"
        fill="#58595B"
      />
      <path
        d="M55.8456 8.8871C54.7417 8.4679 53.3024 8.2583 51.5558 8.2583C51.0387 8.2583 50.5078 8.2583 49.9628 8.27228C49.4039 8.28625 48.8729 8.30022 48.3419 8.34214C47.9227 8.37009 47.5174 8.39804 47.1262 8.42598C46.9725 8.43996 46.8607 8.56572 46.8607 8.71943V10.8154C46.8607 10.9831 47.0004 11.1229 47.1821 11.1089C47.5733 11.0809 48.0205 11.053 48.5235 11.039C49.1663 11.025 49.837 10.9971 50.5357 10.9691C51.2344 10.9412 51.9191 10.9412 52.5898 10.9412C53.7636 10.9412 54.6579 11.2346 55.2587 11.8355C55.8735 12.4364 56.167 13.3167 56.167 14.4904V14.6022C56.167 14.7699 56.0273 14.8957 55.8735 14.8957H51.8492C50.382 14.8957 49.1244 15.1053 48.0764 15.5245C47.0284 15.9437 46.2319 16.5585 45.6869 17.369C45.142 18.1794 44.8765 19.1995 44.8765 20.4152C44.8765 21.5889 45.128 22.595 45.631 23.4195C46.1341 24.2579 46.8467 24.8867 47.769 25.3198C48.6912 25.753 49.7951 25.9766 51.0807 25.9766C52.2684 25.9766 53.2885 25.767 54.1408 25.3338C54.9792 24.9006 55.65 24.2858 56.1251 23.4614V23.4474C56.2788 23.1819 56.684 23.2797 56.684 23.5871V25.222C56.684 25.3897 56.8237 25.5155 56.9774 25.5155H58.808C58.9756 25.5155 59.1014 25.3757 59.1014 25.222V14.714C59.1014 13.121 58.8359 11.8495 58.3049 10.9133C57.7739 9.991 56.9635 9.30631 55.8456 8.8871ZM55.4543 21.8125C55.0212 22.4413 54.4622 22.8885 53.8055 23.154C53.1487 23.4195 52.4501 23.5592 51.7234 23.5592C50.4798 23.5592 49.5436 23.2658 48.8729 22.6928C48.2161 22.1199 47.8807 21.3654 47.8807 20.4152C47.8807 19.479 48.2161 18.7104 48.8729 18.1096C49.5296 17.5087 50.4798 17.2153 51.7234 17.2153H55.8735C56.0412 17.2153 56.167 17.355 56.167 17.5087V19.1995C56.1251 20.3174 55.8875 21.1837 55.4543 21.8125Z"
        fill="#58595B"
      />
      <path
        d="M62.5669 2.68292V4.13615C62.5669 4.40165 62.7905 4.62522 63.056 4.62522H64.4393C64.7048 4.62522 64.9284 4.8488 64.9284 5.11429V25.0544C64.9284 25.3199 65.152 25.5434 65.4175 25.5434H67.5135C67.779 25.5434 68.0026 25.3199 68.0026 25.0544V4.62522V2.68292C68.0026 2.41742 67.779 2.19385 67.5135 2.19385H64.9284H63.056C62.7905 2.19385 62.5669 2.41742 62.5669 2.68292Z"
        fill="#58595B"
      />
      <path
        d="M79.9358 22.595C79.7402 22.595 79.5725 22.4692 79.5027 22.2875L74.7657 8.76127C74.6958 8.57962 74.5281 8.45386 74.3325 8.45386H72.1806C71.8592 8.45386 71.6356 8.77525 71.7474 9.06869L77.5184 24.8587C77.5883 25.0403 77.756 25.1521 77.9516 25.1521H78.9018C79.2092 25.1521 79.4328 25.4455 79.335 25.739L79.0974 26.5634C78.8878 27.2621 78.6084 27.835 78.287 28.2542C77.9516 28.6874 77.5464 28.9808 77.0433 29.1625C76.5403 29.3441 75.9255 29.428 75.1989 29.428H73.1867C72.9352 29.428 72.7256 29.6376 72.7256 29.8891V31.7196C72.7256 31.9711 72.9352 32.1807 73.1867 32.1807H75.0032C76.177 32.1807 77.211 32.013 78.0914 31.6917C78.9717 31.3563 79.7262 30.8393 80.3411 30.1266C80.9559 29.414 81.445 28.4917 81.7803 27.3599L87.2998 9.04074C87.3837 8.7473 87.1741 8.45386 86.8667 8.45386H84.8126C84.6169 8.45386 84.4353 8.59359 84.3794 8.77525L80.383 22.2596C80.3271 22.4692 80.1454 22.595 79.9358 22.595Z"
        fill="#58595B"
      />
      <path
        d="M94.6359 3.28369H92.6237C92.3583 3.28369 92.1486 3.4933 92.1486 3.75879V7.99274C92.1486 8.25823 91.939 8.46783 91.6735 8.46783H89.6614C89.3959 8.46783 89.1863 8.67743 89.1863 8.94293V10.3123C89.1863 10.5778 89.3959 10.7874 89.6614 10.7874H91.6735C91.939 10.7874 92.1486 10.997 92.1486 11.2625V19.7444C92.1486 21.2535 92.4002 22.4552 92.9032 23.3495C93.4063 24.2299 94.1189 24.8587 95.0411 25.222C95.9634 25.5853 97.0673 25.7669 98.3528 25.7669H100.505C100.77 25.7669 100.98 25.5573 100.98 25.2918V23.4753C100.98 23.2098 100.77 23.0002 100.505 23.0002H97.9616C97.0393 23.0002 96.3407 22.7487 95.8376 22.2456C95.3346 21.7426 95.0831 21.0299 95.0831 20.1217V11.2485C95.0831 10.983 95.2927 10.7734 95.5582 10.7734H100.505C100.77 10.7734 100.98 10.5638 100.98 10.2983V8.92896C100.98 8.66346 100.77 8.45386 100.505 8.45386H95.5582C95.2927 8.45386 95.0831 8.24426 95.0831 7.97876V3.73084C95.097 3.49329 94.8874 3.28369 94.6359 3.28369Z"
        fill="#58595B"
      />
      <path
        d="M107.03 5.7152C107.715 5.7152 108.218 5.53354 108.553 5.17023C108.889 4.80692 109.042 4.3458 109.042 3.80084C109.042 3.2419 108.875 2.79475 108.553 2.43144C108.218 2.06813 107.715 1.88647 107.03 1.88647C106.346 1.88647 105.842 2.06813 105.507 2.43144C105.172 2.79475 105.018 3.25587 105.018 3.80084C105.018 4.35977 105.186 4.82089 105.507 5.17023C105.842 5.53354 106.346 5.7152 107.03 5.7152Z"
        fill="#58595B"
      />
      <path
        d="M103.537 8.90095V10.438C103.537 10.6895 103.732 10.8852 103.984 10.8852H105.717C105.968 10.8852 106.164 11.0808 106.164 11.3323V25.0961C106.164 25.3477 106.359 25.5433 106.611 25.5433H108.791C109.042 25.5433 109.238 25.3477 109.238 25.0961V10.8991V8.91492C109.238 8.6634 109.042 8.46777 108.791 8.46777H106.164H103.984C103.746 8.46777 103.537 8.6634 103.537 8.90095Z"
        fill="#58595B"
      />
      <path
        d="M126.705 21.4771C126.272 22.05 125.713 22.5111 125.014 22.8464C124.315 23.1818 123.477 23.3635 122.513 23.3635C121.269 23.3635 120.249 23.084 119.425 22.5111C118.6 21.9522 117.985 21.1836 117.58 20.2055C117.175 19.2413 116.965 18.1654 116.965 16.9916C116.965 15.7759 117.161 14.686 117.58 13.7218C117.985 12.7576 118.586 12.0031 119.397 11.4441C120.207 10.8852 121.227 10.6057 122.429 10.6057C123.84 10.6057 124.958 10.969 125.783 11.6817C126.523 12.3245 126.998 13.093 127.194 14.0152C127.236 14.2109 127.403 14.3506 127.613 14.3506H129.723C129.975 14.3506 130.184 14.127 130.142 13.8755C130.003 12.7576 129.625 11.7655 129.024 10.9131C128.326 9.94898 127.417 9.19442 126.272 8.66343C125.126 8.13244 123.84 7.86694 122.415 7.86694C120.99 7.86694 119.732 8.11846 118.67 8.63548C117.608 9.1525 116.714 9.82322 116.001 10.6616C115.289 11.5 114.758 12.4502 114.408 13.4982C114.059 14.5462 113.877 15.6082 113.877 16.6981V17.2711C113.877 18.3191 114.059 19.3671 114.408 20.4011C114.758 21.4491 115.289 22.3993 115.987 23.2657C116.686 24.132 117.58 24.8167 118.656 25.3337C119.732 25.8507 121.004 26.1023 122.485 26.1023C123.938 26.1023 125.238 25.8368 126.397 25.2918C127.557 24.7468 128.493 23.9923 129.206 23.0002C129.835 22.1338 130.212 21.1277 130.338 19.9819C130.366 19.7304 130.17 19.5208 129.919 19.5208H127.795C127.585 19.5208 127.417 19.6745 127.376 19.8701C127.292 20.471 127.054 21.002 126.705 21.4771Z"
        fill="#58595B"
      />
      <path
        d="M145.611 17.1454C144.507 16.3209 143.026 15.8179 141.195 15.6223L139.337 15.4266C138.331 15.3149 137.618 15.0773 137.171 14.686C136.738 14.3088 136.514 13.7917 136.514 13.149C136.514 12.4084 136.822 11.7796 137.436 11.2905C138.051 10.8014 138.96 10.5499 140.161 10.5499C141.349 10.5499 142.257 10.7875 142.858 11.2765C143.333 11.6538 143.627 12.087 143.752 12.59C143.808 12.8416 144.018 13.0092 144.269 13.0092H146.1C146.421 13.0092 146.687 12.7158 146.645 12.3944C146.477 11.011 145.876 9.94906 144.828 9.19449C143.627 8.34211 142.076 7.90894 140.161 7.90894C138.876 7.90894 137.758 8.11854 136.766 8.53774C135.788 8.95694 135.019 9.5578 134.46 10.3263C133.901 11.1089 133.622 12.0311 133.622 13.121C133.622 14.5743 134.139 15.6921 135.159 16.5026C136.179 17.2991 137.506 17.7881 139.127 17.9558L140.986 18.1515C142.173 18.2772 143.04 18.5427 143.571 18.934C144.102 19.3252 144.367 19.8842 144.367 20.5828C144.367 21.3653 144.004 22.0361 143.277 22.581C142.551 23.126 141.503 23.3915 140.147 23.3915C138.638 23.3915 137.562 23.098 136.933 22.5112C136.444 22.05 136.123 21.561 135.997 21.0579C135.927 20.8204 135.718 20.6387 135.466 20.6387H133.636C133.3 20.6387 133.049 20.9322 133.091 21.2536C133.258 22.623 133.873 23.7408 134.949 24.5792C136.179 25.5434 137.912 26.0185 140.147 26.0185C141.531 26.0185 142.76 25.7949 143.822 25.3338C144.884 24.8727 145.723 24.2299 146.338 23.4194C146.952 22.595 147.246 21.6588 147.246 20.5828C147.274 19.1296 146.715 17.9698 145.611 17.1454Z"
        fill="#58595B"
      />
      <path
        d="M171.406 2.97639C170.12 2.36156 168.513 2.06812 166.585 2.06812H162.589H161.946H159.976C159.682 2.06812 159.445 2.30566 159.445 2.5991V25.0264C159.445 25.3199 159.682 25.5574 159.976 25.5574H162.058C162.351 25.5574 162.589 25.3199 162.589 25.0264V17.9559C162.589 17.6625 162.826 17.4249 163.12 17.4249H166.585C168.499 17.4249 170.106 17.1175 171.406 16.5166C172.691 15.9018 173.67 15.0494 174.326 13.9315C174.983 12.8137 175.318 11.5002 175.318 10.019V9.50197C175.318 7.99284 174.983 6.67934 174.326 5.56147C173.67 4.4436 172.691 3.57724 171.406 2.97639ZM171.56 12.2408C171.182 12.9814 170.637 13.5822 169.911 14.0154C169.184 14.4486 168.262 14.6721 167.158 14.6721H163.106C162.812 14.6721 162.575 14.4346 162.575 14.1412V5.33789C162.575 5.04445 162.812 4.80691 163.106 4.80691H167.158C168.262 4.80691 169.184 5.0165 169.911 5.44968C170.637 5.88286 171.182 6.46974 171.56 7.21033C171.937 7.95092 172.118 8.8033 172.118 9.73952C172.118 10.6618 171.937 11.4862 171.56 12.2408Z"
        fill="#58595B"
      />
      <path
        d="M192.045 16.3349C193.316 15.8179 194.308 15.0354 195.021 13.9734C195.734 12.9114 196.097 11.5281 196.097 9.85125V9.33424C196.097 7.64345 195.748 6.27406 195.035 5.21208C194.336 4.1501 193.344 3.35361 192.059 2.85057C190.773 2.33355 189.292 2.08203 187.587 2.08203H183.074H181.886H180.461C180.167 2.08203 179.944 2.31958 179.944 2.59905V25.0404C179.944 25.3338 180.181 25.5574 180.461 25.5574H182.557C182.85 25.5574 183.074 25.3198 183.074 25.0404V17.6344C183.074 17.341 183.311 17.1174 183.591 17.1174H187.573C187.643 17.1174 187.713 17.1174 187.783 17.1174C187.95 17.1035 188.118 17.1873 188.23 17.327L194.21 25.3617C194.308 25.4875 194.462 25.5713 194.63 25.5713H197.131C197.564 25.5713 197.802 25.0823 197.55 24.7329L191.821 17.1593C191.583 16.8519 191.709 16.4607 192.045 16.3349C192.031 16.3349 192.031 16.3349 192.045 16.3349ZM183.06 5.33784C183.06 5.04439 183.297 4.82082 183.577 4.82082H188.146C189.124 4.82082 189.977 5.03042 190.675 5.44962C191.374 5.86882 191.919 6.42776 192.31 7.14041C192.687 7.85305 192.883 8.67748 192.883 9.58575C192.883 10.508 192.687 11.3185 192.31 12.0311C191.933 12.7437 191.388 13.3027 190.675 13.7079C189.977 14.1131 189.124 14.3088 188.146 14.3088H183.577C183.283 14.3088 183.06 14.0712 183.06 13.7918V5.33784Z"
        fill="#58595B"
      />
      <defs>
        <linearGradient
          id="paint0_linear_728_1927"
          x1="211.932"
          y1="7.20471"
          x2="226.014"
          y2="7.20471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#68BAB8" />
          <stop offset="1" stop-color="#10142D" />
        </linearGradient>
      </defs>
    </svg>
  )
}
