import { Flex, Text } from '@chakra-ui/react'

export const Header = () => {
  return (
    <Flex flexDir="column">
      <Text fontFamily={'Sora'} fontWeight={'600'} fontSize={'16px'}>
        Histórico de pagamento
      </Text>
      <Flex flexDir="row">
        <Text fontWeight={'400'}>
          Gerencie seus pagamentos, faça download dos boletos{' '}
        </Text>
        <Text fontWeight={'600'} ml="5px" mr="5px">
          (disponíveis por até 58 dias)
        </Text>
      </Flex>
    </Flex>
  )
}
