import { applyHtmlCodeInEspecialCharacter } from 'src/utilities/applyHtmlCodeInEspecialCharacter'

interface HtmlJsonProp {
  CNPJ_empresa: string
  Razão_Social_empresa: string
  Email_empresa: string
  Produto_de_origem_empresa: string
  Telefone_empresa: string

  nome_indicado: string
  email_indicado: string
  telefone_indicado: string
  produto_indicado: string
}

export const amountHTML = (htmlJson: HtmlJsonProp) => {
  const HTML = `
  <html>
  <head>
  <title>Ploomes Workspace</title>
  <meta charset="utf-8">
  </head>
  <body bgcolor="#FFFFFF" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0"><center>
  <table id="Tabela_01" width="640" height="auto" border="0" cellpadding="0" cellspacing="0" bgcolor="#F7F7F7">
    <tr>
      <td colspan="6">
        <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_01.png" width="640" height="80" alt="Londrisoft" style="display:block;"></td>
    </tr>
    <tr>
      <td colspan="6">
        <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_ploomes.png" width="640" height="190" alt="Novo Cadastro" style="display:block;"></td>
    </tr>
    <tr>
      <td width="640" height="auto" colspan="6">
  
  <table width="640" border="0">
    <tbody>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560" style="text-align:center; line-height:24px; font-size:14px;">
  <font face="Arial, sans-serif" color="#1e57bd" size="+2"><strong>AVISO</strong></font>
      </td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">&nbsp;</td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560" style="text-align:center; line-height:22px; font-size:16px;">
  <font face="Arial, sans-serif" color="#222222">Olá, recebemos um novo lead no <strong>Clube do contador</strong>.<br><br>
  <strong>Informações de quem indicou:</strong>
      </td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">&nbsp;</td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560"><table width="560" border="0" bgcolor="#e5e5e5">
          <tbody>
            <tr>
              <td width="20">&nbsp;</td>
              <td width="520"></td>
              <td width="20">&nbsp;</td>
            </tr>
            <tr>
              <td width="20">&nbsp;</td>
              <td width="520" style="text-align:left; line-height:24px; font-size:14px;"><font face="Arial, sans-serif" color="#222222">
            CNPJ: <b>${htmlJson.CNPJ_empresa}</b>
            <br>
            Razão Social: <b>${htmlJson.Razão_Social_empresa}</b>
           <br>
            Telefone: <b>${htmlJson.Telefone_empresa}</b>
            <br>
           E-mail: <b>${htmlJson.Email_empresa}</b>           
      <br>
      Produto de origem: <b>${htmlJson.Produto_de_origem_empresa}</b>
          </font></td>
          
          
              <td width="20">&nbsp;</td>
            </tr>
            
            <tr>
              <td width="20">&nbsp;</td>
              <td width="520"></td>
              <td width="20">&nbsp;</td>
            </tr>
          </tbody>
        </table></td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">
  
      </td>
        <td width="40">&nbsp;</td>
      </tr>
     
    </tbody>
  </table>
  
  <!-- Inicio do layout de quem indicou -->
  
  
  <table id="Tabela_01" width="640" height="auto" border="0" cellpadding="0" cellspacing="0" bgcolor="#F7F7F7">
    
    
    <tr>
      <td width="640" height="auto" colspan="6">
  
  <table width="640" border="0">
    <tbody>
   
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">&nbsp;</td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560" style="text-align:center; line-height:22px; font-size:16px;">
  <font face="Arial, sans-serif" color="#222222">
  <strong>Informações do indicado:</strong>
      </td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">&nbsp;</td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560"><table width="560" border="0" bgcolor="#e5e5e5">
          <tbody>
            <tr>
              <td width="20">&nbsp;</td>
              <td width="520"></td>
              <td width="20">&nbsp;</td>
            </tr>
            <tr>
              <td width="20">&nbsp;</td>
              <td width="520" style="text-align:left; line-height:24px; font-size:14px;"><font face="Arial, sans-serif" color="#222222">
            Nome: <b>${htmlJson.nome_indicado}</b>
            <br>
           
            Telefone: <b>${htmlJson.telefone_indicado}</b>
            <br>
           E-mail: <b>${htmlJson.email_indicado}</b>           
      <br>
      Produto de interesse: <b>${htmlJson.produto_indicado}</b>
          </font></td>
          
          
              <td width="20">&nbsp;</td>
            </tr>
            
            <tr>
              <td width="20">&nbsp;</td>
              <td width="520"></td>
              <td width="20">&nbsp;</td>
            </tr>
          </tbody>
        </table></td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">
  
      </td>
        <td width="40">&nbsp;</td>
      </tr>
      
       <tr>
        <td width="40">&nbsp;</td>
        <td width="560" style="text-align:center; line-height:20px; font-size:14px;">
  <font face="Arial, sans-serif" color="#222222">
  Caso tenha qualquer dúvida, nossa equipe está à disposição.
  </font>
      </td>
        <td width="40">&nbsp;</td>
      </tr>
    
    </tbody>
  </table>
      </td>
    </tr>
    <tr>
      <td>
        <a href="https://londrisoft.com.br/?utm_source=email_workspace&utm_medium=rodape_email&utm_campaign=workspace" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/londrisoft.png" width="465" height="80" border="0" alt="Londrisoft" style="display:block;"></a></td>
      <td>
        <a href="https://www.facebook.com/londrisoft" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/facebook.png" width="35" height="80" border="0" alt="Facebook" style="display:block;"></a></td>
      <td>
        <a href="https://www.instagram.com/londrisoft/" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/instagram.png" width="35" height="80" border="0" alt="Instagram" style="display:block;"></a></td>
      <td>
        <a href="https://br.linkedin.com/company/londrisoft" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/linkedin.png" width="35" height="80" border="0" alt="LinkedIn" style="display:block;"></a></td>
      <td>
        <a href="https://www.youtube.com/channel/UCoQWBxPXIDBgubbkb066u0Q" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/youtube.png" width="35" height="80" border="0" alt="YouTube" style="display:block;"></a></td>
      <td>
        <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_09.png" width="35" height="80" alt="" style="display:block;"></td>
    </tr>
  </table>
  </center>
  </body>
  </html>`
  // const reader = new FileReader();
  // reader.readAsDataURL(HTML);
  // reader.onload = function () {};
  return applyHtmlCodeInEspecialCharacter(HTML)
}
