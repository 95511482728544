import { Button, ButtonProps, Flex, Icon, Tag, Text } from '@chakra-ui/react'

interface SideMenuButtonProps extends ButtonProps {
  label: string
  icon: React.ElementType
  beta?: boolean
  permissions: string[] | undefined
}

export const SideMenuButton = ({
  label,
  icon,
  beta = false,
  permissions,
  ...props
}: SideMenuButtonProps) => {
  return (
    <Flex alignItems={'center'}>
      <Button
        aria-label="icon"
        height={'48px'}
        minW="240px"
        variant={'ghost'}
        justifyContent={'flex-start'}
        alignItems="center"
        borderRadius="0"
        _hover={{ backgroundColor: '#1155bb17' }}
        _active={{ backgroundColor: '#1155bb17' }}
        _focus={{ backgroundColor: '#1155bb17' }}
        {...props}
      >
        <Icon as={icon} fontSize="22px" />

        <Text
          fontSize="12px"
          ml="10px"
          fontWeight="400"
          color="low.dark"
          marginLeft="10px"
        >
          {label}
        </Text>
        {beta && (
          <Tag
            px="6px"
            ml="auto"
            size="sm"
            fontSize="11px"
            colorScheme="yellow"
            borderRadius="4px"
          >
            Beta
          </Tag>
        )}
      </Button>
    </Flex>
  )
}
