import {
  Box,
  ButtonGroup,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import ClipboardJS from 'clipboard'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'
import { DownloadSimple, Eye, LinkSimple } from 'phosphor-react'
import { useCallback, useEffect, useState } from 'react'

import { ReactComponent as Notfind } from 'src/assets/imgs/not-find.svg'

import { useSession } from 'src/contexts'
import { apiInstance } from 'src/services/api'
import { FileCard } from '../components/FileCard'

export interface File {
  id: number
  document?: string
  email: string
  serie?: string
  name: string
  type: string
  initial_date: string
  expiry_date?: string
  ref?: string
}

interface Company {
  razao: string
  cnpjCpf: string
}

interface ReportsProps {
  step: number
  visualization: 'card' | 'list'
  selectedCompany: Company
  hasGroup: boolean
}

export const Reports = ({
  step,
  visualization,
  selectedCompany,
  hasGroup,
}: ReportsProps) => {
  const { session } = useSession()

  const [isFetching, setIsFetching] = useState(true)
  const [files, setFiles] = useState<File[]>([])
  const toast = useToast()
  const [pdf, setPdf] = useState({ title: '', file: '' })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const getFiles = useCallback(async () => {
    try {
      setIsFetching(true)
      const { data } = await apiInstance(8010).get<any[]>(
        `/Relatorios/${selectedCompany?.cnpjCpf}`,
        {
          headers: {
            companyId: session.company.id.toString(),
            authorization: `bearer ${session.token}`,
          },
        }
      )

      let formatedfiles = data
        .filter((file: { privado: number; login: any }) => {
          if (session.perfilWs === 0 && hasGroup) {
            return file
          }
          return (
            file.privado === 2 ||
            (file.privado === 0 && file.login === session.email)
          )
        })
        .map(
          (file: {
            extensao: any
            nome: string
            datainicio: string | number | Date
            id: any
            cnpj: any
            login: string
            nr_serie: string
            mensagem: any
          }) => {
            let extension = ''
            let msg = ''
            let ref = ''

            const formatMessage = (value: string) => {
              try {
                const separateValue = value
                  .split('/')[6]
                  .replace('.zip', '')
                  .split('_')[2]
                  .split('')

                const year = separateValue.slice(0, 4).join('')
                const month = separateValue.slice(4, 6).join('')

                // const date = new Date(Number(year), Number(month))

                return `XML's referente ao mês ${month}/${year}`
              } catch (err) {
                console.log('Nome:', value)
                return value || 'Relatório'
              }
            }

            switch (file.extensao) {
              case 'link':
                extension = 'rar'
                msg = formatMessage(file.nome)
                ref = file.nome
                break
              case 'application/doc':
                extension = 'doc'
                break
              case 'DOC':
                extension = 'doc'
                break
              case 'application/pdf':
                extension = 'pdf'
                break
              case 'PDF':
                extension = 'pdf'
                break
              case 'text/plain':
                extension = 'txt'
                break
              case 'TXT':
                extension = 'txt'
                break
              case 'application/excel':
                extension = 'xls'
                break
              case 'application/xml':
                extension = 'xml'
                break
              case 'XML':
                extension = 'xml'
                break
              case 'image/jpeg':
                extension = 'jpeg'
                break
              case 'JPEG':
                extension = 'jpeg'
                break
              case 'image/jpg':
                extension = 'jpg'
                break
              case 'JPG':
                extension = 'jpg'
                break
              case 'image/png':
                extension = 'png'
                break
              case 'PNG':
                extension = 'png'
                break
              default:
                extension = 'outros'
                break
            }

            if (file.nome.toLowerCase().includes('.rem')) extension = 'rem'
            if (file.nome.toLowerCase().includes('.crm')) extension = 'crm'

            const initialD = dayjs(new Date(file.datainicio)).format(
              'DD/MM/YYYY HH:mm:ss'
            )
            const expiryD = dayjs(
              dayjs(new Date(file.datainicio)).add(24, 'hours').toDate()
            ).format('DD/MM/YYYY HH:mm:ss')

            return {
              id: file.id,
              document: file.cnpj,
              email: file.login.trim(),
              serie: file.nr_serie.trim(),
              name: extension === 'rar' ? msg : file.mensagem,
              type: extension,
              initial_date: initialD,
              expiry_date: expiryD,
              ref,
            }
          }
        )
        .sort(
          (
            a: { initial_date: string | number | Date },
            b: { initial_date: string | number | Date }
          ) => {
            const da = +new Date(a.initial_date)
            const db = +new Date(b.initial_date)

            return db - da
          }
        )

      await new Promise((resolve) => setTimeout(resolve, 1000))

      setFiles(() => formatedfiles)
    } catch (error) {
      console.log(error)
    } finally {
      setIsFetching(false)
    }
  }, [
    hasGroup,
    selectedCompany?.cnpjCpf,
    session.company.id,
    session.email,
    session.perfilWs,
    session.token,
  ])

  function downloadBase64File(
    contentType: string,
    base64Data: string,
    fileName: string
  ) {
    const linkSource = `data:${contentType};base64,${base64Data}`
    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  const handleFile = async (file: File, action: 'download' | 'view') => {
    try {
      if (file.type === 'rar') {
        window.open(file.ref, '_blank')
        toast({
          title: 'Download iniciado!',
          description:
            'Utilize os 4 primeiros dígitos do CNPJ da empresa como senha de descompactação do arquivo baixado.',
          duration: 5000,
          position: 'top',
          status: 'success',
          isClosable: true,
        })
        return
      }

      const { data } = await apiInstance(8010).get<{
        arquivo: string
        extensao: string
        tarefa: string
        nome: string
        mensagem: string
      }>(`/Relatorios/Content/${file.id}`)

      if (file.type === 'xml') {
        downloadBase64File(data.extensao, data.arquivo, data.nome.trim())
      }

      if (file.type === 'txt') {
        downloadBase64File(data.extensao, data.arquivo, data.nome.trim())
      }

      if (data.tarefa.trim() === 'DOCS') {
        downloadBase64File(data.extensao, data.arquivo, data.nome.trim())
        return
      }

      if (file.type === 'pdf') {
        if (action === 'download') {
          downloadBase64File(data.extensao, data.arquivo, data.mensagem.trim())
          return
        }

        if (action === 'view') {
          if (data.arquivo.length < 999999) {
            setPdf({ file: data.arquivo, title: data.mensagem.trim() })
            onOpen()
            return
          } else {
            toast({
              title: 'Falha ao visualizar o arquivo!',
              description:
                'Arquivo muito grande e não pode ser aberto em modo visualização. Faça o download do arquivo.',
              duration: 3000,
              position: 'top-right',
              status: 'warning',
              isClosable: true,
            })
          }
        }
      }

      if (file.type === 'xls') {
        downloadBase64File(
          'application/vnd.ms-excel',
          data.arquivo,
          data.mensagem.trim()
        )
        return
      }

      if (
        file.name.toLowerCase().includes('rem') ||
        file.name.toLowerCase().includes('crm')
      ) {
        downloadBase64File(data.extensao, data.arquivo, data.nome.trim())
        return
      }
    } catch (error) {
      toast({
        title: 'Falha ao carregar o arquivo.',
        description:
          'Ocorreu um erro ao tentar ler o arquivo, tente novamente ou entre em contato com o departamento de atendimento!',
        duration: 3000,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      })
    }
  }

  const copy = () => {
    const clipboard = new ClipboardJS('.link')
    clipboard.on('success', (e) => {
      toast({
        id: Math.random(),
        title: 'Link copiado com sucesso!',
        status: 'success',
        position: 'top',
        duration: 2000,
      })
      e.clearSelection()
    })
    setTimeout(() => {
      clipboard.destroy()
    }, 100)
  }

  useEffect(() => {
    if (step === 0) getFiles()
  }, [getFiles, step])

  return (
    <>
      <Flex w="full" h="full" flexDir="column">
        {isFetching ? (
          <Center h="full" w="full">
            <Spinner
              size="xl"
              color="primary.pure"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
            />
          </Center>
        ) : !isFetching && files?.length === 0 ? (
          <Center h="full" flexDir="column">
            <Notfind />
            <Text fontSize="20px" fontWeight="bold" color="low.light" mt="18px">
              Nenhum resultado encontrado.
            </Text>
          </Center>
        ) : (
          <>
            {visualization === 'list' && (
              <Box mt="12px" overflowY="auto">
                <Table size="sm">
                  <Thead position="sticky" top="0" bg="high.pure">
                    <Tr h="40px">
                      <Th
                        fontSize="sm"
                        fontWeight="400"
                        color="low.light"
                        borderWidth="0px"
                        w="100px"
                      >
                        Formato
                      </Th>
                      <Th
                        fontSize="sm"
                        fontWeight="400"
                        color="low.light"
                        borderWidth="0px"
                        w="200px"
                      >
                        Gerado em:
                      </Th>
                      <Th
                        fontSize="sm"
                        fontWeight="400"
                        color="low.light"
                        borderWidth="0px"
                        w="200px"
                      >
                        Expira em:
                      </Th>
                      <Th
                        fontSize="sm"
                        fontWeight="400"
                        color="low.light"
                        borderWidth="0px"
                      >
                        Nome do arquivo
                      </Th>
                      <Th
                        fontSize="sm"
                        fontWeight="400"
                        color="low.light"
                        borderWidth="0px"
                        w="110px"
                      />
                    </Tr>
                  </Thead>

                  <Tbody>
                    {files?.map((file) => (
                      <Tr key={file.id}>
                        <Td fontWeight="bold" textTransform="uppercase">
                          .{file.type}
                        </Td>
                        <Td>{file.initial_date}</Td>
                        <Td>{file.expiry_date}</Td>
                        <Td wordBreak="break-all">{file.name}</Td>
                        <Td py="4px">
                          <ButtonGroup size="sm" variant="ghost">
                            <IconButton
                              aria-label="visualization"
                              icon={<Eye size={22} />}
                              position="initial"
                              onClick={() => handleFile(file, 'view')}
                              isDisabled={file.type !== 'pdf'}
                            />
                            <IconButton
                              aria-label="copy-to-clipboard"
                              icon={<LinkSimple size={22} />}
                              position="initial"
                              onClick={copy}
                              className="link"
                              data-clipboard-text={file.ref}
                              isDisabled={file.type !== 'rar'}
                            />

                            <IconButton
                              aria-label="download"
                              icon={<DownloadSimple size={22} />}
                              position="initial"
                              onClick={() => handleFile(file, 'download')}
                              isDisabled={['outros'].some(
                                (item) => item === file.type
                              )}
                            />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
            {visualization === 'card' && (
              <Box overflowY="auto" mt="12px">
                <Grid
                  templateColumns="repeat(auto-fill, minmax(276px, auto))"
                  rowGap="12px"
                  columnGap="24px"
                  padding="6px 10px 10px 6px"
                >
                  {files?.map((file) => (
                    <GridItem key={file.id}>
                      <FileCard
                        file={file}
                        actions={
                          <ButtonGroup size="sm" variant="ghost">
                            <IconButton
                              aria-label="visualization"
                              icon={<Eye size={22} />}
                              position="initial"
                              onClick={() => handleFile(file, 'view')}
                              isDisabled={file.type !== 'pdf'}
                            />
                            <IconButton
                              aria-label="visualization"
                              icon={<LinkSimple size={22} />}
                              position="initial"
                              onClick={copy}
                              className="link"
                              data-clipboard-text={file.ref}
                              isDisabled={file.type !== 'rar'}
                            />

                            <IconButton
                              aria-label="visualization"
                              icon={<DownloadSimple size={22} />}
                              position="initial"
                              isDisabled={['outros'].some(
                                (item) => item === file.type
                              )}
                              onClick={() => handleFile(file, 'download')}
                            />
                          </ButtonGroup>
                        }
                      />
                    </GridItem>
                  ))}
                </Grid>
              </Box>
            )}
          </>
        )}

        {/* document view */}
        <Drawer
          placement="bottom"
          onClose={onClose}
          isOpen={isOpen}
          size="full"
          preserveScrollBarGap
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">{pdf.title}</DrawerHeader>
            <DrawerBody>
              <Box
                height="calc(100vh - 90px)"
                as={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.6 } }}
                exit={{ opacity: 0 }}
              >
                <embed
                  src={`data:application/pdf;base64,${pdf.file}`}
                  height="100%"
                  width="100%"
                />
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  )
}
