import { ReactComponent as Bi } from './icons/bi.svg'
import { ReactComponent as ClubeContador } from './icons/clube-contador.svg'
import { ReactComponent as Gestor } from './icons/gestor.svg'
import { ReactComponent as MercadoLivre } from './icons/ml.svg'
import { ReactComponent as Quod } from './icons/quod.svg'
import { ReactComponent as Simplaz } from './icons/simplaz.svg'
import { ReactComponent as Unique } from './icons/unique.svg'
import { ReactComponent as Importador } from './icons/importador.svg'
import { ReactComponent as Analytics } from './icons/analytics.svg'
import { ReactComponent as CertificadoDigital } from './icons/certificado_digital.svg'

export const tagsAppsAndIcon = [
  {
    tag: [
      'GESTOR',
      'GE_L4',
      'GE_L3',
      'GE_L2',
      'GE_L1',
      'GE_N4',
      'GE_N3',
      'GE_N2',
      'GE_N1',
    ],
    icon: <Gestor />,
  },
  { tag: ['UNIQUE', 'UN_L0', 'UN_L1', 'UN_L2', 'UN_L3'], icon: <Unique /> },
  { tag: ['SIMPLAZ', 'SP_P4', 'SP_P3', 'SP_P2'], icon: <Simplaz /> },
  { tag: ['ANALYTICSBI'], icon: <Bi /> },
  { tag: ['MercadoLivre'], icon: <MercadoLivre /> },
  { tag: ['INTELVENDAS', 'INTELADM'], icon: <Analytics /> },
  { tag: ['QUOD'], icon: <Quod /> },
  {
    tag: ['CL_CTD_P1', 'CL_CTD_P2', 'CL_CTD_P3', 'CL_CTD_P4', 'CL_CTD_P5'],
    icon: <ClubeContador />,
  },
  { tag: ['IMPXLS'], icon: <Importador /> },
  { tag: ['CERTD'], icon: <CertificadoDigital /> },
  { tag: ['MOBV_'], icon: <Importador /> },
]
