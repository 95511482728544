import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { ReactComponent as AnalyticsBILogo } from 'src/assets/analytics-bi-logo.svg'
import ClubeContadorLogo from 'src/assets/clube-contador-logo.svg'
import { ReactComponent as GestorLogo } from 'src/assets/gestor-logo.svg'
import Gestor_Icon from 'src/assets/icone_gestor.svg'
import Unique_Icon from 'src/assets/icone_unique.svg'
import AnalyticsBIIcon from 'src/assets/icons/analytics-bi-icon.svg'
import { ReactComponent as ClubeContadorLogoDetails } from 'src/assets/icons/clube-contador-details.svg'
import ImportadorXlsIcon from 'src/assets/icons/importador-xls-icon.svg'
import AppLogo from 'src/assets/icons/int-logo.svg'
import IntelVendasIcon from 'src/assets/icons/inteligencia-vendas-icon.svg'
import MercadoLivreIcon from 'src/assets/icons/mercado-livre-icon.svg'
import { ReactComponent as Quod_Store } from 'src/assets/icons/quod-details.svg'
import Quod_Stores from 'src/assets/icons/quod-store.svg'
import SimplazIcon from 'src/assets/icons/simplaz-blue-icon.svg'
import { ReactComponent as ImportadorXlsLogo } from 'src/assets/importador-xls-logo.svg'
import { ReactComponent as MercadoLivreLogo } from 'src/assets/mercado-livre-logo.svg'
import Mob_Icon from 'src/assets/mobiledevendas_icone.svg'
import { ReactComponent as SimplazLogo } from 'src/assets/simplaz-logo.svg'
import { ReactComponent as UniqueLogo } from 'src/assets/unique-logo.svg'

export interface GetTagAppProps {
  appTag: string
}
const tagsClubeContador = [
  'CL_CTD_P1',
  'CL_CTD_P2',
  'CL_CTD_P3',
  'CL_CTD_P4',
  'CL_CTD_P5',
]

const tagsGestor = [
  'GE_L1',
  'GE_L2',
  'GE_L3',
  'GE_L4',
  'GE_N1',
  'GE_N2',
  'GE_N3',
  'GE_N4',
]
const tagsUnique = ['UN_L1', 'UN_L2', 'UN_L3']
const tagsSimplaz = [
  'SIMPLAZ',
  'SP_P2',
  'SP_P3',
  'SP_P4',
  'SP_CTB_P5',
  'SP_CTB_P6',
  'SP_CTB_P7',
]

//pega icone no detalhes do produto
export const GetAppLogoByTag = ({ appTag }: GetTagAppProps) => {
  return (
    <Box height={'38px'} width={'260px'} marginY={'5px'}>
      {appTag === '' ? null : appTag.toLowerCase() ===
        'ANALYTICSBI'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <AnalyticsBILogo style={{ height: 'inherit', width: '160px' }} />
        </Flex>
      ) : tagsSimplaz.includes(appTag) ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <SimplazLogo style={{ height: 'inherit', width: '160px' }} />
        </Flex>
      ) : tagsGestor.includes(appTag.toUpperCase()) ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <GestorLogo style={{ height: 'inherit', width: '160px' }} />
        </Flex>
      ) : tagsUnique.includes(appTag) ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <UniqueLogo style={{ height: 'inherit', width: '160px' }} />
        </Flex>
      ) : appTag.toLowerCase() === 'MercadoLivre'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <MercadoLivreLogo style={{ height: 'inherit', width: '160px' }} />
        </Flex>
      ) : appTag.toLowerCase() === 'INTELVENDAS'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <Text
            height={'inherit'}
            width={'260px'}
            fontWeight={'700'}
            marginTop={'5px'}
          >
            Inteligência de Vendas
          </Text>
        </Flex>
      ) : appTag.toLowerCase() === 'INTELADM'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <Text
            height={'inherit'}
            width={'260px'}
            fontWeight={'700'}
            marginTop={'5px'}
          >
            Inteligência Administrativa
          </Text>
        </Flex>
      ) : appTag.toLowerCase() === 'IMPXLS'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <Text
            height={'inherit'}
            width={'260px'}
            fontWeight={'700'}
            marginTop={'5px'}
          >
            <ImportadorXlsLogo style={{ height: 'inherit', width: '250px' }} />
          </Text>
        </Flex>
      ) : appTag.toLowerCase() === 'QUOD'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <Text
            height={'inherit'}
            width={'260px'}
            fontWeight={'700'}
            marginTop={'5px'}
          >
            <Quod_Store style={{ height: 'inherit', width: '250px' }} />
          </Text>
        </Flex>
      ) : tagsClubeContador.includes(appTag) ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'flex-start'}
        >
          <ClubeContadorLogoDetails
            style={{ height: 'inherit', width: '250px' }}
          />
        </Flex>
      ) : null}
    </Box>
  )
}

//pega icone da loja de apps
export const GetAppIconByTag = ({ appTag }: GetTagAppProps) => {
  return (
    <Box height={'50px'} width={'50px'}>
      {appTag === '' ? null : appTag.toLowerCase() ===
        'ANALYTICSBI'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={AnalyticsBIIcon} />
      ) : tagsSimplaz.includes(appTag.toLocaleUpperCase()) ? (
        <Image height="inherit" width="inherit" src={SimplazIcon} />
      ) : appTag.toLowerCase() === 'GESTOR'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={AppLogo} />
      ) : appTag.toLowerCase() === 'MercadoLivre'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={MercadoLivreIcon} />
      ) : appTag.toLowerCase() === 'INTELVENDAS'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={IntelVendasIcon} />
      ) : appTag.toLowerCase() === 'INTELADM'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={IntelVendasIcon} />
      ) : appTag.toLowerCase() === 'IMPXLS'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={ImportadorXlsIcon} />
      ) : appTag.toLowerCase() === 'QUOD'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={Quod_Stores} />
      ) : tagsClubeContador.includes(appTag) ? (
        <Image height="inherit" width="inherit" src={ClubeContadorLogo} />
      ) : tagsGestor.includes(appTag) ? (
        <Image height="inherit" width="inherit" src={Gestor_Icon} />
      ) : tagsUnique.includes(appTag) ? (
        <Image height="inherit" width="inherit" src={Unique_Icon} />
      ) : appTag.toLocaleLowerCase() === 'MOBV_'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={Mob_Icon} />
      ) : null}
    </Box>
  )
}
