import { Flex } from '@chakra-ui/react'

interface FooterProps {
  selectedApp: string
  apps: any[]
}
export const Footer = ({ apps, selectedApp }: FooterProps) => {
  return (
    <Flex
      w="100%"
      h="8px"
      bg="#fff"
      flexDir="row"
      borderBottomRightRadius={'10px'}
      bottom={'0'}
    >
      {apps.map((app: any) => (
        <Flex
          key={app.tag}
          border={`1px solid ${
            app.tag === selectedApp ? '#1155bb' : 'rgba(203, 200, 200, 1)'
          }`}
          bg={app.tag === selectedApp ? '#1155bb' : '#fff'}
          w={`${100 / apps.length}%`}
          h="8px"
        ></Flex>
      ))}
    </Flex>
  )
}
