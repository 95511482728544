import {
  Button,
  Checkbox,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { Question } from 'phosphor-react'
import { useMemo, useState } from 'react'
import { statusNotes } from '../..'
import { ITable } from './types'
import { formattingText } from './utils/formattingText'
import { clearCurrency } from './utils/isCurrencyValidator'
import { validationLine } from './utils/validationLine'
import { verifyAddress } from './utils/verifyAddress'

interface TableIndexProps {
  lineSelectedToImported: statusNotes
  table: ITable[]
  setLineSelectedToImported: (value: statusNotes) => void
}

export const TableIndex = ({
  lineSelectedToImported,
  table,
  setLineSelectedToImported,
}: TableIndexProps) => {
  const [textError, setTextError] = useState('')

  const headerTable = [
    'Código do pedido',
    'SKU',
    'Dados do cliente',
    'Dados do endereço',
    'Observação do pedido',
    'Dados do pagamento ',
  ]

  const handleCheckbox = (referenceId: string) => {
    if (lineSelectedToImported.lineSelectedToImport.includes(referenceId)) {
      const list = lineSelectedToImported.lineSelectedToImport.filter(
        (item) => item !== referenceId
      )
      setLineSelectedToImported({
        ...lineSelectedToImported,
        lineSelectedToImport: list,
      })
    } else {
      setLineSelectedToImported({
        ...lineSelectedToImported,
        lineSelectedToImport: [
          ...lineSelectedToImported.lineSelectedToImport,
          referenceId,
        ],
      })
    }
  }

  const lineValidationErrors = validationLine(table).filter((item) =>
    Array.isArray(item)
  )

  const listLineErrorsNumber: string[] = []
  const listLineCorrectNumber: string[] = []

  const getTextErrors = (referenceId: number) => {
    let textError = ''
    for (let i = 0; i < lineValidationErrors.length; i++) {
      const lineItem: any = lineValidationErrors[i]
      for (const lineItemEntry of lineItem) {
        if (lineItemEntry.referenceId === referenceId.toString()) {
          textError = lineItemEntry.message
        }
      }
    }

    setTextError(textError)
  }

  for (let i = 0; i < lineValidationErrors.length; i++) {
    const lineItem: any = lineValidationErrors[i]
    for (const lineItemEntry of lineItem) {
      listLineErrorsNumber.push(lineItemEntry.referenceId)
    }
  }

  for (const line of table) {
    if (!listLineErrorsNumber.includes(line.referenceId)) {
      listLineCorrectNumber.push(line.referenceId)
    }
  }

  useMemo(() => {
    setLineSelectedToImported({
      ...lineSelectedToImported,
      entryError: lineValidationErrors.length,
      entrySuccess: table.length,
    })
  }, [])

  return (
    <Flex
      display="flex"
      flexDir={'column'}
      mt="22px"
      h="396px"
      maxH={{ xl: '296px', '2xl': '450px' }}
      borderRadius="12px"
      boxShadow={
        '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)'
      }
    >
      <Flex
        bg={'#1155bb'}
        w="100%"
        h="57px"
        alignItems={'center'}
        borderTopLeftRadius="12px"
        borderTopRightRadius="12px"
      >
        <Flex w={'232px'} alignItems="center" h="50px">
          <Checkbox
            ml={'22px'}
            size="lg"
            borderRadius="4px"
            bg="#fff"
            w="20px"
            h="20px"
            isChecked={
              lineSelectedToImported.lineSelectedToImport.length ===
              listLineCorrectNumber.length
            }
            onChange={() => {
              if (
                lineSelectedToImported.lineSelectedToImport.length ===
                listLineCorrectNumber.length
              ) {
                setLineSelectedToImported({
                  ...lineSelectedToImported,
                  lineSelectedToImport: [],
                })
              } else {
                setLineSelectedToImported({
                  ...lineSelectedToImported,
                  lineSelectedToImport: listLineCorrectNumber,
                })
              }
            }}
          />
          <Text
            color={'#fff'}
            fontSize={'16px'}
            fontWeight={'600'}
            alignSelf="center"
            ml={'22px'}
          >
            Selecionar todos
          </Text>
        </Flex>
      </Flex>
      <Flex w="100%" h="450px">
        <TableContainer
          w={'1417px'}
          h=""
          maxH={{ xl: '240px', '2xl': '445px' }}
          overflowY={'auto'}
          css={{
            '&::-webkit-scrollbar': {
              background: '#ddd',
              width: '6px',
              height: '6px',
            },
            '&::-webkit-scrollbar-track': {
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#A1A1AA',
              borderRadius: '24px',
            },
            thead: {
              position: 'sticky',
              top: 0,
              zIndex: 2,
            },
          }}
        >
          <Table size="sm" colorScheme={'blackAlpha'}>
            <Thead>
              <Tr fontFamily={'Inter'} border="1px solid #F0F0F0" h="44px">
                <Th bg="#F1F0F5"></Th>
                {headerTable.map((item: string, index: number) => (
                  <Th
                    key={index}
                    textTransform="capitalize"
                    fontWeight={'500'}
                    fontSize="14px"
                    color={'#475467'}
                    bg="#F1F0F5"
                  >
                    {item}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {table.map((item: any, index: number) => (
                <Tr
                  key={index}
                  cursor={
                    listLineErrorsNumber.includes(item.referenceId)
                      ? 'not-allowed'
                      : 'pointer'
                  }
                  color="#A1A1AA"
                  border="1px solid #F0F0F0"
                  isDisabled={listLineErrorsNumber.includes(item.referenceId)}
                  bg={
                    listLineErrorsNumber.includes(item.referenceId)
                      ? '#FFEBEB'
                      : lineSelectedToImported.lineSelectedToImport.includes(
                          item.referenceId
                        )
                      ? '#F9FAFB'
                      : '#fff'
                  }
                >
                  <Td>
                    {listLineErrorsNumber.includes(item.referenceId) ? (
                      <Popover trigger="hover" placement="auto-start">
                        <PopoverTrigger>
                          <Question
                            size={28}
                            color="#ff0000"
                            weight="light"
                            onMouseOver={() =>
                              getTextErrors(parseInt(item.referenceId))
                            }
                          />
                        </PopoverTrigger>
                        <PopoverContent w="full" bg="#101828">
                          <PopoverArrow bg="#101828" />

                          <PopoverBody
                            fontFamily={'Inter'}
                            fontSize={'14px'}
                            color="#FFFFFF"
                          >
                            <Text fontWeight="600">
                              {/* Campo {textError} inválido */}
                              Há erro(s) nesse pedido
                            </Text>

                            <Text fontWeight="300" mt="8px">
                              {/* Revise e carregue novamente.{' '}
                              {textError === 'CPF/CNPJ' ||
                                (textError === 'Quantidade' &&
                                  'O campo deve conter apenas números.')}
                              {} */}
                              Confira os campos obrigatórios (produto,
                              quantidade, valor unitário, nome do cliente,
                              CPF/CNPJ) e carregue novamente.
                            </Text>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    ) : (
                      <Checkbox
                        ml={'2%'}
                        isChecked={lineSelectedToImported.lineSelectedToImport.includes(
                          item.referenceId
                        )}
                        onChange={() => handleCheckbox(item.referenceId)}
                        size="lg"
                        borderRadius="4px"
                        w="20px"
                        h="20px"
                      />
                    )}
                  </Td>

                  <Td
                    textTransform="capitalize"
                    fontWeight={'400'}
                    fontSize="14px"
                    color={'#101828'}
                    isDisabled={listLineErrorsNumber.includes(item.referenceId)}
                    onClick={() =>
                      listLineErrorsNumber.includes(item.referenceId)
                        ? ''
                        : handleCheckbox(item.referenceId)
                    }
                  >
                    {item.referenceId}
                  </Td>

                  <Td
                    textTransform="capitalize"
                    fontWeight={'500'}
                    fontSize="14px"
                    color={'#867DBD'}
                    onClick={() =>
                      listLineErrorsNumber.includes(item.referenceId)
                        ? ''
                        : handleCheckbox(item.referenceId)
                    }
                  >
                    <Popover trigger="hover">
                      <PopoverTrigger>
                        <Button
                          bg="#ECE9FF"
                          onClick={() =>
                            listLineErrorsNumber.includes(item.referenceId)
                              ? ''
                              : handleCheckbox(item.referenceId)
                          }
                        >
                          {item.orderProduct.length}{' '}
                          {item.orderProduct.length > 1
                            ? 'produtos'
                            : 'produto'}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent bg={'#FFF'} w="600px">
                        <PopoverArrow />
                        <PopoverBody>
                          <TableContainer overflow={'hidden'}>
                            <Table
                              size="sm"
                              w="100%"
                              border="1px solid #667085"
                            >
                              <Thead>
                                <Tr
                                  bg={'#ECE9FF'}
                                  color="#344054"
                                  border="1px solid #667085"
                                  fontFamily={'Inter'}
                                  fontSize="12px"
                                >
                                  <Th border="1px solid #667085">SKU</Th>
                                  <Th border="1px solid #667085">Nome</Th>
                                  <Th border="1px solid #667085">preço</Th>
                                  <Th border="1px solid #667085">quant.</Th>
                                  <Th border="1px solid #667085">
                                    Valor total
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {item.orderProduct.map(
                                  (product: any, index: number) => {
                                    return (
                                      <Tr color="#667085" key={index}>
                                        <Td
                                          border="1px solid #667085"
                                          textTransform={'capitalize'}
                                          maxW={'100px'}
                                          textOverflow={'ellipsis'}
                                          whiteSpace={'nowrap'}
                                          overflow={'hidden'}
                                        >
                                          <Tooltip label={product.sku}>
                                            {product.sku}
                                          </Tooltip>
                                        </Td>
                                        <Td
                                          border="1px solid #667085"
                                          maxW={'150px'}
                                          textOverflow={'ellipsis'}
                                          whiteSpace={'nowrap'}
                                          overflow={'hidden'}
                                        >
                                          <Tooltip
                                            label={formattingText(product.name)}
                                          >
                                            {formattingText(product.name)}
                                          </Tooltip>
                                        </Td>
                                        <Td border="1px solid #667085">
                                          {product.unitaryValue.toLocaleString(
                                            'pt-BR',
                                            {
                                              currency: 'BRL',
                                              style: 'currency',
                                            }
                                          )}
                                        </Td>
                                        <Td
                                          border="1px solid #667085"
                                          textTransform={'capitalize'}
                                        >
                                          {product.quantity}
                                        </Td>
                                        <Td
                                          border="1px solid #667085"
                                          textTransform={'capitalize'}
                                        >
                                          {clearCurrency(
                                            product.amount.toString()
                                          ).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL',
                                          })}
                                        </Td>
                                      </Tr>
                                    )
                                  }
                                )}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Td>

                  <Td
                    fontWeight={'500'}
                    fontSize="14px"
                    color={'#5E7FAF'}
                    onClick={() =>
                      listLineErrorsNumber.includes(item.referenceId)
                        ? ''
                        : handleCheckbox(item.referenceId)
                    }
                  >
                    <Popover trigger="hover">
                      <PopoverTrigger>
                        <Button bg="#DFECFF">
                          {formattingText(item.client.name)}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent bg={'#FFF'} w="450px">
                        <PopoverArrow />
                        <PopoverBody>
                          <Flex flexDir={'column'}>
                            <Flex>
                              <Text
                                fontFamily={'Inter'}
                                fontWeight={'600'}
                                color="#344054"
                                textTransform={'uppercase'}
                              >
                                {item.client.name}
                              </Text>
                            </Flex>
                            <Flex mt="10px" color="#667085" fontSize={'14px'}>
                              <Text fontWeight={'700'}>CPF/CNPJ:</Text>
                              <Text ml="5px">{item.client.cpfCnpj}</Text>
                            </Flex>
                            <Flex mt="10px" color="#667085" fontSize={'14px'}>
                              <Text fontWeight={'700'}>RG/IE:</Text>
                              <Text ml="5px">{item.client.rgIE}</Text>
                            </Flex>
                            <Flex mt="10px" color="#667085" fontSize={'14px'}>
                              <Text fontWeight={'700'}>
                                Celular do cliente:
                              </Text>
                              <Text ml="5px">{item.client.cellPhone}</Text>
                            </Flex>
                            <Flex mt="10px" color="#667085" fontSize={'14px'}>
                              <Text fontWeight={'700'}>
                                Telefone do cliente:
                              </Text>
                              <Text ml="5px">{item.client.phone}</Text>
                            </Flex>
                            <Flex mt="10px" color="#667085" fontSize={'14px'}>
                              <Text fontWeight={'700'}>E-mail do cliente:</Text>
                              <Text ml="5px">{item.client.email}</Text>
                            </Flex>
                          </Flex>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Td>

                  <Td
                    fontWeight={'500'}
                    fontSize="14px"
                    color={'#5E7FAF'}
                    onClick={() =>
                      listLineErrorsNumber.includes(item.referenceId)
                        ? ''
                        : handleCheckbox(item.referenceId)
                    }
                  >
                    <Popover trigger="hover">
                      <PopoverTrigger>
                        <Button
                          bg={'#DFECFF'}
                          disabled={verifyAddress(
                            item.client.clientCustomer[0]
                          )}
                        >
                          {!verifyAddress(item.client.clientCustomer[0])
                            ? item.client.clientCustomer[0].city
                            : 'Endereço não cadastrado'}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent bg={'#FFF'}>
                        <PopoverArrow />
                        <PopoverBody>
                          <Flex flexDir={'column'}>
                            <Flex>
                              <Text
                                fontFamily={'Inter'}
                                fontWeight={'600'}
                                color="#344054"
                                textTransform={'uppercase'}
                              >
                                {item.client.clientCustomer[0].city}
                              </Text>
                            </Flex>
                            <Flex mt="10px" color="#667085" fontSize={'14px'}>
                              <Text fontWeight={'700'}>
                                Endereço do cliente:
                              </Text>
                              <Text ml="5px">
                                {' '}
                                {item.client.clientCustomer[0].address}
                              </Text>
                            </Flex>
                            <Flex mt="10px" color="#667085" fontSize={'14px'}>
                              <Text fontWeight={'700'}>Número:</Text>
                              <Text ml="5px">
                                {' '}
                                {item.client.clientCustomer[0].number}
                              </Text>
                            </Flex>
                            <Flex mt="10px" color="#667085" fontSize={'14px'}>
                              <Text fontWeight={'700'}>Estado:</Text>
                              <Text ml="5px">
                                {' '}
                                {item.client.clientCustomer[0].state}
                              </Text>
                            </Flex>
                            <Flex mt="10px" color="#667085" fontSize={'14px'}>
                              <Text fontWeight={'700'}>CEP:</Text>
                              <Text ml="5px">
                                {' '}
                                {item.client.clientCustomer[0].zipCode}
                              </Text>
                            </Flex>
                            <Flex mt="10px" color="#667085" fontSize={'14px'}>
                              <Text fontWeight={'700'}>
                                Complemento do endereço:
                              </Text>
                              <Text ml="5px">
                                {' '}
                                {item.client.clientCustomer[0].complement}
                              </Text>
                            </Flex>
                            <Flex mt="10px" color="#667085" fontSize={'14px'}>
                              <Text fontWeight={'700'}>
                                Observação do endereço:
                              </Text>
                              <Text ml="5px">
                                {' '}
                                {item.client.clientCustomer[0].observation}
                              </Text>
                            </Flex>
                          </Flex>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Td>

                  <Td
                    fontWeight={'500'}
                    fontSize="14px"
                    color={'#5E7FAF'}
                    onClick={() =>
                      listLineErrorsNumber.includes(item.referenceId)
                        ? ''
                        : handleCheckbox(item.referenceId)
                    }
                  >
                    <Popover trigger="hover">
                      <PopoverTrigger>
                        <Button
                          disabled={
                            item.observation.split(' ').lenght >= 1
                              ? false
                              : true
                          }
                          bg="#FFE7C2"
                          color="#D29539"
                        >
                          {item.observation.split(' ').lenght >= 1
                            ? item.observation.split(' ').lenght
                            : 0}{' '}
                          Observação
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent bg={'#FFF'}>
                        <PopoverArrow />
                        <PopoverBody>
                          <Flex flexDir={'column'}>
                            <Flex>
                              <Text
                                fontFamily={'Inter'}
                                fontWeight={'600'}
                                color="#344054"
                                textTransform={'uppercase'}
                              >
                                {item.client.clientCustomer[0].city}
                              </Text>
                            </Flex>
                            <Flex mt="10px" color="#667085" fontSize={'14px'}>
                              <Text fontWeight={'700'}>
                                Endereço do cliente:
                              </Text>
                              <Text ml="5px">
                                {' '}
                                {item.client.clientCustomer[0].address}
                              </Text>
                            </Flex>
                            <Flex mt="10px" color="#667085" fontSize={'14px'}>
                              <Text fontWeight={'700'}>Número:</Text>
                              <Text ml="5px">
                                {' '}
                                {item.client.clientCustomer[0].number}
                              </Text>
                            </Flex>
                          </Flex>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Td>

                  <Td
                    textTransform="capitalize"
                    fontWeight={'500'}
                    fontSize="14px"
                    color={'#58595B'}
                    onClick={() =>
                      listLineErrorsNumber.includes(item.referenceId)
                        ? ''
                        : handleCheckbox(item.referenceId)
                    }
                  >
                    {item.orderPaymentTerm[0].amount.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Flex>
  )
}
