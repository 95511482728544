import {
  Button,
  Flex,
  Image,
  Radio,
  RadioGroup,
  Table,
  TableContainer,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { replaceTextEncrypt } from 'src/screens/Importador/utils/replaceTextIcorrects'
import { decryptedSimple, encryptedSimple } from 'src/utilities/hashing'
import CorrelationSVG from '../../../../../assets/icons/correlationicon.svg'
import Step2SVG from '../../../../../assets/icons/step2.svg'
import { returnTablePlanilhaPadrao } from './returnTablePlanilhaPadrao'

let dataFile: any
let table: any[] = []

const SelectHeaderTable = () => {
  const { data } = useParams()

  const newData = data!.replaceAll(
    '98fds49f8d5ds98f5ds98f54ds984d9841fvcx9581ff1',
    '/'
  )

  const decryptData = decryptedSimple(newData!) as string[]
  const navigate = useNavigate()

  const [value, setValue] = useState<number>()

  const [, setGetValue] = useState<[]>([])

  function handleGetValue(id: any, index: number) {
    setGetValue(id)
    dataFile = id
    const updateData = decryptData.filter((item: any) => {
      return item !== id
    })
    table = updateData.slice(index)
  }

  useEffect(() => {
    if (decryptData[0][0] === 'Planilha Padrão') {
      navigate(
        `/importa-facil/selectpage/${replaceTextEncrypt(
          encryptedSimple({
            table: decryptData.slice(2),
            returnTable: returnTablePlanilhaPadrao(),
          })
        )}`
      )
    }
  }, [])

  return (
    <>
      <Flex
        w="100%"
        h="100%"
        flexDir="column"
        fontFamily={'Inter'}
        overflow={'auto'}
        css={{
          '&::-webkit-scrollbar': {
            background: '#ddd',
            width: '4px',
            height: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#A1A1AA',
            borderRadius: '24px',
          },
          thead: {
            position: 'sticky',
            top: 0,
            zIndex: 2,
          },
        }}
      >
        <Flex justifyContent="space-between">
          <Flex alignItems="center">
            <Image src={CorrelationSVG} alt="correlation" mb="9.2%" />
            <Flex display="grid" ml="27px">
              <Text fontWeight="600" fontSize="22px" color="#333333" mb="2%">
                Selecione a linha cabeçalho da tabela de pedidos <br />
                que você carregou
              </Text>
              <Text fontWeight="400" fontSize="16px" color="#333">
                A correlação será feita a partir dessa linha.
              </Text>
            </Flex>
          </Flex>

          <Flex alignItems="flex-end" flexDir="column">
            <Image src={Step2SVG} alt="step2" />
            <Link
              to={`/importa-facil/correlationSaved/${replaceTextEncrypt(
                encryptedSimple({
                  head: dataFile,
                  table: table,
                })
              )}`}
            >
              <Button
                color="#ffffff"
                bg="#1155BB"
                w="217px"
                h="52px"
                borderRadius="4px"
                fontSize="16px"
                mt={'100px'}
                fontWeight="700"
                _hover={{ bg: '#006AFF' }}
                disabled={typeof value === 'undefined'}
              >
                Usar correlação salva
              </Button>
            </Link>
          </Flex>
        </Flex>

        <Flex
          w="100%"
          h="471px"
          bg="#F1F6FF"
          border="2px solid #E1E0E7"
          borderRadius="6px"
          mt={'22px'}
          overflowY={'auto'}
          css={{
            '&::-webkit-scrollbar': {
              background: '#ddd',
              width: '4px',
              height: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '2px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#A1A1AA',
              borderRadius: '24px',
            },
            thead: {
              position: 'sticky',
              top: 0,
              zIndex: 2,
            },
          }}
        >
          <Flex flexDir={'column'} padding={'50px'} w="100%">
            <Flex bg={'#1155bb'} w="100%">
              <Text
                color={'#fff'}
                w="100%"
                h="60px"
                fontSize={'16px'}
                fontFamily="Inter"
                fontWeight="700"
                p="17px"
              >
                Visualização da linha
              </Text>
            </Flex>
            <Flex>
              <TableContainer
                w={'100%'}
                mb="30px"
                overflow="auto"
                css={{
                  '&::-webkit-scrollbar': {
                    background: '#ddd',
                    width: '6px',
                    height: '6px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '2px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#A1A1AA',
                    borderRadius: '24px',
                  },
                  thead: {
                    position: 'sticky',
                    top: 0,
                    zIndex: 2,
                  },
                }}
              >
                <Table>
                  <Thead bg={'#FFFFFF'}>
                    <Flex>
                      <RadioGroup
                        // defaultValue="0"
                        onChange={(e) => setValue(parseInt(e))}
                        value={value?.toString()}
                      >
                        {decryptData
                          .slice(0, 5)
                          .map((outerItem: any, index) => (
                            <Tr
                              bg={value === index ? '#F1F0F5' : '#fff'}
                              h={'60px'}
                              onClick={() => handleGetValue(outerItem, index)}
                            >
                              <Th>
                                <Radio
                                  size="lg"
                                  colorScheme={'facebook'}
                                  key={index}
                                  value={index.toString()}
                                />
                              </Th>

                              {outerItem.map((items: any) => (
                                <>
                                  <Th
                                    fontSize={'14px'}
                                    fontWeight="500"
                                    color={'#a1a1aa'}
                                    textTransform="capitalize"
                                  >
                                    <Text mt="3.2%">{items}</Text>
                                  </Th>
                                </>
                              ))}
                            </Tr>
                          ))}
                      </RadioGroup>
                    </Flex>
                  </Thead>
                </Table>
              </TableContainer>
            </Flex>
          </Flex>
        </Flex>

        <Flex w={'100%'} justifyContent="flex-end" mt="34px">
          <Link to={'/importa-facil'}>
            <Button
              w="170px"
              h={'52px'}
              bg="transparent"
              border="1px solid #1155BB"
              color="#1155BB"
              borderRadius={'4px'}
              fontSize="16px"
              fontWeight={'700'}
              _hover={{ bg: 'transparent' }}
            >
              Cancelar
            </Button>
          </Link>
          <Link
            to={`/importa-facil/correlation/${replaceTextEncrypt(
              encryptedSimple({
                head: dataFile,
                table: table,
                valueCorrelation: '',
              })
            )}`}
          >
            <Button
              w="170px"
              h={'52px'}
              bg="#1155BB"
              _hover={{ bg: '#006AFF' }}
              color="#fff"
              borderRadius={'4px'}
              fontSize="16px"
              fontWeight={'700'}
              ml="20px"
              disabled={typeof value === 'undefined'}
            >
              Continuar
            </Button>
          </Link>
        </Flex>
      </Flex>
    </>
  )
}

export default SelectHeaderTable
