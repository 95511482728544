export const replaceTextEncrypt = (text: string): string => {
  return text
    .replaceAll('+', '8754238y5823y523h52h38ffhgf72h3u')
    .replaceAll('/', '98fds49f8d5ds98f5ds98f54ds984d9841fvcx9581ff1')
}

export const replaceTextDescrypt = (text: string): string => {
  return text
    .replaceAll('8754238y5823y523h52h38ffhgf72h3u', '+')
    .replaceAll('98fds49f8d5ds98f5ds98f54ds984d9841fvcx9581ff1', '/')
}
