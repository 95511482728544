import { Flex, Image } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

export interface GetTagAppProps {
  appTag: string
}

const ASSETS_URL = process.env.REACT_APP_ASSETS_URL

interface GetAppLogoByTagProps {
  nameApp: string
  description: string
  logo: string
}
const tagsClubeContador = [
  'CL_CTD_P1',
  'CL_CTD_P2',
  'CL_CTD_P3',
  'CL_CTD_P4',
  'CL_CTD_P5',
]
export const GetAppLogoByTag = ({ appTag }: GetTagAppProps) => {
  const [appLogo, setAppLogo] = useState<GetAppLogoByTagProps>()
  const appsTagAndLogo = [
    {
      tag: 'SIMPLAZ',
      logo: `${ASSETS_URL}/workspace/clubecontador/simplaz_logo.svg`,
      nameApp: 'Simplaz',
      description: `${ASSETS_URL}/workspace/clubecontador/simplaz_descricao.svg`,
    },
    {
      tag: 'MercadoLivre',
      logo: `${ASSETS_URL}/workspace/clubecontador/mercadolivre_logo.svg`,
      nameApp: 'Mercado Livre',
      description: `${ASSETS_URL}/workspace/clubecontador/mercado_livre_descricao.svg`,
    },
    {
      tag: 'ANALYTICSBI',
      logo: `${ASSETS_URL}/workspace/clubecontador/analytics_bi_logo.svg`,
      nameApp: 'Analytics BI',
      description: `${ASSETS_URL}/workspace/clubecontador/analytics_bi_descricao.svg`,
    },
    {
      tag: 'IMPXLS',
      logo: `${ASSETS_URL}/workspace/clubecontador/importador_xls_logo.svg`,
      nameApp: 'Importa Fácil',
      description: `${ASSETS_URL}/workspace/clubecontador/importador_xls_descricao.svg`,
    },
    {
      tag: 'INTELVENDAS',
      logo: `${ASSETS_URL}/workspace/clubecontador/analyticspro_logo_gestor.svg`,
      nameApp: 'Intel Vendas',
      description: `${ASSETS_URL}/workspace/clubecontador/analyticspro_descricao_gestor.svg`,
    },
    {
      tag: 'INTELADM',
      logo: `${ASSETS_URL}/workspace/clubecontador/analyticspro_logo_unique.svg`,
      nameApp: 'Intel Adm',
      description: `${ASSETS_URL}/workspace/clubecontador/analyticspro_descricao_unique.svg`,
    },
    {
      tag: 'Gestor',
      logo: `${ASSETS_URL}/workspace/clubecontador/gestor_logo.svg`,
      nameApp: 'Gestor',
      description: `${ASSETS_URL}/workspace/clubecontador/gestor_descricao.svg`,
    },
    {
      tag: 'Unique',
      logo: `${ASSETS_URL}/workspace/clubecontador/unique_logo.svg`,
      nameApp: 'Unique',
      description: `${ASSETS_URL}/workspace/clubecontador/unique_descricao.svg`,
    },
    {
      tag: 'QUOD',
      logo: `${ASSETS_URL}/workspace/clubecontador/quod_logo.svg`,
      nameApp: 'Quod',
      description: `${ASSETS_URL}/workspace/clubecontador/quod_descricao.svg`,
    },
    {
      tag: 'CERTD',
      logo: `${ASSETS_URL}/workspace/clubecontador/certificado_logo.svg`,
      nameApp: 'Certificado Digital',
      description: `${ASSETS_URL}/workspace/clubecontador/certificado_descricao.svg`,
    },
    {
      tag: 'CL_CTD_P1',
      logo: `${ASSETS_URL}/workspace/clubecontador/clube_logo.svg`,
      nameApp: 'Clube Contador',
      description: `${ASSETS_URL}/workspace/clubecontador/clube_descricao.svg`,
    },
    // {
    //   tag: 'MOBV_',
    //   logo: `${ASSETS_URL}/workspace/clubecontador/mobiledevendas_logo.svg`,
    //   nameApp: 'Mobile de vendas',
    //   description: `${ASSETS_URL}/workspace/clubecontador/mobiledevendas_descricao.svg`,
    // },
  ]

  const tranformToLowerCase = (tag: string): string => {
    return tag.toString().toLowerCase()
  }

  useEffect(() => {
    const appLogo = appsTagAndLogo.find(
      (app) => tranformToLowerCase(app.tag) === tranformToLowerCase(appTag)
    )
    setAppLogo({
      logo: appLogo?.logo as string,
      nameApp: appLogo?.nameApp as string,
      description: appLogo?.description as string,
    })
  }, [appTag])

  return (
    <Flex flexDir="column" mt="31px" ml="35px">
      <Image src={appLogo?.logo} w="150px" />

      <Flex w="70%" mt="32px">
        <Image src={appLogo?.description} w="1200px" />
      </Flex>
    </Flex>
  )
}
