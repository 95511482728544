import { Flex, Image, Text } from '@chakra-ui/react'
import { statusNotes } from '../..'
import GreenSVG from '../../../../../../../assets/icons/green.svg'
import OrangeSVG from '../../../../../../../assets/icons/orange.svg'
import RedSVG from '../../../../../../../assets/icons/red.svg'

interface ReleasesBottomProps {
  lineSelectedToImported: statusNotes
}

export const ReleasesBottom = ({
  lineSelectedToImported,
}: ReleasesBottomProps) => {
  return (
    <Flex
      w={'587px'}
      h="62px"
      bg="#fff"
      mt={{ xl: '19px', '2xl': '59px' }}
      justifyContent={'space-around'}
      borderRadius="15px"
      boxShadow={
        '0px 2px 3px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)'
      }
    >
      <Flex flexDirection={'column'} justifyContent="space-evenly">
        <Text
          fontWeight={'600'}
          fontSize="14px"
          color={'rgba(22, 27, 37, 0.5)'}
        >
          Linhas selecionadas
        </Text>
        <Flex>
          <Image src={OrangeSVG} alt="orange" width={'10px'} />
          <Text fontWeight={'700'} fontSize="18px" color="#58595B" ml={'4%'}>
            {lineSelectedToImported.lineSelectedToImport.length}
          </Text>
        </Flex>
      </Flex>

      <Flex flexDirection={'column'} justifyContent="space-evenly">
        <Text fontWeight={'600'} fontSize="14px" color={'#161B2580'}>
          Lançamentos com sucesso
        </Text>
        <Flex>
          <Image src={GreenSVG} alt="green" width={'10px'} />
          <Text fontWeight={'700'} fontSize="18px" color="#58595B" ml={'4%'}>
            {lineSelectedToImported.entrySuccess}
          </Text>
        </Flex>
      </Flex>

      <Flex flexDirection={'column'} justifyContent="space-evenly">
        <Text fontWeight={'600'} fontSize="14px" color={'#161B2580'}>
          Itens com erro
        </Text>
        <Flex>
          <Image src={RedSVG} alt="red" width={'10px'} />
          <Text fontWeight={'700'} fontSize="18px" color="#58595B" ml={'4%'}>
            {lineSelectedToImported.entryError}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
