import { Button, Flex, useDisclosure } from '@chakra-ui/react'
import { ReactComponent as Hands } from '../Icons/hands.svg'
import { IndicaParceiro } from './Modal/IndicaParceiro'

export const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Flex w="100%" justifyContent={'flex-end'} mb="15px">
      <Button
        w="188px"
        h="40px"
        bg="#166DD7"
        _hover={{ bg: '#166DD7' }}
        color="#fff"
        fontFamily={'Sora'}
        fontSize={'14px'}
        fontWeight={'600'}
        onClick={onOpen}
        leftIcon={<Hands />}
      >
        Indicar parceiro
      </Button>

      <IndicaParceiro isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
