import { useState } from 'react'
import { TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'

import { ReactComponent as user_square } from 'src/assets/icons/user_square.svg'

import { ReactComponent as security_safe } from 'src/assets/icons/security_safe.svg'
import { ReactComponent as cobranca_icon } from 'src/assets/icons/Icon-cobranca.svg'
import { ReactComponent as medal_stat } from 'src/assets/icons/medal_stat.svg'
import { TabHeader } from '../Analytics/components/TabHeader'
import { Certificate } from './Certificate'
import { Plan } from './Plan'

import { General } from './General'

import { Helmet } from 'react-helmet-async'
import { Unique } from './General/unique'
import { useSession } from 'src/contexts'
import { useCan } from 'src/hooks/useCan'
import { Charge } from './Charge'
import { Outlet, useNavigate } from 'react-router-dom'

export const CompanySettings = () => {
  const navigate = useNavigate()
  const { session } = useSession()
  const [tabIndex, setTabIndex] = useState(0)

  const isAdmin = useCan({ permissions: ['ADMIN'] })

  function handleClickTab(index: number) {
    if (index === 0) navigate(`geral`)
    if (index === 1) navigate(`certificate`)
    if (index === 2) navigate(`plan`)
    if (index === 3) navigate(`charge`)
  }

  return (
    <>
      <Helmet>
        <title>Configurações | Workspace</title>
      </Helmet>
      <Tabs
        onChange={setTabIndex}
        isLazy
        w="full"
        bg="high.pure"
        borderRadius="4px"
        boxShadow={
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }
      >
        <TabList>
          <TabHeader
            title="Geral"
            icon={user_square}
            hasSelect={tabIndex === 0}
            onClick={() => handleClickTab(0)}
          />

          <TabHeader
            title="Certificado"
            icon={security_safe}
            hasSelect={tabIndex === 1}
            isDisabled={!isAdmin}
            onClick={() => handleClickTab(1)}
          />

          <TabHeader
            title="Meus serviços"
            icon={medal_stat}
            hasSelect={tabIndex === 2}
            onClick={() => handleClickTab(2)}
          />

          <TabHeader
            title="Cobrança"
            icon={cobranca_icon}
            hasSelect={tabIndex === 3}
            isDisabled={!isAdmin}
            onClick={() => handleClickTab(3)}
          />
        </TabList>

        <TabPanels>
          <TabPanel id="geral" h="calc(100vh - 130px)" overflowY="auto">
            <Outlet />
          </TabPanel>

          <TabPanel id="certificado" h="calc(100vh - 130px)">
            <Outlet />
          </TabPanel>

          <TabPanel id="plano" h="calc(100vh - 130px)">
            <Outlet />
          </TabPanel>
          <TabPanel id="charge" h="calc(100vh - 130px)">
            <Outlet />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}
