import { settingURL } from 'src/utilities/settingsUrlAPi'

const axios = require('axios')

interface RedundanceInterface {
  status: number
  data: any
  url: string
}

const setDataApi = (url: string) => {
  localStorage.setItem('api', url)
  setDataGestor(url)
  return
}

const setDataGestor = (url: string) => {
  const urlsGestor = settingURL.filter((item) => item.url === url)
  localStorage.setItem('gestor', JSON.stringify(urlsGestor[0].nsServers))
  return
}

export const getDataGestor = () => {
  const gestor = localStorage.getItem('gestor')
  return JSON.parse(gestor || '[]')
}

export function testingApis(
  urls: string[],
  tentativa = 0,
): Promise<RedundanceInterface> {
  const urlAtual = urls[tentativa]

  const data = axios
    .get(`${urlAtual}:9000/workspace/TestarApi/`, {
      headers: {
        gatewayls: '2e44bb6339e6aacd8faeca8fd4e8694e',
      },
    })
    .then((response: any) => {
      // Processar a resposta da API

      setDataApi(urlAtual)
      return { status: response.status, data: response.data, url: urlAtual }
    })
    .catch((error: any) => {
      console.error(`Erro na requisição para ${urlAtual}: ${error.message}`)

      // Tentar outra URL se disponível
      if (tentativa < urls.length - 1) {
        testingApis(urls, tentativa + 1)
      } else {
        console.error(
          `Todas as tentativas falharam. Não foi possível chamar a API.`,
        )
      }
    })

  return data
}
