export const mask = (string: string): string => {
  string = string.replace(/\D/g, '')

  if (string.length <= 11) {
    string = string.replace(/(\d{3})(\d)/, '$1.$2')
    string = string.replace(/(\d{3})(\d)/, '$1.$2')
    string = string.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  } else {
    string = string.replace(/^(\d{2})(\d)/, '$1.$2')
    string = string.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    string = string.replace(/\.(\d{3})(\d)/, '.$1/$2')
    string = string.replace(/(\d{4})(\d)/, '$1-$2')
  }

  return string
}
