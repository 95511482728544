import {
  Button,
  Center,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useSession } from 'src/contexts'
import { getEcoCompanyGroup } from 'src/services/api'
import HomePerfilImg from '../../assets/icons/homeperfil.svg'
import { DropzoneModal } from './components/Modals/DropzoneModal'
import { EconomicGroup } from './types/types'
import DownloadFile from './utils/downloadFile'
import { mask } from './utils/maskCnpjCpf'

export const ImportadorXLS = () => {
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)
  const [dataGroupEconomic, setDataGroupEconomic] = useState<EconomicGroup[]>()
  const [openModal, setOpenModal] = useState(false)

  const [render, setRender] = useState(0)

  function manipuleModal() {
    setRender(3)
    setOpenModal(!openModal)
    return openModal
  }

  const { session } = useSession()

  const getGroupEconomic = async () => {
    try {
      setLoading(true)
      onOpen()
      const { grupo } = await getEcoCompanyGroup(session.company.document)

      setDataGroupEconomic(grupo)

      setLoading(false)
    } catch (error) {
      toast({
        title: 'Erro ao carregar os grupos econômicos',
        description: 'Tente novamente mais tarde',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  return (
    <Flex w="100%" h="100%">
      <Center w="366.31px" bg="rgba(17, 85, 187, 0.85)">
        <Image
          src={HomePerfilImg}
          alt="homeperfil"
          style={{
            zIndex: '9',
            marginLeft: '90%',
          }}
        />
      </Center>

      <Center w="839.46px" bg="#fff" display="grid">
        <Flex display="grid" marginLeft="10%">
          <Text
            fontWeight="700"
            fontSize="36px"
            color="#000000"
            textAlign="justify"
            marginBottom="2rem"
            fontFamily="Sora"
          >
            Importa Fácil
          </Text>

          <Text textAlign="justify" mb="2rem" fontFamily={'Inter'}>
            Ganhe mais tempo e flexibilidade em seu negócio!
            <Text fontWeight="bold">
              Use planilhas externas para importar pedidos para o sistema
              Gestor.{' '}
            </Text>
            <Text mt={'25px'}>
              Você também pode organizar as suas informações
            </Text>
            <Text>com a nossa planilha padrão. Com ela disponibilizamos</Text>
            <Text>as referências de cabeçalhos e basta você preencher</Text>
            <Text>com os seus dados.</Text>
            <Text mt="25px">Formatos aceitos: XLS, XLSX ou CSV.</Text>
            <Text fontSize="0.875rem" mt="10px">
              Observação: nos valores das planilhas você deve utilizar ponto(.)
            </Text>
            <Text fontSize="0.875rem">
              para milhar e vírgula (,) para centavos.
            </Text>
          </Text>

          <Flex textAlign={'center'} justifyContent="space-between">
            <DropzoneModal openModal={openModal} render={render} />

            <DownloadFile />

            <Button
              backgroundColor="#1155BB"
              borderRadius="4px"
              width="127px"
              height="52px"
              fontFamily={'Inter'}
              fontWeight="700"
              _hover={{ backgroundColor: '#006AFF' }}
              onClick={() => {
                getGroupEconomic()
              }}
            >
              <Text color="#FFFFFF">Continuar</Text>
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader
                  alignSelf="center"
                  fontFamily="Inter"
                  fontWeight={'600'}
                  fontSize="32px"
                >
                  Selecione a empresa
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Flex alignItems="center" flexDirection="column">
                    {loading ? (
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                      />
                    ) : (
                      dataGroupEconomic?.map((InfoGroupEconomic, index) => (
                        <Button
                          display="flex"
                          flexDir="column"
                          key={index}
                          bg="#1155BB"
                          color="#fff"
                          gap="3px"
                          w="455px"
                          mb="23px"
                          h="92px"
                          borderRadius="6px"
                          fontFamily={'Inter'}
                          textTransform="lowercase"
                          fontWeight="400"
                          fontSize={'18px'}
                          _hover={{ bg: '#006AFF' }}
                          onClick={manipuleModal}
                        >
                          <Text>{InfoGroupEconomic.razao}</Text>
                          <Text mt="5px">
                            {mask(InfoGroupEconomic.cnpjCpf)}
                          </Text>
                        </Button>
                      ))
                    )}
                  </Flex>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
        </Flex>
      </Center>
    </Flex>
  )
}
