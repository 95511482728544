export const appsWithPermissions = [
  'SIMPLAZ',
  'GE_L4',
  'GE_L3',
  'GE_L2',
  'GE_L1',
  'GE_N4',
  'GE_N3',
  'GE_N2',
  'GE_N1',
  'UN_L1',
  'UN_L2',
  'UN_L3',
  'UN_L4',
  'SP_P2',
  'SP_P3',
  'SP_P4',
  'SP_CTB_P5',
  'SP_CTB_P6',
  'SP_CTB_P7',
]
