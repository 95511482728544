import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'

import { Flex, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { apiInstance, postGravarLog } from 'src/services/api'

import { ReactComponent as salesPerMonthIcon } from 'src/assets/graphMonth.svg'
import { ReactComponent as termOfPaymentIcon } from 'src/assets/graphPay.svg'
import { ReactComponent as totalPerSellerIcon } from 'src/assets/graphStar.svg'
import { useSession } from 'src/contexts'

import { AnalyticalFilters } from './components/AnalyticalFilters'
import { TabHeader } from './components/TabHeader'
import { TermOfPayment } from './payments'
import { SalesPerMonth } from './sales'
import { TotalPerSeller } from './seller'

interface Seller {
  codigo: number
  nome: string
}

export const Analytics = () => {
  const { session } = useSession()
  const [analyticsToken, setAnalyticsToken] = useState<string | null>(null)
  const [tabIndex, setTabIndex] = useState(0)
  const [sellers, setSellers] = useState<Seller[]>([])
  const [seller, setSeller] = useState(0)
  const [period, setPeriod] = useState(() => ({
    initial: dayjs().startOf('month').format('YYYY-MM-DD'),
    final: dayjs().format('YYYY-MM-DD'),
  }))

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  }

  const changeSeler = (sellerId: number) => setSeller(sellerId)

  const changePeriod = useCallback((value: string) => {
    if (value === 'mensal') {
      const period = {
        initial: dayjs().startOf('month').format('YYYY-MM-DD'),
        final: dayjs().format('YYYY-MM-DD'),
      }
      setPeriod(period)
    }
    if (value === 'trimestral') {
      const period = {
        initial: dayjs()
          .subtract(3, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        final: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      }
      setPeriod(period)
    }
    if (value === 'semestral') {
      const period = {
        initial: dayjs()
          .subtract(6, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        final: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      }
      setPeriod(period)
    }
  }, [])

  const getSellers = useCallback(async () => {
    try {
      const { data } = await apiInstance(9000).get(
        `/relatorios/ListaVendedores/?cnpjcpf=${session?.company?.document}`,
        {
          headers: {
            authorization: `bearer ${analyticsToken}`,
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        },
      )

      setSellers(data.vendedores)
    } catch (error: any) {
      console.log(error)
    }
  }, [analyticsToken, session?.company?.document])

  const getAnalyticsToken = useCallback(async () => {
    const log = {
      EmpresaCnpj: session.company.document,
      IdUsuario: session.id,
      NomeUsuario: session.name,
      Aplicativo: 'Analytics',
      Acao: 'Acesso ao Analytics',
      TipoAcao: 'Login',
      EndPoint: '',
    }

    postGravarLog(log)

    try {
      const { data } = await apiInstance(9000).post(
        '/relatorios/Login/',
        {
          login: 'desenvolvimento@londrisoft.com.br',
          password: '1234',
        },
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        },
      )
      setAnalyticsToken(data.accessToken)
    } catch (error) {
      console.log('falha ao recuperar o token')
    }
  }, [])

  useEffect(() => {
    getAnalyticsToken()
  }, [getAnalyticsToken])

  useEffect(() => {
    if (analyticsToken) {
      getSellers()
    }
  }, [analyticsToken, getSellers])

  return (
    <>
      <Helmet>
        <title>Analítico | Workspace</title>
      </Helmet>
      <Flex
        flexDirection={'column'}
        width={'100%'}
        // overflowY="scroll"
        overflowX={'hidden'}
        p="12px"
        bg="high.pure"
        boxShadow={
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }
        borderRadius="4px"
      >
        <AnalyticalFilters
          sellers={sellers}
          changePeriod={changePeriod}
          changeSeler={changeSeler}
        />

        <Tabs mt={6} onChange={handleTabsChange}>
          <TabList>
            <TabHeader
              title="Vendas por mês"
              icon={salesPerMonthIcon}
              hasSelect={tabIndex === 0}
            />

            <TabHeader
              title="Prazo de pagamento"
              icon={termOfPaymentIcon}
              hasSelect={tabIndex === 1}
            />

            <TabHeader
              title="Total por Vendedor"
              icon={totalPerSellerIcon}
              hasSelect={tabIndex === 2}
            />
          </TabList>

          <TabPanels>
            <TabPanel id="salesPerMonth">
              <SalesPerMonth
                step={tabIndex}
                sellerId={seller}
                period={period}
                analyticsToken={analyticsToken}
              />
            </TabPanel>
            <TabPanel id="termOfPayment">
              <TermOfPayment
                step={tabIndex}
                sellerId={seller}
                period={period}
                analyticsToken={analyticsToken}
              />
            </TabPanel>
            <TabPanel id="totalPerSeller">
              <TotalPerSeller
                step={tabIndex}
                period={period}
                sellerId={seller}
                analyticsToken={analyticsToken}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  )
}
