import {
  Button,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSession } from 'src/contexts'
import { postSavedCorrelation } from 'src/services/api'
import { decryptedSimple, encryptedSimple } from 'src/utilities/hashing'
import CorrelationSVG from '../../../../../assets/icons/correlationicon.svg'
import CursorSVG from '../../../../../assets/icons/cursor.svg'
import FailedIcon from '../../../../../assets/icons/failedicon.svg'
import Step3SVG from '../../../../../assets/icons/step3.svg'
import SuccessIcon from '../../../../../assets/icons/sucessicon.svg'
import SelectItem from '../../../components/SelectItem/select'
import { fildesGestor } from '../../../utils/fildesGestor'
import { validationLineTable } from '../../../utils/validations/validationsCorrelation'
import { LoadingModal } from './components/modals/loadingModal'
import { SavedModal } from './components/modals/savedModal'
import { TReturnTable } from './types/types'
import { VerifyRequiredField } from './utils/verifyFieldRequired'

export interface Data {
  head: string[]
  table: string[]
  valueCorrelation: string
}

let arrayView: TReturnTable[] = []

const CorrelationPage = ({ indexTable }: any) => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [inputCorrelation, setInputCorrelation] = useState<string>('')
  const [isModalCorrelation, setIsModalCorrelation] = useState(false)
  const [returnTable, setReturnTable] = useState<TReturnTable[]>([])

  const toast = useToast()

  const [loading, setLoading] = useState(false)
  const { session } = useSession()

  const { path } = useParams()
  const decodePath = path!
    .replaceAll('8754238y5823y523h52h38ffhgf72h3u', '+')
    .replaceAll('98fds49f8d5ds98f5ds98f54ds984d9841fvcx9581ff1', '/')
  const decryptData: Data = decryptedSimple(decodePath!)
  let { head, table, valueCorrelation } = decryptData

  useEffect(() => {
    verifyCorrelationSaved()
  }, [])

  const verifyCorrelationSaved = () => {
    if (valueCorrelation !== '') {
      const { arrayView } = JSON.parse(atob(valueCorrelation))

      setReturnTable(arrayView)
    }
  }

  const handleSaveCorrelation = async () => {
    // var encode64 = btoa(value)
    try {
      setLoading(true)

      const value = { arrayView, nameCorrelation: inputCorrelation }

      const payload = {
        companyId: session.company.id,
        userId: session.id,
        origin: 'hub',
        value: btoa(JSON.stringify(value)),
        key: 'hub-correlation',
        dateCreated: new Date(),
        inativo: 0,
      }

      await postSavedCorrelation(session.company.document, payload)
      // transition()
    } catch (err) {
      toast({
        title: 'Falha ao salvar a correlação.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const loadingModal = <LoadingModal isOpen={isOpen} onClose={onClose} />
  const transition = () => {
    setSteps(loadingModal)
    setTimeout(() => {
      navigate(
        `/importa-facil/selectpage/${encryptedSimple({
          head: head,
          table: table,
        })}`
      )
    }, 2500)
  }
  const SaveModal = (
    <SavedModal
      isOpen={isOpen}
      onClose={onClose}
      setInputCorrelation={setInputCorrelation}
      inputCorrelation={inputCorrelation}
      table={table}
      returnTable={returnTable}
      handleSaveCorrelation={handleSaveCorrelation}
      setIsModalCorrelation={setIsModalCorrelation}
    />
  )

  const [steps, setSteps] = useState(SaveModal)

  const isRequiredField = [0, 2, 3, 15, 16, 17, 19]

  const itemsWithAlert = ['Valor Total Produto', 'Valor Unitário']

  const isAprovedItemTable = [
    'string',
    'string',
    'string',
    'cpf',
    'celular',
    'telefone',
    'email',
    'endereco',
    'numero',
    'cidade',
    'estado',
    'cep',
    'Complemento',
    'Observação Endereço',
    'Código do Pedido',
    'Observação Pedido',
    'Produto (obrigatório) ',
    'Quantidade (obrigatório)',
    'Valor Unitário (obrigatório)',
    'Valor Total Produto',
    'SKU',
    'Forma de Pagamento',
    'Valor Total',
  ]

  const [selected, setSelected] = useState<string>()

  function handleSelect(select: any) {
    setSelected(select)
  }

  function fetchTableInfo(key_gestor: number, tableIndex: number) {
    arrayView.push({
      key_table: key_gestor,
      table_index: Number(tableIndex),
      status: true,
    })

    arrayView = arrayView.filter((item) => item.key_table !== key_gestor)

    arrayView = [
      ...arrayView,
      {
        key_table: key_gestor,
        table_index: Number(tableIndex),
        status: true,
      },
    ]
    setReturnTable(arrayView)
  }

  const verifyRequiredField = () => {
    if (
      VerifyRequiredField({
        returnTable,
        isRequiredField,
      })
    ) {
      setIsModalCorrelation(true)
      onOpen()
    } else {
      toast({
        title: 'Campos obrigatórios não preenchidos',
        description: 'Preencha todos os campos obrigatórios',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Flex flexDir={'column'} maxW="100%" w={'71.3rem'} fontFamily="Inter">
      <Flex w={'100%'} justifyContent="space-between">
        <Flex justifyContent={'space-between'}>
          <Image src={CursorSVG} alt="cursor" width={'5%'} mb={'5%'} />
        </Flex>

        <Flex>
          <Flex alignItems={'flex-start'}>
            <Image src={Step3SVG} alt="step3" />
          </Flex>
        </Flex>
      </Flex>

      <Flex w={'100%'} mb={'1%'}>
        <Flex>
          <Image src={CorrelationSVG} alt="correlation" />
          <Flex flexDir={'column'} ml="18px">
            <Text fontWeight={'600'} fontSize="22px" color={'#333333'}>
              Correlacione os dados correspondentes
            </Text>
            <Text>
              Selecione os dados da sua planilha referentes aos campos do
              sistema Gestor
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        bg="#FFFFFF"
        borderRadius="10px"
        boxShadow={
          '0px 2px 3px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)'
        }
        border="1px solid #e1e0e7"
        h={'42.7rem'}
        overflow="auto"
      >
        <TableContainer
          padding={'44px 46px'}
          overflowY="auto"
          overflowX={'auto'}
          css={{
            '&::-webkit-scrollbar': {
              background: '#ddd',
              width: '6px',
              height: '6px',
            },
            '&::-webkit-scrollbar-track': {
              width: '2px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#A1A1AA',
              borderRadius: '24px',
            },
          }}
        >
          <Table variant={'simple'} size="lg" color="#58595B">
            <Thead bg={'#F7F8FA'}>
              <Tr fontSize="14px" color={'#000000'}>
                <Th fontWeight={'500'}>CAMPOS DO SISTEMA GESTOR</Th>
                <Th fontWeight={'500'}>INFORMAÇÃO DA PLANILHA</Th>
                <Th fontWeight={'500'}>TIPO DE COLUNA</Th>
                <Th fontWeight={'500'}>STATUS DA COLUNA</Th>
              </Tr>
            </Thead>
            <Tbody>
              {fildesGestor.map((item: string, index: number) => (
                <Tr
                  key={index}
                  bg={
                    index <= 1
                      ? '#FFE7C2'
                      : index > 1 && index <= 14
                      ? '#DFECFF'
                      : index > 14 && index <= 19
                      ? '#ECE9FF'
                      : '#DEF1F1'
                  }
                >
                  <Td>
                    <Flex flexDir="column">
                      <Flex>
                        <Text mr="5px">{item}</Text>
                        <Text color="#F32626">
                          {isRequiredField.includes(index)
                            ? '(Obrigatório)'
                            : null}
                        </Text>
                      </Flex>
                      {itemsWithAlert.includes(item) && (
                        <Flex>
                          <Text fontSize="12px" color="#58595B">
                            *Utilizar ponto para milhar e vírgula para centavos.
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </Td>
                  <Td>
                    {returnTable.map((items, i) => (
                      <Text key={i}>
                        {items.key_table === index ? (
                          <Text
                            color={
                              validationLineTable(
                                isAprovedItemTable[index],
                                table[0][items.table_index]
                              )
                                ? '#58595B'
                                : '#F32626'
                            }
                          >
                            {table[0][items.table_index]}
                          </Text>
                        ) : null}
                      </Text>
                    ))}
                  </Td>
                  <Td>
                    <SelectItem
                      data={head}
                      key_gestor={index}
                      func={fetchTableInfo}
                    />
                  </Td>
                  <Td>
                    {returnTable.map((items, i) => (
                      <Text key={i}>
                        {items.key_table === index ? (
                          <Image
                            m="auto"
                            src={
                              validationLineTable(
                                isAprovedItemTable[index],
                                table[0][items.table_index]
                              )
                                ? SuccessIcon
                                : FailedIcon
                            }
                          />
                        ) : null}
                      </Text>
                    ))}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>

      <Flex w={'100%'} justifyContent="flex-end" mt={'2%'}>
        <Link to={'/importa-facil'}>
          <Button
            w="132px"
            h={'52px'}
            bg="transparent"
            border="1px solid #1155BB"
            _hover={{ bg: 'transparent' }}
            color="#1155BB"
            borderRadius={'4px'}
            fontSize="16px"
            fontWeight={'700'}
            onClick={() => (arrayView = [])}
          >
            Cancelar
          </Button>
        </Link>

        <Button
          w="208px"
          h={'52px'}
          bg="#1155BB"
          _hover={{ bg: '#006AFF' }}
          color="#fff"
          borderRadius={'4px'}
          fontSize="16px"
          fontWeight={'700'}
          ml="20px"
          onClick={verifyRequiredField}
        >
          Importar arquivo
        </Button>

        {steps}
      </Flex>

      {isModalCorrelation && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={'xl'}>
          <ModalOverlay />
          {SaveModal}
        </Modal>
      )}
    </Flex>
  )
}

export default CorrelationPage
