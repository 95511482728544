export type Profile = {
  id: string
  name: string
  bgColor: string
  textColor: string
  link?: string
}

export const WorkspaceProfiles: Profile[] = [
  {
    id: '0',
    name: 'Administrador',
    bgColor: '#D4EDFA',
    textColor: '#39ACE5',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-gestor_type1_adm&utm_medium=ws-ca&utm_campaign=ws-ca-gestor_adm',
  },
  {
    id: '1',
    name: 'Usuário',
    bgColor: '#B5FFD2',
    textColor: '#41B971',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-gestor_type1_adm&utm_medium=ws-ca&utm_campaign=ws-ca-gestor_adm',
  },
  {
    id: '999',
    name: 'Selecione',
    bgColor: '#DED4F9',
    textColor: '#A68FE5',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-gestor_type7_ind&utm_medium=ws-ca&utm_campaign=ws-ca-gestor_ind',
  },
]

export const GestorProfiles: Profile[] = [
  {
    id: '1',
    name: 'Administrador',
    bgColor: '#D4EDFA',
    textColor: '#39ACE5',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-gestor_type1_adm&utm_medium=ws-ca&utm_campaign=ws-ca-gestor_adm',
  },
  {
    id: '2',
    name: 'Cadastro',
    bgColor: '#FCD9FF',
    textColor: '#EF73FA',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-gestor_type2_cad&utm_medium=ws-ca&utm_campaign=ws-ca-gestor_cad',
  },
  {
    id: '3',
    name: 'Faturamento',
    bgColor: '#B5FFD2',
    textColor: '#41B971',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-gestor_type3_fat&utm_medium=ws-ca&utm_campaign=ws-ca-gestor_fat',
  },
  {
    name: 'PDV',
    id: '4',
    bgColor: '#B5FFD2',
    textColor: '#41B971',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-gestor_type4_pdv&utm_medium=ws-ca&utm_campaign=ws-ca-gestor_pdv',
  },
  {
    name: 'NF-e',
    id: '5',
    bgColor: '#FAF2D4',
    textColor: '#E5C245',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-gestor_type5_nfe&utm_medium=ws-ca&utm_campaign=ws-ca-gestor_nfe',
  },
  {
    id: '6',
    name: 'Financeiro',
    bgColor: '#FFD9D9',
    textColor: '#E57373',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-gestor_type6_fin&utm_medium=ws-ca&utm_campaign=ws-ca-gestor_fin',
  },
  {
    id: '7',
    name: 'Indústria',
    bgColor: '#DED4F9',
    textColor: '#A68FE5',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-gestor_type7_ind&utm_medium=ws-ca&utm_campaign=ws-ca-gestor_ind',
  },
  {
    id: '999',
    name: 'Selecione',
    bgColor: '#DED4F9',
    textColor: '#A68FE5',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-gestor_type7_ind&utm_medium=ws-ca&utm_campaign=ws-ca-gestor_ind',
  },
]

export const UniqueProfiles: Profile[] = [
  {
    id: '1',
    name: 'Administrador',
    bgColor: '#D4EDFA',
    textColor: '#39ACE5',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-unique_type1_adm&utm_medium=ws-ca&utm_campaign=ws-ca-unique_adm',
  },
  {
    id: '2',
    name: 'Cadastro',
    bgColor: '#FCD9FF',
    textColor: '#EF73FA',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-unique_type2_cad&utm_medium=ws-ca&utm_campaign=ws-ca-unique_cad',
  },
  {
    id: '3',
    name: 'Folha',
    bgColor: '#B5FFD2',
    textColor: '#41B971',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-unique_type3_fol&utm_medium=ws-ca&utm_campaign=ws-ca-unique_fol',
  },
  {
    id: '4',
    name: 'Fiscal',
    bgColor: '#FAF2D4',
    textColor: '#E5C245',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-unique_type4_fis&utm_medium=ws-ca&utm_campaign=ws-ca-unique_fis',
  },
  {
    id: '5',
    name: 'Contábil',
    bgColor: '#FFD9D9',
    textColor: '#E57373',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-unique_type5_con&utm_medium=ws-ca&utm_campaign=ws-ca-unique_con',
  },
  {
    id: '6',
    name: 'Contábil Físcal',
    bgColor: '#DED4F9',
    textColor: '#A68FE5',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-unique_type6_confis&utm_medium=ws-ca&utm_campaign=ws-ca-unique_confis',
  },
  {
    id: '999',
    name: 'Selecione',
    bgColor: '#DED4F9',
    textColor: '#A68FE5',
    link: 'https://londrisoft.movidesk.com/kb/pt-br?utm_source=ws-ca-gestor_type7_ind&utm_medium=ws-ca&utm_campaign=ws-ca-gestor_ind',
  },
]
