import { applyHtmlCodeInEspecialCharacter } from './applyHtmlCodeInEspecialCharacter'

interface EmailHtmlPloomesProps {
  cnpj: string
  razao_social: string
  email: string
  telefone: string
  app_interesse: string
}
export function getEmailHtmlPloomes({
  cnpj,
  razao_social,
  email,
  telefone,
  app_interesse,
}: EmailHtmlPloomesProps): string {
  const file = `<html>
  <head>
  <title>Aplicativo | Acesso Liberado</title>
  <meta charset="utf-8">
  </head>
  <body bgcolor="#FFFFFF" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0"><center>
  <table id="Tabela_01" width="640" height="auto" border="0" cellpadding="0" cellspacing="0" bgcolor="#F7F7F7">
    <tr>
      <td colspan="6">
        <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_01.png" width="640" height="80" alt="Londrisoft" style="display:block;"></td>
    </tr>
    <tr>
      <td colspan="6">
        <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_ploomes.png" width="640" height="190" alt="Novo Cadastro" style="display:block;"></td>
    </tr>
    <tr>
      <td width="640" height="auto" colspan="6">
      
  <table width="640" border="0">
    <tbody>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560" style="text-align:center; line-height:24px; font-size:14px;">
  <font face="Arial, sans-serif" color="#1e57bd" size="+2"><strong>AVISO</strong></font>
      </td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">&nbsp;</td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560" style="text-align:center; line-height:22px; font-size:16px;">
  <font face="Arial, sans-serif" color="#222222">Olá, recebemos um lead no <strong>Workspace Londrisoft</strong>.<br><br>
  <strong>Informações:</strong>
      </td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">&nbsp;</td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560"><table width="560" border="0" bgcolor="#e5e5e5">
          <tbody>
            <tr>
              <td width="20">&nbsp;</td>
              <td width="520"></td>
              <td width="20">&nbsp;</td>
            </tr>
            <tr>
              <td width="20">&nbsp;</td>
              <td width="520" style="text-align:left; line-height:24px; font-size:14px;"><font face="Arial, sans-serif" color="#222222">
            CNPJ: <b>${cnpj}</b>
            <br>
            Razão Social: <b>${razao_social}</b>
           <br>
            Telefone: <b>${telefone}</b>
            <br>
           E-mail: <b>${email}</b>
            <br>
            Aplicativo de Interesse: <b>${app_interesse}</b>
          </font></td>
              <td width="20">&nbsp;</td>
            </tr>
            <tr>
              <td width="20">&nbsp;</td>
              <td width="520"></td>
              <td width="20">&nbsp;</td>
            </tr>
          </tbody>
        </table></td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">
      
      </td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560" style="text-align:center; line-height:20px; font-size:14px;">
  <font face="Arial, sans-serif" color="#222222">
  Caso tenha qualquer dúvida, nossa equipe está à disposição.
  </font>
      </td>
        <td width="40">&nbsp;</td>
      </tr>
    </tbody>
  </table>
  
      
      </td>
    </tr>
    <tr>
      <td>
        <a href="https://londrisoft.com.br/?utm_source=email_workspace&utm_medium=rodape_email&utm_campaign=workspace" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/londrisoft.png" width="465" height="80" border="0" alt="Londrisoft" style="display:block;"></a></td>
      <td>
        <a href="https://www.facebook.com/londrisoft" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/facebook.png" width="35" height="80" border="0" alt="Facebook" style="display:block;"></a></td>
      <td>
        <a href="https://www.instagram.com/londrisoft/" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/instagram.png" width="35" height="80" border="0" alt="Instagram" style="display:block;"></a></td>
      <td>
        <a href="https://br.linkedin.com/company/londrisoft" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/linkedin.png" width="35" height="80" border="0" alt="LinkedIn" style="display:block;"></a></td>
      <td>
        <a href="https://www.youtube.com/channel/UCoQWBxPXIDBgubbkb066u0Q" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/youtube.png" width="35" height="80" border="0" alt="YouTube" style="display:block;"></a></td>
      <td>
        <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_09.png" width="35" height="80" alt="" style="display:block;"></td>
    </tr>
  </table>
  </center>
  </body>
  </html>`

  return applyHtmlCodeInEspecialCharacter(file)
}

interface EmailHtmlAtivacaoUsuarioProps {
  user: string
  app_name: string
}
export function getEmailAtivacaoUsuario({
  user,
  app_name,
}: EmailHtmlAtivacaoUsuarioProps): string {
  const file = `<html>
  <head>
  <title>${app_name} | Ativação de Usuário</title>
  <meta charset="utf-8">
  </head>
  <body bgcolor="#FFFFFF" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0"><center>
  <table id="Tabela_01" width="640" height="auto" border="0" cellpadding="0" cellspacing="0" bgcolor="#F7F7F7">
    <tr>
      <td colspan="6">
        <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_01.png" width="640" height="80" alt="Londrisoft" style="display:block;"></td>
    </tr>
    <tr>
      <td colspan="6">
        <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_app-ativacao-usr.png" width="640" height="190" alt="Novo Cadastro" style="display:block;"></td>
    </tr>
    <tr>
      <td width="640" height="auto" colspan="6">
      
  <table width="640" border="0">
    <tbody>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560" style="text-align:center; line-height:20px; font-size:14px;">
  <font face="Arial, sans-serif" color="#1e57bd" size="+2"><strong>Olá, ${user}!</strong></font>		
      </td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">&nbsp;</td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560" style="text-align:center; line-height:22px; font-size:16px;">
  <font face="Arial, sans-serif" color="#222222">
    Sua conta foi liberada pelo administrador para acessar o aplicativo <strong>${app_name}</strong> via <strong>Workspace Londrisoft</strong>.
      </td>
        <td width="40">&nbsp;</td>
      </tr>
    <tr>
        <td width="40">&nbsp;</td>
        <td width="560">&nbsp;</td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">
          <table width="560" border="0" bgcolor="#F7F7F7">
          <tbody>
            <tr>
              <td width="110" height="60">&nbsp;</td>
              <td width="300" height="60" style="text-align:center; line-height:24px; font-size:18px;" bgcolor="#1e57bd"><font face="Arial, sans-serif" color="#222222">	
        <strong><a href="https://workspace.londrisoft.com.br/" target="_blank" style="text-decoration:none;color:#FFFFFF">ACESSAR O WORKSPACE</a></strong></font></td>	
              <td width="110" height="60">&nbsp;</td>
            </tr>
          </tbody>
        </table>
    </td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">&nbsp;</td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560" style="text-align:center; line-height:20px; font-size:14px;">
  <font face="Arial, sans-serif" color="#222222">
  Caso tenha qualquer dúvida, nossa equipe está à disposição.<br>Nosso atendimento é de <strong>segunda a sexta-feira, das 09h às 18h</strong>.
  </font>
      </td>
        <td width="40">&nbsp;</td>
      </tr>
    </tbody>
  </table>
  
      
      </td>
    </tr>
    <tr>
      <td>
        <a href="https://londrisoft.com.br/?utm_source=email_workspace&utm_medium=rodape_email&utm_campaign=workspace" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/londrisoft.png" width="465" height="80" border="0" alt="Londrisoft" style="display:block;"></a></td>
      <td>
        <a href="https://www.facebook.com/londrisoft" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/facebook.png" width="35" height="80" border="0" alt="Facebook" style="display:block;"></a></td>
      <td>
        <a href="https://www.instagram.com/londrisoft/" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/instagram.png" width="35" height="80" border="0" alt="Instagram" style="display:block;"></a></td>
      <td>
        <a href="https://br.linkedin.com/company/londrisoft" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/linkedin.png" width="35" height="80" border="0" alt="LinkedIn" style="display:block;"></a></td>
      <td>
        <a href="https://www.youtube.com/channel/UCoQWBxPXIDBgubbkb066u0Q" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/youtube.png" width="35" height="80" border="0" alt="YouTube" style="display:block;"></a></td>
      <td>
        <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_09.png" width="35" height="80" alt="" style="display:block;"></td>
    </tr>
  </table>
  </center>
  </body>
  </html>`
  return applyHtmlCodeInEspecialCharacter(file)
}

interface EmailHtmlNotifyAdminAcessoLiberadoProps {
  admin: string
  user_name: string
  app_name: string
  dateTime: Date
}
export function getEmailNotifyAdminAcessoLiberado({
  admin,
  user_name,
  app_name,
  dateTime,
}: EmailHtmlNotifyAdminAcessoLiberadoProps): string {
  const date = dateTime.toLocaleDateString()
  const time = `${dateTime.getHours().toString()}:${dateTime
    .getMinutes()
    .toString()}`
  const file = `<html>
  <head>
  <title>${app_name} | Acesso Liberado</title>
  <meta charset="utf-8">
  </head>
  <body bgcolor="#FFFFFF" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0"><center>
  <table id="Tabela_01" width="640" height="auto" border="0" cellpadding="0" cellspacing="0" bgcolor="#F7F7F7">
    <tr>
      <td colspan="6">
        <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_01.png" width="640" height="80" alt="Londrisoft" style="display:block;"></td>
    </tr>
    <tr>
      <td colspan="6">
        <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_app-acesso-lib.png" width="640" height="190" alt="Novo Cadastro" style="display:block;"></td>
    </tr>
    <tr>
      <td width="640" height="auto" colspan="6">
      
  <table width="640" border="0">
    <tbody>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560" style="text-align:center; line-height:24px; font-size:14px;">
  <font face="Arial, sans-serif" color="#1e57bd" size="+2"><strong>AVISO</strong></font>
      </td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">&nbsp;</td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560" style="text-align:center; line-height:22px; font-size:16px;">
  <font face="Arial, sans-serif" color="#222222">Um novo usuário foi liberado no <strong>Workspace Londrisoft</strong><br> para acessar o aplicativo <strong>${app_name}</strong>.<br><br>
  <strong>Informações:</strong>
      </td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">&nbsp;</td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560"><table width="560" border="0" bgcolor="#e5e5e5">
          <tbody>
            <tr>
              <td width="20">&nbsp;</td>
              <td width="520"></td>
              <td width="20">&nbsp;</td>
            </tr>
            <tr>
              <td width="20">&nbsp;</td>
              <td width="520" style="text-align:left; line-height:24px; font-size:14px;"><font face="Arial, sans-serif" color="#222222">
            Usuário: <b>${user_name}</b>
            <br>
            Aplicativo: <b>${app_name}</b>
           <br>
            Data: <b>${date}</b>
            <br>
           Horário: <b>${time}</b>
            <br>
            Administrador responsável pela inclusão: <b>${admin}</b>
          </font></td>
              <td width="20">&nbsp;</td>
            </tr>
            <tr>
              <td width="20">&nbsp;</td>
              <td width="520"></td>
              <td width="20">&nbsp;</td>
            </tr>
          </tbody>
        </table></td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560">
      
      </td>
        <td width="40">&nbsp;</td>
      </tr>
      <tr>
        <td width="40">&nbsp;</td>
        <td width="560" style="text-align:center; line-height:20px; font-size:14px;">
  <font face="Arial, sans-serif" color="#222222">
  Caso tenha qualquer dúvida, nossa equipe está à disposição.
  </font>
      </td>
        <td width="40">&nbsp;</td>
      </tr>
    </tbody>
  </table>
  
      
      </td>
    </tr>
    <tr>
      <td>
        <a href="https://londrisoft.com.br/?utm_source=email_workspace&utm_medium=rodape_email&utm_campaign=workspace" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/londrisoft.png" width="465" height="80" border="0" alt="Londrisoft" style="display:block;"></a></td>
      <td>
        <a href="https://www.facebook.com/londrisoft" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/facebook.png" width="35" height="80" border="0" alt="Facebook" style="display:block;"></a></td>
      <td>
        <a href="https://www.instagram.com/londrisoft/" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/instagram.png" width="35" height="80" border="0" alt="Instagram" style="display:block;"></a></td>
      <td>
        <a href="https://br.linkedin.com/company/londrisoft" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/linkedin.png" width="35" height="80" border="0" alt="LinkedIn" style="display:block;"></a></td>
      <td>
        <a href="https://www.youtube.com/channel/UCoQWBxPXIDBgubbkb066u0Q" target="_blank">
          <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/youtube.png" width="35" height="80" border="0" alt="YouTube" style="display:block;"></a></td>
      <td>
        <img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_09.png" width="35" height="80" alt="" style="display:block;"></td>
    </tr>
  </table>
  </center>
  </body>
  </html>`
  return applyHtmlCodeInEspecialCharacter(file)
}

export const getEmailNewUserNeedAuthorization = (userName: string): string => {
  const html = `
  <html>
<head>
<title>Novo Cadastro</title>
<meta charset="utf-8">
</head>
<body bgcolor="#FFFFFF" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0"><center>
<table id="Tabela_01" width="640" height="auto" border="0" cellpadding="0" cellspacing="0" bgcolor="#F7F7F7">
	<tr>
		<td colspan="6">
			<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_01.png" width="640" height="80" alt="Londrisoft" style="display:block;"></td>
	</tr>
	<tr>
		<td colspan="6">
			<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_novo-cadastro.png" width="640" height="190" alt="Novo Cadastro" style="display:block;"></td>
	</tr>
	<tr>
		<td width="640" height="auto" colspan="6">
		
<table width="640" border="0">
  <tbody>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560" style="text-align:center; line-height:20px; font-size:14px;">
<font face="Arial, sans-serif" color="#1e57bd" size="+2"><strong>Olá, ${userName}!</strong></font>		
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560">&nbsp;</td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560" style="text-align:center; line-height:22px; font-size:16px;">
<font face="Arial, sans-serif" color="#222222">Seja bem-vindo(a) ao <strong>Workspace Londrisoft!</strong><br><br>
	Sua conta foi cadastrada pelo administrador e está aguardando<br> validação pela nossa equipe. Assim que a validação for concluída,<br> enviaremos um e-mail informando.
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560">&nbsp;</td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560" style="text-align:center; line-height:22px; font-size:16px;">
<font face="Arial, sans-serif" color="#222222">
Nossa verificação é realizada de <strong>segunda a sexta-feira, das 09h às 18h</strong>.
</font>
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
  </tbody>
</table>

		
		</td>
	</tr>
	<tr>
		<td>
			<a href="https://londrisoft.com.br/?utm_source=email_workspace&utm_medium=rodape_email&utm_campaign=workspace" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/londrisoft.png" width="465" height="80" border="0" alt="Londrisoft" style="display:block;"></a></td>
		<td>
			<a href="https://www.facebook.com/londrisoft" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/facebook.png" width="35" height="80" border="0" alt="Facebook" style="display:block;"></a></td>
		<td>
			<a href="https://www.instagram.com/londrisoft/" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/instagram.png" width="35" height="80" border="0" alt="Instagram" style="display:block;"></a></td>
		<td>
			<a href="https://br.linkedin.com/company/londrisoft" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/linkedin.png" width="35" height="80" border="0" alt="LinkedIn" style="display:block;"></a></td>
		<td>
			<a href="https://www.youtube.com/channel/UCoQWBxPXIDBgubbkb066u0Q" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/youtube.png" width="35" height="80" border="0" alt="YouTube" style="display:block;"></a></td>
		<td>
			<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_09.png" width="35" height="80" alt="" style="display:block;"></td>
	</tr>
</table>
</center>
</body>
</html>
  `

  return applyHtmlCodeInEspecialCharacter(html)
}

interface EmailHtmlNotifyAdminNewUserProps {
  empresa: string
  cnpj: string
  nome: string
  email: string
  telefone: string
  statusUser: string
  cadastradoPor: string
}

export const getEmailWarningAdminUserCreated = ({
  empresa,
  cnpj,
  nome,
  email,
  telefone,
  statusUser,
  cadastradoPor,
}: EmailHtmlNotifyAdminNewUserProps): string => {
  const html = `
  <html>
<head>
<title>Novo Cadastro</title>
<meta charset="utf-8">
</head>
<body bgcolor="#FFFFFF" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0"><center>
<table id="Tabela_01" width="640" height="auto" border="0" cellpadding="0" cellspacing="0" bgcolor="#F7F7F7">
	<tr>
		<td colspan="6">
			<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_01.png" width="640" height="80" alt="Londrisoft" style="display:block;"></td>
	</tr>
	<tr>
		<td colspan="6">
			<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_novo-cadastro.png" width="640" height="190" alt="Novo Cadastro" style="display:block;"></td>
	</tr>
	<tr>
		<td width="640" height="auto" colspan="6">
		
<table width="640" border="0">
  <tbody>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560" style="text-align:center; line-height:24px; font-size:14px;">
<font face="Arial, sans-serif" color="#1e57bd" size="+2"><strong>AVISO</strong></font><br>
<font face="Arial, sans-serif" color="#1e57bd"><strong>${new Date().toLocaleDateString()}</strong></font>
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560">&nbsp;</td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560" style="text-align:center; line-height:22px; font-size:16px;">
<font face="Arial, sans-serif" color="#222222">Um novo cadastro de usuário foi realizado no <strong>Workspace Londrisoft.<br><br>
Credenciais do novo cadastro:</strong>
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560">&nbsp;</td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560"><table width="560" border="0" bgcolor="#e5e5e5">
        <tbody>
          <tr>
            <td width="20">&nbsp;</td>
            <td width="520"></td>
            <td width="20">&nbsp;</td>
          </tr>
          <tr>
            <td width="20">&nbsp;</td>
            <td width="520" style="text-align:left; line-height:24px; font-size:14px;"><font face="Arial, sans-serif" color="#222222">
          Empresa: <b>${empresa}</b>
          <br>
          CNPJ: <b>${cnpj}</b>
          <br>
          Nome: <b>${nome}</b>
         <br>
          E-mail: <b>${email}</b>
          <br>
         Telefone: <b>${telefone}</b>
          <br>
          status: <b>${statusUser}</b>
          <br>
          Cadastrado por: <b>${cadastradoPor}</b>
				</font></td>
            <td width="20">&nbsp;</td>
          </tr>
          <tr>
            <td width="20">&nbsp;</td>
            <td width="520"></td>
            <td width="20">&nbsp;</td>
          </tr>
        </tbody>
      </table></td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560">
		
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560" style="text-align:center; line-height:22px; font-size:16px;">
<font face="Arial, sans-serif" color="#222222">
Nossa verificação é realizada de <strong>segunda a sexta-feira, das 09h às 18h</strong>.
<br><br>
Caso tenha qualquer dúvida, nossa equipe está à disposição.
</font>
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
  </tbody>
</table>

		
		</td>
	</tr>
	<tr>
		<td>
			<a href="https://londrisoft.com.br/?utm_source=email_workspace&utm_medium=rodape_email&utm_campaign=workspace" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/londrisoft.png" width="465" height="80" border="0" alt="Londrisoft" style="display:block;"></a></td>
		<td>
			<a href="https://www.facebook.com/londrisoft" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/facebook.png" width="35" height="80" border="0" alt="Facebook" style="display:block;"></a></td>
		<td>
			<a href="https://www.instagram.com/londrisoft/" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/instagram.png" width="35" height="80" border="0" alt="Instagram" style="display:block;"></a></td>
		<td>
			<a href="https://br.linkedin.com/company/londrisoft" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/linkedin.png" width="35" height="80" border="0" alt="LinkedIn" style="display:block;"></a></td>
		<td>
			<a href="https://www.youtube.com/channel/UCoQWBxPXIDBgubbkb066u0Q" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/youtube.png" width="35" height="80" border="0" alt="YouTube" style="display:block;"></a></td>
		<td>
			<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_09.png" width="35" height="80" alt="" style="display:block;"></td>
	</tr>
</table>
</center>
</body>
</html>`

  return applyHtmlCodeInEspecialCharacter(html)
}

export const getEmailUserLocalWithOpenAccount = (
  name: string,
  empresa: string,
  cnpj: string,
  login: string,
  link: string,
  local: boolean
): string => {
  const html = `
  <html>
<head>
<title>Gestor - 1º Acesso</title>
<meta charset="utf-8">
</head>
<body bgcolor="#FFFFFF" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0"><center>
<table id="Tabela_01" width="640" height="auto" border="0" cellpadding="0" cellspacing="0" bgcolor="#F7F7F7">
	<tr>
		<td colspan="6">
			<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_01.png" width="640" height="80" alt="Londrisoft" style="display:block;"></td>
	</tr>
	<tr>
		<td colspan="6">
			<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_produto.png" width="640" height="190" alt="Gestor - 1º Acesso" style="display:block;"></td>
	</tr>
	<tr>
		<td width="640" height="auto" colspan="6">
		
<table width="640" border="0">
  <tbody>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560" style="text-align:center; line-height:20px; font-size:14px;">
<font face="Arial, sans-serif" color="#1e57bd" size="+2"><strong>Bem-vindo(a), ${name}!</strong></font>		
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560">&nbsp;</td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560" style="text-align:center; line-height:22px; font-size:16px;">
      ${
        local
          ? '<font face="Arial, sans-serif" color="#222222">Seu acesso está liberado.<br><br>'
          : ' '
      }
		
	Para a sua segurança, orientamos a troca da senha<br> logo no primeiro acesso <a href="${link}" target="_blank" style="text-decoration:none;color:#1e57bd"><strong>clicando aqui</strong></a>.<br><br>
	Seguem abaixo os <strong>dados para seu acesso:</strong></font>
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560">&nbsp;</td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560"><table width="560" border="0" bgcolor="#e5e5e5">
        <tbody>
          <tr>
            <td width="20">&nbsp;</td>
            <td width="520"></td>
            <td width="20">&nbsp;</td>
          </tr>
          <tr>
            <td width="20">&nbsp;</td>
            <td width="520" style="text-align:left; line-height:24px; font-size:14px;"><font face="Arial, sans-serif" color="#222222">
          Empresa: <b>${empresa}</b>
          <br>
          CNPJ: <b>${cnpj}</b>
          <br>
          Login: <b>${login}</b>
 			</font></td>
            <td width="20">&nbsp;</td>
          </tr>
          <tr>
            <td width="20">&nbsp;</td>
            <td width="520"></td>
            <td width="20">&nbsp;</td>
          </tr>
        </tbody>
      </table></td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560">
		
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
    <td width="40">&nbsp;</td>
    <td width="560">
      <table width="560" border="0" bgcolor="#F7F7F7">
        <tbody>
          <tr>
            <td width="110" height="60">&nbsp;</td>
            <td
              width="300"
              height="60"
              style="
                text-align: center;
                line-height: 24px;
                font-size: 18px;
                cursor: 'pointer';
              "
              bgcolor="#1e57bd"
              onclick="window.open('${link}', '_blank')"
            >
              <font face="Arial, sans-serif" color="#222222">
                <strong
                  ><a
                    href="${link}"
                    target="_blank"
                    style="text-decoration: none; color: #ffffff"
                    >TROCAR SENHA</a
                  ></strong
                ></font
              >
            </td>
            <td width="110" height="60">&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </td>
    <td width="40">&nbsp;</td>
  </tr>
	  <tr>
      <td width="40">&nbsp;</td>
		  <td width="560" style="text-align:center; line-height:20px; font-size:14px;"><font face="Arial, sans-serif" color="#222222">Caso tenha qualquer dúvida, nossa equipe está à disposição.</font></td>
      <td width="40">&nbsp;</td>
    </tr>
  </tbody>
</table>	
	</td>
	</tr>
	<tr>
		<td>
			<a href="https://londrisoft.com.br/?utm_source=email_workspace&utm_medium=rodape_email&utm_campaign=workspace" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/londrisoft.png" width="465" height="80" border="0" alt="Londrisoft" style="display:block;"></a></td>
		<td>
			<a href="https://www.facebook.com/londrisoft" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/facebook.png" width="35" height="80" border="0" alt="Facebook" style="display:block;"></a></td>
		<td>
			<a href="https://www.instagram.com/londrisoft/" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/instagram.png" width="35" height="80" border="0" alt="Instagram" style="display:block;"></a></td>
		<td>
			<a href="https://br.linkedin.com/company/londrisoft" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/linkedin.png" width="35" height="80" border="0" alt="LinkedIn" style="display:block;"></a></td>
		<td>
			<a href="https://www.youtube.com/channel/UCoQWBxPXIDBgubbkb066u0Q" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/youtube.png" width="35" height="80" border="0" alt="YouTube" style="display:block;"></a></td>
		<td>
			<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_09.png" width="35" height="80" alt="" style="display:block;"></td>
	</tr>
</table>
</center>
</body>
</html>`

  return applyHtmlCodeInEspecialCharacter(html)
}

export const rememberPassword = (user: string, link: string) => {
  const html = `
  <html>
<head>
<title>Redefinição de Senha</title>
<meta charset="utf-8">
</head>
<body bgcolor="#FFFFFF" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0"><center>
<table id="Tabela_01" width="640" height="auto" border="0" cellpadding="0" cellspacing="0" bgcolor="#F7F7F7">
	<tr>
		<td colspan="6">
			<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_01.png" width="640" height="80" alt="Londrisoft" style="display:block;"></td>
	</tr>
	<tr>
		<td colspan="6">
			<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_redefinicao-senha.png" width="640" height="190" alt="Novo Cadastro" style="display:block;"></td>
	</tr>
	<tr>
		<td width="640" height="auto" colspan="6">
		
<table width="640" border="0">
  <tbody>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560" style="text-align:center; line-height:20px; font-size:14px;">
<font face="Arial, sans-serif" color="#1e57bd" size="+2"><strong>Olá, ${user}!</strong></font>		
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560">&nbsp;</td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560" style="text-align:center; line-height:22px; font-size:16px;">
<font face="Arial, sans-serif" color="#222222">Esqueceu a sua senha? Não tem problema!<br>
Clique no botão abaixo para <strong>criar uma nova senha</strong>:
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560">&nbsp;</td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560"><table width="560" border="0" bgcolor="#F7F7F7">
        <tbody>
          <tr>
            <td width="110" height="60">&nbsp;</td>
            <td width="300" height="60" style="text-align:center; line-height:24px; font-size:18px;" bgcolor="#1e57bd"><font face="Arial, sans-serif" color="#222222">	
			<strong><a href="${link}" target="_blank" style="text-decoration:none;color:#FFFFFF">CRIAR UMA NOVA SENHA</a></strong></font></td>	
            <td width="110" height="60">&nbsp;</td>
          </tr>
        </tbody>
      </table></td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560">
		
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
    <tr>
      <td width="40">&nbsp;</td>
      <td width="560" style="text-align:center; line-height:22px; font-size:16px;">
<font face="Arial, sans-serif" color="#222222">
          Caso não tenha solicitado a alteração da senha, por favor,<br>
          desconsidere esta mensagem e sua senha atual será mantida.
</font>
	  </td>
      <td width="40">&nbsp;</td>
    </tr>
  </tbody>
</table>

		
		</td>
	</tr>
	<tr>
		<td>
			<a href="https://londrisoft.com.br/?utm_source=email_workspace&utm_medium=rodape_email&utm_campaign=workspace" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/londrisoft.png" width="465" height="80" border="0" alt="Londrisoft" style="display:block;"></a></td>
		<td>
			<a href="https://www.facebook.com/londrisoft" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/facebook.png" width="35" height="80" border="0" alt="Facebook" style="display:block;"></a></td>
		<td>
			<a href="https://www.instagram.com/londrisoft/" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/instagram.png" width="35" height="80" border="0" alt="Instagram" style="display:block;"></a></td>
		<td>
			<a href="https://br.linkedin.com/company/londrisoft" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/linkedin.png" width="35" height="80" border="0" alt="LinkedIn" style="display:block;"></a></td>
		<td>
			<a href="https://www.youtube.com/channel/UCoQWBxPXIDBgubbkb066u0Q" target="_blank">
				<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/youtube.png" width="35" height="80" border="0" alt="YouTube" style="display:block;"></a></td>
		<td>
			<img src="http://www.londrisoftservices.com.br/imagens/workspace/emails/2021/images/workspace_emails_09.png" width="35" height="80" alt="" style="display:block;"></td>
	</tr>
</table>
</center>
</body>
</html>`

  return applyHtmlCodeInEspecialCharacter(html)
}
