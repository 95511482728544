import { Box, Center, Divider, Flex, Input, Text } from '@chakra-ui/react'
import { setgid } from 'process'
import { FormInput } from 'src/components/FormInput'
import { useSession } from 'src/contexts'
import { formatting } from 'src/utilities/formattingToPattern'
import { TabelaPlansApp } from './components/TabelaPlansApps'
import { ReactComponent as UserMyService } from 'src/assets/icons/user-my-services.svg'
import { ReactComponent as IntegrationsMyService } from 'src/assets/icons/integrations-my-services.svg'
import { ReactComponent as StatusMyService } from 'src/assets/icons/status-my-services.svg'
import { AboutAcoount } from './components/Data-about-account'
import { useUsers } from 'src/screens/Users/hooks'

export const Plan = () => {
  const { session } = useSession()
  const { users } = useUsers()

  const plansGestor = [
    { id: 1, description: 'Plano Avançado' },
    { id: 2, description: 'Plano Intermediário' },
    { id: 3, description: 'Plano Básico' },
    { id: 4, description: 'Plano NF-e' },
  ]

  const plansUnique = [
    { id: 0, description: 'Plano Premium' },
    { id: 1, description: 'Plano Plus' },
    { id: 2, description: 'Plano Light' },
  ]

  const plans = session.origin === 'Gestor' ? plansGestor : plansUnique

  const appsAtivos = session.plan.aplicativos
    .split('|')
    .filter((x) => x.length > 1)

  const appsExcludes = ['', null, undefined]

  const countAppsWithUsersActive = users.filter(
    (u: any) => u.status === true && !appsExcludes.includes(u.aplicativos)
  )

  const appsSupport: any[] = []
  const appsUserActives = countAppsWithUsersActive.map((app: any) => {
    return app.aplicativos.split('|').length === 2
      ? app.aplicativos.split('|')[1]
      : app.aplicativos.split('|').map((apps: any) => {
          if (apps !== '') return appsSupport.push(apps)
          return ''
        })
  })

  appsSupport.map((item: any) => appsUserActives.push(item))

  const usersWithAccessActive = appsUserActives.filter((item: any) => {
    return typeof item === 'string' && appsAtivos.includes(item)
  })

  const data = {
    financial: session.plan?.indfinanceiro === '0' ? 'Normal' : 'Pendente',
    users: usersWithAccessActive.length,
    plan:
      session.origin === 'Gestor'
        ? plansGestor.find((plan) => plan.id === session.plan.planoGestorId)
        : plansUnique.find((plan) => plan.id === session.plan.planoUniqueId),
    integrations: session.plan.aplicativos
      .split('|')
      .filter((app: string) => app.length !== 0)
      .filter(
        (integration) => integration !== 'GESTOR' && integration !== 'UNIQUE'
      ),
  }

  return (
    <Flex
      flexDir="column"
      h="full"
      overflowY={'auto'}
      w="full"
      css={{
        '&::-webkit-scrollbar': {
          background: '#ddd',
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#A1A1AA',
          borderRadius: '24px',
        },
        thead: {
          position: 'sticky',
          top: 0,
          zIndex: 2,
        },
      }}
    >
      <AboutAcoount
        financial={data.financial}
        integrations={data.integrations}
        plan={data.plan}
        users={data.users}
      />
      <TabelaPlansApp session={session} />
    </Flex>
  )
}

// const PlanCard = ({
//   id,
//   description,
//   hasSelect,
//   openLink,
// }: {
//   id: number
//   description: string
//   hasSelect: boolean
//   openLink?: () => void
// }) => {
//   return (
//     <Center
//       onClick={openLink}
//       cursor="pointer"
//       h="100px"
//       maxW="250px"
//       w="full"
//       borderRadius="4px"
//       opacity={hasSelect ? 1 : 0.5}
//       __css={{
//         background:
//           'radial-gradient(100.49% 100.49% at 50% 20.59%, #1155BB 0%, #3BB2EE 100%)',
//         boxShadow:
//           '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
//       }}
//     >
//       <Text
//         fontWeight="600"
//         color="high.pure"
//         textAlign="center"
//         fontSize={{ base: 'sm' }}
//       >
//         {description}
//       </Text>
//     </Center>
//   )
// }
