import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useToast,
} from '@chakra-ui/react'
import { CopySimple } from 'phosphor-react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useSession } from 'src/contexts'
import { formatting } from 'src/utilities/formattingToPattern'

export const SideButtonCompany = () => {
  const toast = useToast()
  const { session } = useSession()

  const copy = () => {
    toast({
      title: 'Copiado com sucesso',
      status: 'success',
      duration: 2000,
      isClosable: true,
    })
  }

  const hasManyCompany = false

  if (!hasManyCompany) {
    return (
      <Button
        flexDir="column"
        minH="75px"
        maxW={{ base: 'full', lg: '204px' }}
        mx={2}
        textAlign="start"
        variant="ghost"
        borderRadius="4px"
        cursor="inherit"
        _hover={{ backgroundColor: 'transparent' }}
        _active={{ backgroundColor: 'transparent' }}
        _focus={{ backgroundColor: 'transparent' }}
      >
        <Text mr="auto" fontSize="12px" whiteSpace="break-spaces">
          {session.company.name}
        </Text>
        <CopyToClipboard
          text={session.company.document}
          options={{ message: 'Copiado com sucesso' }}
          onCopy={() => {
            toast({
              title: 'Copiado com sucesso',
              status: 'success',
              duration: 2000,
              isClosable: true,
            })
          }}
        >
          <Flex w="100%" alignItems="center" mt="10px">
            <Text
              title="Copiar CNPJ"
              mr="5px"
              fontSize="12px"
              fontWeight="normal"
              color="low.light"
            >
              {formatting({
                value: session.company.document,
                pattern: 'document',
              })}
            </Text>
            <CopySimple size={20} color="#ababab" weight="fill" />
          </Flex>
        </CopyToClipboard>
      </Button>
    )
  }

  return (
    <>
      <Popover placement="auto-start">
        <PopoverTrigger>
          <Button
            flexDir="column"
            minH="75px"
            maxW="204px"
            mx={2}
            textAlign="start"
            variant="ghost"
            borderRadius="4px"
            _hover={{ backgroundColor: '#1155bb17' }}
            _active={{ backgroundColor: '#1155bb17' }}
            _focus={{ backgroundColor: '#1155bb17' }}
          >
            <Text mr="auto" fontSize="13px" whiteSpace="break-spaces">
              {session.company.name}
            </Text>
            <Text
              mr="auto"
              mt="10px"
              fontSize="12px"
              fontWeight="normal"
              color="low.light"
            >
              {formatting({
                value: session.company.document,
                pattern: 'document',
              })}
            </Text>
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />

          <PopoverHeader>Selecione a Empresa</PopoverHeader>
          <PopoverBody>
            <Flex h="300px" overflowY="auto" gap={2} flexDir="column" p={1}>
              <Button h="40px">company name</Button>
              <Button h="40px">company name</Button>
              <Button h="40px">company name</Button>
              <Button h="40px">company name</Button>
              <Button h="40px">company name</Button>
              <Button h="40px">company name</Button>
              <Button h="40px">company name</Button>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  )
}
