export interface SettingURLInterface {
  name: string
  url: string
  nsServers: string[]
}

const isAlpha =
  window.location.origin.includes('alpha') ||
  window.location.origin.includes('localhost')

export const settingURL: SettingURLInterface[] = [
  {
    name: 'vivo',
    url: isAlpha
      ? 'https://alfa-api.serverlondrisoft.com'
      : 'https://api.serverlondrisoft.com',
    nsServers: [
      'http://appgestor.serverlondrisoft.com/software/html5.html',
      'http://appgestor1.serverlondrisoft.com/software/html5.html',
      'http://appgestor3.serverlondrisoft.com/software/html5.html',
    ],
  },
  {
    name: 'embratel',
    url: isAlpha
      ? 'https://alfa-api1.serverlondrisoft.com'
      : 'https://api1.serverlondrisoft.com',
    nsServers: [
      'http://appgestor10.serverlondrisoft.com/software/html5.html',
      'http://appgestor21.serverlondrisoft.com/software/html5.html',
      'http://appgestor30.serverlondrisoft.com/software/html5.html',
    ],
  },
  {
    name: 'sercomtel',
    url: isAlpha
      ? 'https://alfa-api2.serverlondrisoft.com'
      : 'https://api2.serverlondrisoft.com',
    nsServers: [
      'http://appgestor100.serverlondrisoft.com/software/html5.html',
      'http://appgestor101.serverlondrisoft.com/software/html5.html',
      'http://appgestor102.serverlondrisoft.com/software/html5.html',
    ],
  },
]
