import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSession } from 'src/contexts'
import { getAplicativosByCompanyDocument } from 'src/services/api'
import { StoreAppProps, getStoreAppByTag } from 'src/utilities/storeApps'
import { AppDetails } from './AppDetails'
import { App } from './Integrations'

export const Store = () => {
  const { apptag } = useParams()
  const { session } = useSession()

  const location = useLocation()

  const navigate = useNavigate()

  const [storeApp, setStoreApp] = useState<StoreAppProps | undefined>(undefined)
  const [storeAppIsAtivo, setStoreAppIsAtivo] = useState(false)

  function handleClickTab(index: number) {
    if (index === 0)
      navigate(
        apptag ? `integrations/${apptag?.toLowerCase()}` : 'integrations'
      )
    if (index === 1) navigate('tools')
  }

  useEffect(() => {
    const storeAPPByTag = getStoreAppByTag(apptag)

    setStoreApp(storeAPPByTag)
  }, [apptag])

  const getAnalyticsBIAppIsAtivo = useCallback(async () => {
    const { data } = await getAplicativosByCompanyDocument(
      session.company.document,
      session.origin
    )
    const app: App | undefined = data.find((x: App) => x.tag === storeApp?.tag)
    if (!!app) setStoreAppIsAtivo(app?.indAtivar === 'S')
  }, [storeApp?.tag, session.company.document])

  useEffect(() => {
    if (!!storeApp) getAnalyticsBIAppIsAtivo()
  }, [storeApp, getAnalyticsBIAppIsAtivo])

  const screen = !!storeApp ? (
    <AppDetails
      tag={storeApp.tag}
      appName={storeApp.appName}
      origin={storeApp.origin}
      nivel={storeApp.nivel}
      isAnalyticsPro={storeApp.isAnalyticsPro}
      tituloPrincipal={storeApp.tituloPrincipal}
      tituloFuncionalidades={storeApp.tituloFuncionalidades}
      tituloMidias={storeApp.tituloMidias}
      descricao={storeApp.descricao}
      listaFuncionalidades={storeApp.listaFuncionalidades}
      hasMidias={storeApp.hasMidias}
      urlVideo={storeApp.urlVideo}
      urlImages={storeApp.urlImages}
      isAtivo={storeAppIsAtivo}
      isAdmin={session.perfilWs === 0}
      handleClickVoltar={() => navigate('integrations')}
    />
  ) : (
    <>
      <Helmet>
        <title>Workspace | Aplicativos</title>
      </Helmet>
      <Tabs
        w="full"
        bg="high.pure"
        borderRadius="4px"
        boxShadow={
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }
        index={location.pathname === '/store/integrations' ? 0 : 1}
        overflowY={'auto'}
        css={{
          '&::-webkit-scrollbar': {
            background: '#ddd',
            width: '6px',
            height: '6px',
          },
          '&::-webkit-scrollbar-track': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#A1A1AA',
            borderRadius: '24px',
          },
          thead: {
            position: 'sticky',
            top: 0,
            zIndex: 2,
          },
        }}
      >
        <TabList>
          <Tab title="Integrações" onClick={() => handleClickTab(0)}>
            <Text fontSize={'14px'}>Integrações</Text>
          </Tab>
          <Tab title="Ferramentas" onClick={() => handleClickTab(1)}>
            <Text fontSize={'14px'}>Ferramentas</Text>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel id="integrações">
            <Outlet />
          </TabPanel>
          <TabPanel id="ferramentas">
            <Outlet />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )

  return <>{screen}</>
}
