const ASSETS_URL = process.env.REACT_APP_ASSETS_URL

export interface StoreAppProps {
  tag: string
  appName: string
  origin: 'GESTOR' | 'UNIQUE'
  nivel: 'user'
  isAnalyticsPro: boolean
  tituloPrincipal: string
  descricao: string
  tituloFuncionalidades: string
  listaFuncionalidades: string[]
  hasMidias: boolean
  tituloMidias: string
  urlVideo: string
  urlImages: string[]
}

export const appsCadastrados = [
  'SIMPLAZ',
  'GESTOR',
  'UNIQUE',
  'MercadoLivre',
  'INTELVENDAS',
  'INTELADM',
  'ANALYTICSBI',
  'IMPXLS',
  'QUOD',
]

const AnalyticsBIApps: StoreAppProps[] = [
  {
    tag: 'ANALYTICSBI',
    appName: 'Analytics BI',
    origin: 'GESTOR',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal:
      'Confira as principais métricas de vendas da sua empresa de forma gratuita e rápida!',
    descricao: `O Analytics foi pensado para permitir uma análise fácil e ágil de métricas de vendas a partir da combinação de diferentes filtros. E o melhor, é grátis!`,
    tituloFuncionalidades: 'Alguns filtros presentes no Analytics:',
    listaFuncionalidades: [
      'Vendas por mês',
      'Vendas por prazo de pagamento',
      'vendas por vendedor',
    ],
    hasMidias: false,
    tituloMidias: '',
    urlVideo: '',
    urlImages: [],
  },
  {
    tag: 'INTELVENDAS',
    origin: 'GESTOR',
    appName: 'Analytics PRO - Inteligência de Vendas',
    nivel: 'user',
    isAnalyticsPro: true,
    tituloPrincipal: 'Gestão orientada por dados:',
    descricao: `Saiba de forma simples os resultados da sua empresa com base em dados da operação.
                Entenda o desempenho da sua equipe e tenha clareza sobre os principais indicadores e métricas do seu negócio.`,
    tituloFuncionalidades: 'Alguns benefícios:',
    listaFuncionalidades: [
      'Decisões mais rápidas',
      'Redução de erros',
      'Identifique os pontos de melhorias em suas operações',
      'Tomada de decisão com fonte seguras',
      'Integrado com o sistema Gestor',
    ],
    hasMidias: true,
    tituloMidias: 'Conheça o Inteligência de Vendas',
    urlVideo: 'https://www.youtube.com/embed/9GVp6GYxnkE',
    urlImages: [
      `${ASSETS_URL}analytics-pro/gestor/int-vendas/tela-clientes_1210x765.png`,
      `${ASSETS_URL}analytics-pro/gestor/int-vendas/tela-geral_1210x765.png`,
      `${ASSETS_URL}analytics-pro/gestor/int-vendas/tela-indicadores_1210x765.png`,
      `${ASSETS_URL}analytics-pro/gestor/int-vendas/tela-vendedores_1210x765.png`,
    ],
  },
  {
    tag: 'MercadoLivre',
    origin: 'GESTOR',
    appName: 'Mercado Livre',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: 'Importe pedidos do maior marketplace da América Latina!',
    descricao: `Integre seu sistema Gestor ao Mercado Livre e realize a importação de pedidos de forma automatizada!
                Com essa integração você garante o controle dos pedidos recebidos no marketplace Mercado Livre e realiza a geração de vendas emissão de notas no sistema Gestor, garantindo uma gestão otimizada de todas as suas vendas online.`,
    tituloFuncionalidades: 'Principais Recursos:',
    listaFuncionalidades: [
      'Importação de Pedidos para o sistema Gestor',
      'Notificação de pedidos pendentes de importação ( Dentro do sistema Gestor)',
      'Logo Mercado Livre nas vendas advindas do marketplace',
      'Link do pedido Mercado Livre atrelado a venda',
    ],
    hasMidias: false,
    tituloMidias: '',
    urlVideo: '',
    urlImages: [],
  },
  {
    tag: 'SIMPLAZ',
    origin: 'GESTOR',
    appName: 'Simplaz',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal:
      'A solução para a gestão de documentos fiscais do seu negócio.',
    descricao: `Com o Simplaz, você consegue realizar toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e  e XML em um só lugar.`,
    tituloFuncionalidades: 'Principais Recursos:',
    listaFuncionalidades: [
      'Visualização de notas',
      'Compartilhamento via WhatsApp',
      'Agendamento de envio de XML para envio mensal',
      'Download imediato de XML ou PDF',
      'Envio em lote por e-mail',
    ],
    hasMidias: false,
    tituloMidias: '',
    urlVideo: '',
    urlImages: [],
  },
  {
    tag: 'SP_P2',
    origin: 'GESTOR',
    appName: 'Simplaz',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal:
      'A solução para a gestão de documentos fiscais do seu negócio.',
    descricao: `Com o Simplaz, você consegue realizar toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e  e XML em um só lugar.`,
    tituloFuncionalidades: 'Principais Recursos:',
    listaFuncionalidades: [
      'Visualização de notas',
      'Compartilhamento via WhatsApp',
      'Agendamento de envio de XML para envio mensal',
      'Download imediato de XML ou PDF',
      'Envio em lote por e-mail',
    ],
    hasMidias: false,
    tituloMidias: '',
    urlVideo: '',
    urlImages: [],
  },
  {
    tag: 'SP_P3',
    origin: 'GESTOR',
    appName: 'Simplaz',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal:
      'A solução para a gestão de documentos fiscais do seu negócio.',
    descricao: `Com o Simplaz, você consegue realizar toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e  e XML em um só lugar.`,
    tituloFuncionalidades: 'Principais Recursos:',
    listaFuncionalidades: [
      'Visualização de notas',
      'Compartilhamento via WhatsApp',
      'Agendamento de envio de XML para envio mensal',
      'Download imediato de XML ou PDF',
      'Envio em lote por e-mail',
    ],
    hasMidias: false,
    tituloMidias: '',
    urlVideo: '',
    urlImages: [],
  },
  {
    tag: 'SP_P4',
    origin: 'GESTOR',
    appName: 'Simplaz',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal:
      'A solução para a gestão de documentos fiscais do seu negócio.',
    descricao: `Com o Simplaz, você consegue realizar toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e  e XML em um só lugar.`,
    tituloFuncionalidades: 'Principais Recursos:',
    listaFuncionalidades: [
      'Visualização de notas',
      'Compartilhamento via WhatsApp',
      'Agendamento de envio de XML para envio mensal',
      'Download imediato de XML ou PDF',
      'Envio em lote por e-mail',
    ],
    hasMidias: false,
    tituloMidias: '',
    urlVideo: '',
    urlImages: [],
  },
  {
    tag: 'SP_CTB_P5',
    origin: 'GESTOR',
    appName: 'Simplaz',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal:
      'A solução para a gestão de documentos fiscais do seu negócio.',
    descricao: `Com o Simplaz, você consegue realizar toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e  e XML em um só lugar.`,
    tituloFuncionalidades: 'Principais Recursos:',
    listaFuncionalidades: [
      'Visualização de notas',
      'Compartilhamento via WhatsApp',
      'Agendamento de envio de XML para envio mensal',
      'Download imediato de XML ou PDF',
      'Envio em lote por e-mail',
    ],
    hasMidias: false,
    tituloMidias: '',
    urlVideo: '',
    urlImages: [],
  },
  {
    tag: 'SP_CTB_P6',
    origin: 'GESTOR',
    appName: 'Simplaz',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal:
      'A solução para a gestão de documentos fiscais do seu negócio.',
    descricao: `Com o Simplaz, você consegue realizar toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e  e XML em um só lugar.`,
    tituloFuncionalidades: 'Principais Recursos:',
    listaFuncionalidades: [
      'Visualização de notas',
      'Compartilhamento via WhatsApp',
      'Agendamento de envio de XML para envio mensal',
      'Download imediato de XML ou PDF',
      'Envio em lote por e-mail',
    ],
    hasMidias: false,
    tituloMidias: '',
    urlVideo: '',
    urlImages: [],
  },
  {
    tag: 'SP_CTB_P7',
    origin: 'GESTOR',
    appName: 'Simplaz',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal:
      'A solução para a gestão de documentos fiscais do seu negócio.',
    descricao: `Com o Simplaz, você consegue realizar toda a gestão de documentos fiscais de sua empresa como: NF-e, NFC-e  e XML em um só lugar.`,
    tituloFuncionalidades: 'Principais Recursos:',
    listaFuncionalidades: [
      'Visualização de notas',
      'Compartilhamento via WhatsApp',
      'Agendamento de envio de XML para envio mensal',
      'Download imediato de XML ou PDF',
      'Envio em lote por e-mail',
    ],
    hasMidias: false,
    tituloMidias: '',
    urlVideo: '',
    urlImages: [],
  },
  {
    tag: 'INTELADM',
    origin: 'UNIQUE',
    appName: 'Analytics PRO - Inteligência Administrativa',
    nivel: 'user',
    isAnalyticsPro: true,
    tituloPrincipal:
      'Visualize os resultados do seu escritório de contabilidade',
    descricao: `Compreenda melhor o perfil de seus clientes com o nosso aplicativo.
                Realize uma análise segmentada com diferentes gráficos e indicadores como: 
                informações dos sócios e clientes, valor de honorários, regimes de tributação,
                 central de processamento e muito mais.`,
    tituloFuncionalidades: 'Alguns benefícios:',
    listaFuncionalidades: [
      'Análise segmentada de seus clientes',
      'Gestão de carteira',
      'Central de processamento',
      'Precificação do honorário e serviços prestados',
      'Integrado com o sistema Unique',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: 'https://www.youtube.com/embed/Do2DLNwS8zA',
    urlImages: [
      `${ASSETS_URL}analytics-pro/unique/int-admin/unique-tela-carteira_1210x765.png`,
      `${ASSETS_URL}analytics-pro/unique/int-admin/unique-tela-contasreceber_1210x765.png`,
      `${ASSETS_URL}analytics-pro/unique/int-admin/unique-tela-demandas_1210x765.png`,
      `${ASSETS_URL}analytics-pro/unique/int-admin/unique-tela-geral_1210x765.png`,
    ],
  },
  {
    tag: 'IMPXLS',
    origin: 'GESTOR',
    appName: 'Importa Fácil',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: 'Ganhe mais tempo e flexibilidade em seu negócio!',
    descricao: `Use planilhas externas para importar pedidos para o sistema Gestor.
    Você também pode organizar as suas informações coma nossa planilha padrão. Com ela disponibilizamos as referências de cabeçalhos e basta você preencher com os seus dados. Formatos aceitos: XLS, XLSX ou CSV.`,
    tituloFuncionalidades: '',
    listaFuncionalidades: [],
    hasMidias: true,
    tituloMidias: 'Conheça o Importa Fácil:',
    urlVideo: `${ASSETS_URL}workspace/xls/images/thumb_home.png`,
    urlImages: [
      `${ASSETS_URL}workspace/xls/images/carregue.png`,
      `${ASSETS_URL}workspace/xls/images/selecione.png`,
      `${ASSETS_URL}workspace/xls/images/correlacao.png`,
      `${ASSETS_URL}workspace/xls/images/finalize.png`,
    ],
  },
  {
    tag: 'QUOD',
    origin: 'GESTOR' || 'UNIQUE',
    appName: 'Quod',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: 'Análise de crédito com confiança.',
    descricao: `Faça de forma ágil a análise crédito, para melhorar suas vendas 
    e diminuir a inadimplência do seu negócio.`,
    tituloFuncionalidades: 'Alguns benefícios:',
    listaFuncionalidades: [
      'Acessar a nota crédito',
      'Descobrir o risco de pagamento',
      'Verificar o score do seu cliente',
      'Pendências financeiras.',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'CL_CTD_P1',
    origin: 'UNIQUE',
    appName: 'Clube contador',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Criado para contador que querem:',
    listaFuncionalidades: [
      'Criado para contador que querem: Ampliar as oportunidades de negócio do seu escritório',
      'Ter mais lucratividade',
      'Acelerar a transformação digital',
      'Ter mais produtividade',
      'Ser um contador consultivo',
      'Facilitar a rotina dos clientes',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'CL_CTD_P2',
    origin: 'UNIQUE',
    appName: 'Clube contador',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Criado para contador que querem:',
    listaFuncionalidades: [
      'Criado para contador que querem: Ampliar as oportunidades de negócio do seu escritório',
      'Ter mais lucratividade',
      'Acelerar a transformação digital',
      'Ter mais produtividade',
      'Ser um contador consultivo',
      'Facilitar a rotina dos clientes',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'CL_CTD_P3',
    origin: 'UNIQUE',
    appName: 'Clube contador',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Criado para contador que querem:',
    listaFuncionalidades: [
      'Criado para contador que querem: Ampliar as oportunidades de negócio do seu escritório',
      'Ter mais lucratividade',
      'Acelerar a transformação digital',
      'Ter mais produtividade',
      'Ser um contador consultivo',
      'Facilitar a rotina dos clientes',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'CL_CTD_P4',
    origin: 'UNIQUE',
    appName: 'Clube contador',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Criado para contador que querem:',
    listaFuncionalidades: [
      'Criado para contador que querem: Ampliar as oportunidades de negócio do seu escritório',
      'Ter mais lucratividade',
      'Acelerar a transformação digital',
      'Ter mais produtividade',
      'Ser um contador consultivo',
      'Facilitar a rotina dos clientes',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },

  {
    tag: 'CL_CTD_P5',
    origin: 'UNIQUE',
    appName: 'Clube contador',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Criado para contador que querem:',
    listaFuncionalidades: [
      'Criado para contador que querem: Ampliar as oportunidades de negócio do seu escritório',
      'Ter mais lucratividade',
      'Acelerar a transformação digital',
      'Ter mais produtividade',
      'Ser um contador consultivo',
      'Facilitar a rotina dos clientes',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },

  {
    tag: 'CL_CTD_P4',
    origin: 'UNIQUE',
    appName: 'Clube contador',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Criado para contador que querem:',
    listaFuncionalidades: [
      'Criado para contador que querem: Ampliar as oportunidades de negócio do seu escritório',
      'Ter mais lucratividade',
      'Acelerar a transformação digital',
      'Ter mais produtividade',
      'Ser um contador consultivo',
      'Facilitar a rotina dos clientes',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'GE_N1',
    origin: 'GESTOR',
    appName: 'Gestor',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Sobre o Gestor:',
    listaFuncionalidades: [
      'Principais características e funcionalidades: Movimentações do mês atual e de todo o período',
      'Gestão completa das vendas (ticket médio, lucro, quantidade, valor bruto, cancelamentos, descontos, cidades atendidas, etc)',
      'Rankings (clientes, vendas, produto, vendedores)',
      'Gráficos de evolução',
      'Gestão completa da equipe de vendas (vendas, cancelamentos, descontos',
      'Gestão completa de clientes (cadastros, cancelamentos, descontos)',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'GE_N2',
    origin: 'GESTOR',
    appName: 'Gestor',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Sobre o Gestor:',
    listaFuncionalidades: [
      'Principais características e funcionalidades: Movimentações do mês atual e de todo o período',
      'Gestão completa das vendas (ticket médio, lucro, quantidade, valor bruto, cancelamentos, descontos, cidades atendidas, etc)',
      'Rankings (clientes, vendas, produto, vendedores)',
      'Gráficos de evolução',
      'Gestão completa da equipe de vendas (vendas, cancelamentos, descontos',
      'Gestão completa de clientes (cadastros, cancelamentos, descontos)',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'GE_N3',
    origin: 'GESTOR',
    appName: 'Gestor',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Sobre o Gestor:',
    listaFuncionalidades: [
      'Principais características e funcionalidades: Movimentações do mês atual e de todo o período',
      'Gestão completa das vendas (ticket médio, lucro, quantidade, valor bruto, cancelamentos, descontos, cidades atendidas, etc)',
      'Rankings (clientes, vendas, produto, vendedores)',
      'Gráficos de evolução',
      'Gestão completa da equipe de vendas (vendas, cancelamentos, descontos',
      'Gestão completa de clientes (cadastros, cancelamentos, descontos)',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'GE_N4',
    origin: 'GESTOR',
    appName: 'Gestor',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Sobre o Gestor:',
    listaFuncionalidades: [
      'Principais características e funcionalidades: Movimentações do mês atual e de todo o período',
      'Gestão completa das vendas (ticket médio, lucro, quantidade, valor bruto, cancelamentos, descontos, cidades atendidas, etc)',
      'Rankings (clientes, vendas, produto, vendedores)',
      'Gráficos de evolução',
      'Gestão completa da equipe de vendas (vendas, cancelamentos, descontos',
      'Gestão completa de clientes (cadastros, cancelamentos, descontos)',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'GE_L1',
    origin: 'GESTOR',
    appName: 'Gestor',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Sobre o Gestor:',
    listaFuncionalidades: [
      'Principais características e funcionalidades: Movimentações do mês atual e de todo o período',
      'Gestão completa das vendas (ticket médio, lucro, quantidade, valor bruto, cancelamentos, descontos, cidades atendidas, etc)',
      'Rankings (clientes, vendas, produto, vendedores)',
      'Gráficos de evolução',
      'Gestão completa da equipe de vendas (vendas, cancelamentos, descontos',
      'Gestão completa de clientes (cadastros, cancelamentos, descontos)',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'GE_L2',
    origin: 'GESTOR',
    appName: 'Gestor',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Sobre o Gestor:',
    listaFuncionalidades: [
      'Principais características e funcionalidades: Movimentações do mês atual e de todo o período',
      'Gestão completa das vendas (ticket médio, lucro, quantidade, valor bruto, cancelamentos, descontos, cidades atendidas, etc)',
      'Rankings (clientes, vendas, produto, vendedores)',
      'Gráficos de evolução',
      'Gestão completa da equipe de vendas (vendas, cancelamentos, descontos',
      'Gestão completa de clientes (cadastros, cancelamentos, descontos)',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'GE_L3',
    origin: 'GESTOR',
    appName: 'Gestor',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Sobre o Gestor:',
    listaFuncionalidades: [
      'Principais características e funcionalidades: Movimentações do mês atual e de todo o período',
      'Gestão completa das vendas (ticket médio, lucro, quantidade, valor bruto, cancelamentos, descontos, cidades atendidas, etc)',
      'Rankings (clientes, vendas, produto, vendedores)',
      'Gráficos de evolução',
      'Gestão completa da equipe de vendas (vendas, cancelamentos, descontos',
      'Gestão completa de clientes (cadastros, cancelamentos, descontos)',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'GE_L4',
    origin: 'GESTOR',
    appName: 'Gestor',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Sobre o Gestor:',
    listaFuncionalidades: [
      'Principais características e funcionalidades: Movimentações do mês atual e de todo o período',
      'Gestão completa das vendas (ticket médio, lucro, quantidade, valor bruto, cancelamentos, descontos, cidades atendidas, etc)',
      'Rankings (clientes, vendas, produto, vendedores)',
      'Gráficos de evolução',
      'Gestão completa da equipe de vendas (vendas, cancelamentos, descontos',
      'Gestão completa de clientes (cadastros, cancelamentos, descontos)',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'UN_L1',
    origin: 'UNIQUE',
    appName: 'Unique',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Sobre o Unique',
    listaFuncionalidades: [
      'Contas a receber: Faturamento dos honorários, ticket médio, contas a receber, valor recebido, inadimplência',
      'Contas a pagar: Valor total, investimentos por categoria',
      'Carteira: Sócios por perfil, sócios por empresas, taxa de sobrevivência e empresas por regime, atividade e região',
      'Demandas: Quantidade de admissões e demissões, funcionários afastados e ativos, quantidade de folhas de pagamento, valor Total dos encargos e salários, valor totalde compras e vendas, quantidade de lançamentos na contabilidade e obrigações geradas.',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'UN_L2',
    origin: 'UNIQUE',
    appName: 'Unique',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Sobre o Unique',
    listaFuncionalidades: [
      'Contas a receber: Faturamento dos honorários, ticket médio, contas a receber, valor recebido, inadimplência',
      'Contas a pagar: Valor total, investimentos por categoria',
      'Carteira: Sócios por perfil, sócios por empresas, taxa de sobrevivência e empresas por regime, atividade e região',
      'Demandas: Quantidade de admissões e demissões, funcionários afastados e ativos, quantidade de folhas de pagamento, valor Total dos encargos e salários, valor totalde compras e vendas, quantidade de lançamentos na contabilidade e obrigações geradas.',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'UN_L3',
    origin: 'UNIQUE',
    appName: 'Unique',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Sobre o Unique',
    listaFuncionalidades: [
      'Contas a receber: Faturamento dos honorários, ticket médio, contas a receber, valor recebido, inadimplência',
      'Contas a pagar: Valor total, investimentos por categoria',
      'Carteira: Sócios por perfil, sócios por empresas, taxa de sobrevivência e empresas por regime, atividade e região',
      'Demandas: Quantidade de admissões e demissões, funcionários afastados e ativos, quantidade de folhas de pagamento, valor Total dos encargos e salários, valor totalde compras e vendas, quantidade de lançamentos na contabilidade e obrigações geradas.',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
  {
    tag: 'MOBV_',
    origin: 'GESTOR',
    appName: 'Mobile de vendas',
    nivel: 'user',
    isAnalyticsPro: false,
    tituloPrincipal: '',
    descricao: ``,
    tituloFuncionalidades: 'Sobre o Mobile de Vendas',
    listaFuncionalidades: [
      'Um aplicativo disponibilizado para sistema Android, nele é possível efetuar vendas para seus clientes de forma rápida e fácil. Ao efetuar vendas externas você sincroniza com seu sistema gestor, facilitando a comunicação de vendas externas.',
    ],
    hasMidias: true,
    tituloMidias: '',
    urlVideo: ``,
    urlImages: [],
  },
]

export function getStoreAppByTag(
  tag: string | undefined,
): StoreAppProps | undefined {
  if (tag)
    return AnalyticsBIApps.find(
      (app) => tag.toLocaleLowerCase() === app.tag.toLocaleLowerCase(),
    )
  else return undefined
}
