import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Session } from 'src/contexts/sessions'
import { useUsers } from 'src/screens/Users/hooks'
import { getAplicativosWithDescription } from 'src/services/api'
import { tagsAppsAndIcon } from './icons'
import { ReactComponent as ButtonDetails } from './icons/button-details.svg'

export interface TabelaPlansAppProps {
  session: Session
}

interface Apps {
  tag: string
  icon: Element
}

export const TabelaPlansApp = ({ session }: TabelaPlansAppProps) => {
  const { users } = useUsers()
  const navigate = useNavigate()

  const [apps, setApps] = useState<Apps[] | undefined>([])
  const [appsWithDescription, setAppsWithDescription] = useState<any[]>([])

  const appsAtivos = session.plan.aplicativos
    .split('|')
    .filter((x) => x.length > 1)

  function getQtdUserAtivoByApp(app: string) {
    return users.filter((u) => u.aplicativos && u.aplicativos.includes(app))
      .length
  }

  const appsInDataBase = async () => {
    const apps = await getAplicativosWithDescription()
    const icon = appsAtivos
      .filter((x) => x !== 'GESTOR' && x !== 'UNIQUE' && x !== 'PHARMALINK')
      .map((app) => {
        const appIcon = tagsAppsAndIcon.find((x) => x.tag.includes(app))
        if (appIcon) {
          return {
            tag: app,
            icon: appIcon.icon,
          }
        }
      })

    setAppsWithDescription(apps)
    setApps(icon as any[])
  }
  useEffect(() => {
    appsInDataBase()
  }, [])

  return (
    <Box>
      <Text
        marginTop={'37px'}
        fontFamily={'sora'}
        fontWeight={'600'}
        fontSize="16px"
        color="#303030"
      >
        Serviços e planos contratados
      </Text>
      <Flex
        overflowY={'auto'}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
      >
        {appsAtivos
          .filter((x) => x !== 'GESTOR' && x !== 'UNIQUE')
          .map((app, index) => {
            return (
              <Flex
                key={index}
                w="49%"
                h="76px"
                mt="15px"
                borderRadius="4px"
                border="1px solid #E0E0E0"
              >
                <Flex flexDir="column" w="80%" p="14px">
                  <Flex alignItems={'center'}>
                    {apps?.map((x) => {
                      if (x.tag === app) {
                        return x.icon
                      }
                    })}
                    {appsWithDescription.map((x) => {
                      if (x.tag === app) {
                        return (
                          <Text
                            fontSize={'14px'}
                            fontFamily={'Sora'}
                            fontWeight={'600'}
                            color={'#303030'}
                            marginLeft={'6px'}
                          >
                            {x.nome}
                          </Text>
                        )
                      }
                    })}
                  </Flex>

                  <Flex alignItems={'center'} mt="8px">
                    <Box
                      w="8px"
                      h="8px"
                      mr="5px"
                      bg="#109972"
                      borderRadius={'full'}
                    ></Box>
                    <Text
                      fontSize={'12px'}
                      fontFamily={'Sora'}
                      fontWeight={'400'}
                      color={'#303030'}
                    >
                      Usuários ativos:
                    </Text>
                    <Text
                      fontSize={'12px'}
                      fontFamily={'Sora'}
                      fontWeight={'700'}
                      color={'#303030'}
                      ml="5px"
                    >
                      {getQtdUserAtivoByApp(app)}
                    </Text>
                  </Flex>
                </Flex>
                <Flex alignItems={'center'}>
                  {!['GESTOR', 'UNIQUE'].some((x) => x === app) ? (
                    <Button
                      border="1px solid #1670D3"
                      borderRadius={'4px'}
                      w="137px"
                      h="40px"
                      bg="#fff"
                      mr="5px"
                      leftIcon={<ButtonDetails />}
                      onClick={() => navigate(`/store/integrations/${app}`)}
                    >
                      <Text
                        fontSize={'14px'}
                        fontFamily={'Sora'}
                        fontWeight={'600'}
                        color={'#1670D3'}
                      >
                        Gerenciar
                      </Text>
                    </Button>
                  ) : (
                    ''
                  )}
                </Flex>
              </Flex>
            )
          })}
      </Flex>
    </Box>
  )
}
