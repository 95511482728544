import { Flex, Image } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import AnalyticsFree from 'src/assets/users/creating/analyticsfree.svg'
import Analyticspro from 'src/assets/users/creating/analyticspro.svg'
import Certificado from 'src/assets/users/creating/certificado.svg'
import Contador from 'src/assets/users/creating/contador.svg'
import Gestor from 'src/assets/users/creating/gestor.svg'
import Importador from 'src/assets/users/creating/importador.svg'
import MercadoLivre from 'src/assets/users/creating/mercadolivre.svg'
import Mobile from 'src/assets/users/creating/mobile.svg'
import Quod from 'src/assets/users/creating/quod.svg'
import Simplaz from 'src/assets/users/creating/simplaz.svg'
import Unique from 'src/assets/users/creating/unique.svg'

interface IGetIconApp {
  appTag: string
}

interface IListApps {
  tag: string[]
  icon: string
  nameApp: string
}

const listApps = [
  {
    tag: [
      'SIMPLAZ',
      'SP_P2',
      'SP_P3',
      'SP_P4',
      'SP_CTB_P5',
      'SP_CTB_P6',
      'SP_CTB_P7',
    ],
    icon: Simplaz,
    nameApp: 'Simplaz',
  },
  {
    tag: [
      'GESTOR',
      'GE_L4',
      'GE_L3',
      'GE_L2',
      'GE_L1',
      'GE_N4',
      'GE_N3',
      'GE_N2',
      'GE_N1',
    ],
    icon: Gestor,
    nameApp: 'Gestor',
  },
  {
    tag: ['UNIQUE', 'UN_L0', 'UN_L1', 'UN_L2', 'UN_L3'],
    icon: Unique,
    nameApp: 'Unique',
  },
  {
    tag: ['CL_CTD_P1', 'CL_CTD_P2', 'CL_CTD_P3', 'CL_CTD_P4', 'CL_CTD_P5'],
    icon: Contador,
    nameApp: 'Clude do contador',
  },
  {
    tag: ['INTELVENDAS', 'INTELADM'],
    icon: Analyticspro,
    nameApp: 'Analytics Pro',
  },
  {
    tag: ['QUOD'],
    icon: Quod,
    nameApp: 'Quod',
  },
  {
    tag: ['IMPXLS'],
    icon: Importador,
    nameApp: 'Importa Fácil',
  },
  {
    tag: ['ANALYTICSBI'],
    icon: AnalyticsFree,
    nameApp: 'Analytics BI',
  },
  {
    tag: ['MOBV_'],
    icon: Mobile,
    nameApp: 'Mobile vendas',
  },
  {
    tag: ['MercadoLivre'],
    icon: MercadoLivre,
    nameApp: 'Mercado Livre',
  },
  {
    tag: ['CERTD'],
    icon: Certificado,
    nameApp: 'Certificado digital',
  },
]

export const GetIconApp = ({ appTag }: IGetIconApp) => {
  const [appTagSeleted, setAppTagSeleted] = useState({} as IListApps)

  useEffect(() => {
    listApps.map((app) => {
      if (app.tag.includes(appTag)) {
        setAppTagSeleted(app)
      }
    })
  }, [appTag])

  return (
    <Flex
      p="4px 15px 4px 16px"
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Flex alignItems={'center'}>
        <Image src={appTagSeleted.icon} w="25px" />
      </Flex>
    </Flex>
  )
}
