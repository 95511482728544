import { Box, Fade, Flex, Image, Link, Text } from '@chakra-ui/react'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSession } from 'src/contexts'
import Banner from '../../assets/home/static-home.png'
import { CardSponser } from './components/card-sponser'

export interface SponserInterface {
  aplicativo: string
  imagem: string
  tituloImagem: string
  descricao: string
  textoBotao: string
  linkBotao: string
}

export const Initial = () => {
  const { session } = useSession()
  const [sponser, setSponser] = useState<SponserInterface[]>()

  const getSettingsHomeSponsers = useCallback(async () => {
    const URL_ASSETS = process.env.REACT_APP_ASSETS_URL

    await axios
      .get(`${URL_ASSETS}workspace/initial/config-home/configuracoes-home.json`)
      .then((response) => {
        const originAccount =
          session.origin !== undefined ? session.origin.toLowerCase() : ''
        const data = response.data

        const sponserFilter = data.filter((item: any) => {
          if (item.aplicativo.toLowerCase() === originAccount) {
            return item
          } else {
            return item.aplicativo.toLowerCase() === 'outros'
          }
        })

        setSponser(sponserFilter.slice(0, 3))
      })
      .catch((e) => console.error(e))
  }, [])

  useEffect(() => {
    getSettingsHomeSponsers()
  }, [getSettingsHomeSponsers])

  return (
    <Fade in={true} style={{ width: '100%' }} delay={0.2}>
      <Helmet>
        <title>Início | Workspace</title>
      </Helmet>
      <Flex
        flexDir="column"
        position="relative"
        w="full"
        h="full"
        maxH="full"
        bg="#FBFBFB"
        p={{ md: '5', '2xl': '0px' }}
        // boxShadow={
        //   '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        // }
        borderRadius="4px"
        overflowY={{ md: 'auto', '2xl': 'hidden' }}
        overflowX={'hidden'}
        css={{
          '&::-webkit-scrollbar': {
            background: '#ddd',
            width: '6px',
            height: '6px',
          },
          '&::-webkit-scrollbar-track': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#A1A1AA',
            borderRadius: '24px',
          },
        }}
      >
        <Box display="inline-flex" flexDirection="column" zIndex={2}>
          <Flex
            backgroundImage={Banner}
            backgroundPosition={'center'}
            backgroundRepeat={'no-repeat'}
            backgroundSize={'cover'}
            width={'100%'}
            height={{ '2xl': '460px', lg: '340px' }}
            border="1px solid #EAECF0"
            boxShadow={'0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}
            borderRadius="4px"
          >
            <Flex mt="57px" ml="69px" flexDir="column">
              <Text
                fontFamily="Sora"
                fontSize="38px"
                fontWeight={'700'}
                w="537px"
              >
                Boas-vindas ao novo
              </Text>
              <Text
                fontFamily="Sora"
                fontSize="38px"
                fontWeight={'700'}
                w="537px"
                mt="-10px"
              >
                <span style={{ color: '#006AFF' }}>Workspace</span> Londrisoft
              </Text>

              <Flex
                w={{ md: '801px', '2xl': '500px' }}
                mt="19px"
                lineHeight={'24px'}
                flexDir="column"
                h="120px"
                color="#000"
                fontFamily={'inter'}
                fontSize={'17px'}
                justifyContent={'space-between'}
              >
                <Text>Olá, {session.name}!</Text>
                <Text mt="20px">
                  Fique por dentro das novidades e solucione as suas dúvidas na
                  nossa{' '}
                  <Link
                    href="https://londrisoft.movidesk.com/kb/pt-br"
                    isExternal
                    color="#006AFF"
                    fontWeight={'700'}
                    textDecoration={'underline'}
                  >
                    Base de Conhecimento.
                  </Link>
                </Text>
                <Text mt="20px">
                  Tem uma sugestão e quer compartilhar com a gente? Comente em
                  nosso{' '}
                  <Link
                    href="https://feedback.userreport.com/ccb1f13f-c50f-425e-a386-37a81026a972"
                    isExternal
                    color="#006AFF"
                    fontWeight={'700'}
                    textDecoration={'underline'}
                  >
                    mural de sugestões.
                  </Link>
                </Text>
              </Flex>
            </Flex>
            <Flex>
              <Image
                src={
                  'http://imagens-design.londrisoftservices.com.br/workspace/initial/images/homem_ws.png'
                }
                backgroundSize={'cover'}
                position={'absolute'}
                w={{ md: '400px', '2xl': '500px' }}
                ml={{ md: '-100px', '2xl': '-40px' }}
                mt={{ md: '0px', '2xl': '40px' }}
                alt="home com braco cruzado"
              />
            </Flex>
          </Flex>
          <Flex mt="10px" h="386px" justifyContent={'space-between'}>
            {sponser?.map((item: SponserInterface, key: number) => (
              <CardSponser
                aplicativo={item.aplicativo}
                descricao={item.descricao}
                imagem={item.imagem}
                linkBotao={item.linkBotao}
                textoBotao={item.textoBotao}
                tituloImagem={item.tituloImagem}
                key={key}
              />
            ))}
          </Flex>
        </Box>
      </Flex>
    </Fade>
  )
}
